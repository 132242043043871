import React, { useState, useCallback } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { TextBox } from 'devextreme-react';
import axios, { AxiosError } from 'axios';

import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogNewTag: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  const handleSend = useCallback(async () => {
    setLoading(true);
    if (name !== '') {
      try {
        await api.post('/api/post-tags', {
          name,
        });
        handleClose();
      } catch (error) {
        if (axios.isAxiosError(error)) {
          const err = error as AxiosError;
          addToast({
            title: err.response?.data,
            type: 'error',
          });
        } else {
          addToast({
            title: 'Something went wrong...',
            type: 'error',
          });
        }
      }
    } else
      addToast({
        title: 'Please fill all the required (*) fields.',
        type: 'error',
        description: '',
      });
    setLoading(false);
  }, [addToast, handleClose, name]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
      onSubmit={() => {
        handleClose();
      }}
    >
      <DialogTitle id="form-dialog-title">New Tag</DialogTitle>
      <DialogContent>
        <FormGroup fieldSetLabel="Name*">
          <TextBox onValueChange={e => setName(e)} />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSend} primary disabled={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
