import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { Container } from './styles';

interface Props {
  uri: string;
}

export const PDFViewer: React.FC<Props> = ({ uri }) => {
  const [pages, setPages] = useState(1);
  const [scale, setScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  return (
    <Container>
      <Document
        file={uri}
        onLoadSuccess={({ numPages }) => {
          setPages(numPages);
          setPageNumber(1);
        }}
      >
        <Page pageNumber={pageNumber} renderTextLayer={false} scale={scale} />
      </Document>
      <div className="page-controls">
        <button
          disabled={pageNumber <= 1}
          onClick={() => setPageNumber(pageNumber - 1)}
          type="button"
          aria-label="Previous page"
        >
          ‹
        </button>
        <span>
          {pageNumber} of {pages}
        </span>
        <button
          disabled={pageNumber >= pages}
          onClick={() => setPageNumber(pageNumber + 1)}
          type="button"
          aria-label="Next page"
        >
          ›
        </button>
      </div>
      <div className="page-controls zoom">
        <button
          disabled={scale <= 1}
          onClick={() => setScale(1)}
          type="button"
          aria-label="Zoom out"
        >
          -
        </button>
        <button
          disabled={scale >= 2}
          onClick={() => setScale(2)}
          type="button"
          aria-label="Zoom in"
        >
          +
        </button>
      </div>
    </Container>
  );
};
