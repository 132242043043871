import styled, { css } from 'styled-components';
import { FiMenu } from 'react-icons/fi';

export const Container = styled.section`
  height: 100%;
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  z-index: 999;
`;

export const AuxHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  padding-bottom: 0;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    text-align: left;
  }
`;

export const AuxBody = styled.div`
  width: 100%;
  overflow: auto;
`;

export const CloseButton = styled(FiMenu).attrs({
  size: 24,
})`
  cursor: pointer;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  p {
    font-size: 16px;
    color: #6b778c;
    margin-bottom: 10px;
    font-weight: 700;
  }
`;

export const CheckList = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 5px;
  flex-wrap: wrap;

  .tagSpan {
    display: flex;
    gap: 10px;
    justify-content: space-between;

    .chip {
      width: 33%;
      font-size: 16px;
      font-weight: 700;
    }
  }
`;

interface CheckItemProps {
  checked?: boolean;
  status?: number;
}

export const CheckItem = styled.div<CheckItemProps>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  padding: 8px;
  align-items: center;
  justify-content: center;
  background-color: #eaeaea;
  margin-bottom: 8px;
  cursor: pointer;
  flex: 1 0 21%;

  ${props =>
    props.checked &&
    css`
      background-color: rgba(139, 3, 3, 0.1);
      /* border: 2px solid #8b0304; */
    `}

  ${props =>
    props.status === 1 &&
    css`
      background-color: rgba(90, 182, 94, 0.1);
      /* border: 2px solid #5ab65e; */
    `}

    ${props =>
    (props.status === 2 || props.status === 0) &&
    css`
      background-color: rgba(51, 51, 51, 0.1);
      /* border: 2px solid #333; */
    `}

    ${props =>
    props.status === 3 &&
    css`
      background-color: rgba(237, 206, 97, 0.1);
      /* border: 2px solid #edce61; */
    `}

  span {
    /* padding-left: 5px; */
    font-size: 14px;
    font-weight: 400;
  }
`;

export const CommentList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const CommentItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 3px;
  padding: 8px;
  align-items: flex-start;
  border: 2px solid transparent;
  background-color: #eaeaea;
  margin-bottom: 8px;
  position: relative;
  overflow-wrap: anywhere;

  .check {
    position: absolute;
    left: -9px;
    top: -5px;
    z-index: 100;
    display: block;
    width: 8px;
    height: 13px;
  }

  .name {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`;

export const CommentaryEntry = styled.div`
  width: 100%;
  display: flex;
  gap: 6px;
  align-items: center;
  margin-bottom: 10px;

  .textarea {
    padding: 8px 16px;
    border-radius: 18px;
    width: 100%;
    background-color: #f5f5f5;
    font-size: 0.9rem;
    font-weight: 300;
    font-family: 'Roboto Condensed', sans-serif;
    resize: none;
    overflow: hidden;
    outline: none !important;

    transition: 0.2s;

    &:focus {
      border: 2px solid #8b0304;
    }
  }
`;

export const CommentaryEntryBtn = styled.button`
  cursor: pointer;
  color: #ccc;
  background: none;
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    color: #8b0304;
  }
`;

export const CommentaryPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;
