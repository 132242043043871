import React, { useState, useEffect, useCallback } from 'react';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import TreeList, {
  Column,
  Selection,
  SearchPanel,
} from 'devextreme-react/tree-list';

import api, { IWeeklyFile } from '../../../../services/api';
import { ThumbnailContainer, Thumbnail } from '../styles';

type Props = {
  selectionType: 'single' | 'multiple';
  onSelectionChanged: (ids: number[]) => void;
  selectedFilesId: number[];
};

export const GlobalFile: React.FC<Props> = ({
  onSelectionChanged,
  selectionType,
  selectedFilesId,
}) => {
  const [files, setFiles] = useState<DataSource>();
  const [ids, setIds] = useState<number[]>([]);

  useEffect(() => {
    setIds(selectedFilesId);
  }, [selectedFilesId]);

  const fetchData = useCallback(async () => {
    const filesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IWeeklyFile[]>('/api/global-files/all');

        return data;
      },
    });

    setFiles(
      new DataSource({
        store: filesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const thumbnailColumn = useCallback(
    e =>
      e.data?.thumbnail && e.data?.contentType?.includes('image') ? (
        <ThumbnailContainer>
          <Thumbnail src={`${e.data?.uri}`} />
          <p>{e.data?.name}</p>
        </ThumbnailContainer>
      ) : (
        <span>{e.data?.name}</span>
      ),
    [],
  );

  return (
    <TreeList
      dataSource={files}
      showRowLines
      parentIdExpr="idParent"
      onSelectionChanged={e => {
        onSelectionChanged(e.component.getSelectedRowKeys('all'));
      }}
      selectedRowKeys={ids}
      defaultSelectedRowKeys={ids}
    >
      <SearchPanel visible />
      <Selection mode={selectionType} recursive />
      <Column dataField="name" cellRender={thumbnailColumn} />
    </TreeList>
  );
};
