import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e6e7e9;
  border-radius: 18px;
  margin-bottom: 32px;
`;

export const Info = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 15px;
`;

export const Photo = styled.img`
  width: 90px;
  height: 90px;
  border-radius: 45px;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  h2 {
    font-weight: 700 !important;
    font-size: clamp(0.9rem, 2.5vw, 1.1rem) !important;
  }

  h3 {
    font-size: clamp(0.7rem, 2.5vw, 1rem) !important;
  }
`;

export const Message = styled.div`
  font-size: clamp(1rem, 2.5vw, 3rem);
  color: #c7c8ca;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
