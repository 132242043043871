import styled, { css } from 'styled-components';

export const Container = styled.div`
  /* display: flex;
  width: 100%; */
  /* align-items: center;
  justify-content: center; */
`;

type Props = {
  width?: number;
  height?: number;
};

export const Image = styled.img<Props>`
  /* border-radius: 18px; */
  max-width: 100%;
  /* max-height: 400px; */

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
`;
