/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState } from 'react';

import CustomStore from 'devextreme/data/custom_store';

import { DataGrid } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  Lookup,
  Paging,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { FiAlertCircle, FiCheckCircle, FiMinusCircle } from 'react-icons/fi';
import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { BoardHeader } from '../../../components/BoardHeader';
import { Container } from './styles';
import { BoardBody } from '../../../components/BoardBody';
import { Button } from '../../../components/Button';
import { DialogNewPost } from './DialogNewPost';
import api from '../../../services/api';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const Post: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [posts, setPosts] = useState<DataSource>();
  const history = useHistory();

  const [editions] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('api/editions');

        return data;
      },
    }),

    paginate: true,
  });

  const loadPosts = useCallback(async () => {
    const postsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/posts');
        return data;
      },
    });

    setPosts(
      new DataSource({
        store: postsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadPosts();
  }, [loadPosts]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          cursor: 'pointer',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const customCheckboxColumns = useCallback(
    e => (
      <Tooltip
        title={
          e.value === 1
            ? 'Approved'
            : e.value === 2
            ? 'No Info'
            : e.value === 3
            ? 'Pending'
            : 'No Info'
        }
      >
        <div>
          {e.value === 1 ? (
            <FiCheckCircle size={20} color="#5ab65e" />
          ) : e.value === 2 ? (
            <FiMinusCircle size={20} />
          ) : e.value === 3 ? (
            <FiAlertCircle size={20} color="#edce61" />
          ) : (
            <FiMinusCircle size={20} />
          )}
        </div>
      </Tooltip>
    ),
    [],
  );

  const customCheckboxBooleanColumns = useCallback(
    e => (
      <Tooltip title={e.value ? 'Approved' : 'Pending'}>
        <div>
          {e.value ? (
            <FiCheckCircle size={20} color="#5ab65e" />
          ) : (
            <FiAlertCircle size={20} color="#edce61" />
          )}
        </div>
      </Tooltip>
    ),
    [],
  );

  const handleOpenPost = useCallback(
    e => {
      if (e.rowType === 'data') {
        history.push(`/admin/posts/${e.key}`);
        // setPostId(e.key);
        // setOpenEdit(true);
      }
    },
    [history],
  );

  const handleClose = useCallback(() => {
    posts?.reload();
    // setPostId(0);
    // setOpenEdit(false);
    setOpen(false);
  }, [posts]);

  return (
    <ApplicationLayers>
      <Container>
        <BoardHeader title="Posts" subtitle="Posts" />

        <BoardBody>
          <Button className="newPostBtn" primary onClick={() => setOpen(true)}>
            NEW POST
          </Button>
          <DataGrid
            hoverStateEnabled
            dataSource={posts}
            onCellClick={handleOpenPost}
          >
            <HeaderFilter visible allowSearch />
            <Column
              dataField="title"
              alignment="center"
              cellRender={boldTextCells}
            />
            <Column
              dataField="idEdition"
              caption="Edition"
              alignment="center"
              cellRender={boldTextCells}
            >
              <Lookup dataSource={editions} displayExpr="name" valueExpr="id" />
            </Column>

            <Column
              dataField="category.name"
              caption="Category"
              alignment="center"
              cellRender={boldTextCells}
            />
            <Column
              dataField="edition.status.name"
              caption="Status"
              alignment="center"
              cellRender={boldTextCells}
            />
            <Column
              dataField="isPartnerRevised"
              caption="Partner"
              cellRender={customCheckboxBooleanColumns}
              alignment="center"
            />
            <Column
              dataField="isRevised"
              caption="Reviewer"
              cellRender={customCheckboxBooleanColumns}
              alignment="center"
            />
            <Column
              dataField="hasText"
              caption="Text"
              cellRender={customCheckboxColumns}
              alignment="center"
              allowFiltering={false}
            />
            <Column
              dataField="hasPhoto"
              caption="Photo"
              cellRender={customCheckboxColumns}
              alignment="center"
              allowFiltering={false}
            />
            <Column
              dataField="hasVideo"
              caption="Video"
              cellRender={customCheckboxColumns}
              alignment="center"
              allowFiltering={false}
            />
            <Column
              dataField="isTag"
              caption="Tag"
              cellRender={customCheckboxColumns}
              alignment="center"
              allowFiltering={false}
            />
            <Column
              dataField="dateCreated"
              dataType="datetime"
              alignment="center"
              cellRender={boldTextCells}
            />
            <Paging pageSize={10} />
          </DataGrid>
        </BoardBody>
        {open && <DialogNewPost open={open} handleClose={handleClose} />}
      </Container>
    </ApplicationLayers>
  );
};
