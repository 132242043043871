import React, { useCallback, useEffect, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, HeaderFilter, Scrolling } from 'devextreme-react/data-grid';
import { useHistory } from 'react-router-dom';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { IconButton, Tooltip } from '@mui/material';
import { FiArrowUpCircle, FiEdit2, FiExternalLink } from 'react-icons/fi';
import { BoardBody } from '../../../components/BoardBody';
import { Container } from './styles';
import { BoardHeader } from '../../../components/BoardHeader';
import api from '../../../services/api';
import { Button } from '../../../components/Button';
import { DialogNewEdition } from './DialogNewEdition';
import { DialogEditStatus } from './DialogEditStatus';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

export const Edition: React.FC = () => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [idEdition, setIdEdition] = useState(0);
  const [editions, setEditions] = useState<DataSource>();

  const loadEditions = useCallback(async () => {
    const editionsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/editions');
        return data;
      },
    });

    setEditions(
      new DataSource({
        store: editionsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadEditions();
  }, [loadEditions]);

  const boldTextCells = useCallback(
    e => (
      <p
        style={{
          color: '#42526E',
          fontSize: '14px',
          fontWeight: 'bold',
          whiteSpace: 'break-spaces',
          cursor: 'pointer',
        }}
      >
        {e.text}
      </p>
    ),
    [],
  );

  const editStatusCell = useCallback(
    e => (
      <>
        <Tooltip title="Edit status">
          <IconButton
            onClick={() => {
              setIdEdition(e.key);
              setOpenEditStatus(true);
            }}
          >
            <FiArrowUpCircle size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              history.push(`/admin/editions/${e.key}`);
            }}
          >
            <FiEdit2 size={20} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Open edition">
          <IconButton onClick={() => window.open(`/editions/${e.key}`)}>
            <FiExternalLink size={20} />
          </IconButton>
        </Tooltip>
      </>
    ),
    [history],
  );

  return (
    <ApplicationLayers>
      <Container>
        <BoardHeader title="Editions" subtitle="Editions" />

        <BoardBody>
          <Button
            className="newEditionBtn"
            primary
            onClick={() => setOpen(true)}
          >
            NEW EDITION
          </Button>
          <DataGrid
            keyExpr="id"
            hoverStateEnabled
            dataSource={editions}
            onCellClick={e => {
              if (e.rowType === 'data' && e.columnIndex < 6) {
                history.push(`/admin/editions/${e.key}`);
              }
            }}
          >
            <HeaderFilter visible allowSearch />
            <Scrolling mode="infinite" />
            <Column dataField="name" cellRender={boldTextCells} />
            <Column
              dataField="slogan"
              cellRender={boldTextCells}
              alignment="center"
            />
            <Column
              dataField="status.name"
              cellRender={boldTextCells}
              alignment="center"
              caption="Status"
            />
            <Column
              dataField="coverPage.name"
              cellRender={boldTextCells}
              alignment="center"
              caption="Cover Page"
            />
            <Column
              dataField="datePublished"
              dataType="datetime"
              cellRender={boldTextCells}
              alignment="center"
              format="dd/MM/yyyy"
            />
            <Column
              dataField="dateCreated"
              dataType="datetime"
              cellRender={boldTextCells}
              alignment="center"
              format="dd/MM/yyyy"
            />
            <Column cellRender={editStatusCell} width={150} />
          </DataGrid>
        </BoardBody>
        {open && (
          <DialogNewEdition
            open={open}
            handleClose={() => {
              setOpen(false);
              editions?.reload();
            }}
          />
        )}
        {openEditStatus && (
          <DialogEditStatus
            open={openEditStatus}
            handleClose={() => {
              setOpenEditStatus(false);
              editions?.reload();
            }}
            idEdition={idEdition}
          />
        )}
      </Container>
    </ApplicationLayers>
  );
};
