import React, { BaseHTMLAttributes } from 'react';

import styled from 'styled-components';

interface DashboardHeaderProps extends BaseHTMLAttributes<HTMLDivElement> {
  title: string;
}

const Container = styled.div`
  width: 100%;
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
  }
`;

export const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title }) => {
  return (
    <Container>
      <h1>{title}</h1>
    </Container>
  );
};
