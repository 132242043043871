import styled from 'styled-components';

export const Container = styled.div`
  & > .dx-texteditor.dx-editor-outlined {
    background-color: #dedede;
    box-shadow: none;
  }

  & > .dx-tagbox.dx-editor-filled .dx-tag-container, .dx-tagbox.dx-editor-outlined .dx-tag-container {
    padding: 9px 10px 10px !important;
    min-height: 42px !important;
  }

  & > .dx-placeholder {
    font-size: 14px;
  }

  & > .dx-tagbox.dx-editor-filled .dx-texteditor-input, .dx-tagbox.dx-editor-outlined .dx-texteditor-input {
    margin-top: 0;
  }

  & > .dx-textbox {
    line-height: 0;
    font-size: 14px;

    input {
      font-size: 14px;
      padding: 0 !important;
    }
  }

  & > .dx-tagbox .dx-texteditor-input {
    height: auto !important;
  }

  .dx-tag {
    margin: 0;
  }

  .dx-tag-content {
    height: 22px;
    display: flex;
    align-items: center;
    background-color: #8b0304 !important;
    color: #fff;
  }
`;
