import styled from 'styled-components';

export const Container = styled.section`
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: bold;
  display: flex;
  gap: 30px;
  margin-top: -75px;
  align-items: center;
  padding: 30px;
  display: flex;
  flex-direction: column;

  section {
    display: flex;
    gap: 30px;
    width: 100%;
    justify-content: center;
  }

  .dx-datagrid,
  .dx-datagrid-header-panel,
  .dx-toolbar {
    background-color: #efefef;
  }

  .dx-datagrid-rowsview .dx-datagrid-content .dx-datagrid-table .dx-row {
    background-color: #fff;
  }

  .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
    font-weight: 700;
    color: #42526e;
    font-size: 14px;
  }

  .bigNumbersWrapper {
    display: flex;
    gap: 30px;
    background-color: white;
    border-radius: 20px;
    padding: 15px 30px;
    text-align: center;
  }
`;
