import styled, { css } from 'styled-components';
import { lighten, darken } from 'polished';

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  loading?: boolean;
  small?: boolean;
}

export const Container = styled.button<ButtonProps>`
  outline: none;
  max-height: 42px;
  border: none;
  justify-content: center;
  font-weight: 700;
  font-size: 12px;
  color: #999999;
  background-color: #eaeaea;
  min-height: 28px;
  padding: 0;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Roboto Condensed', sans-serif;
  display: flex;
  align-items: center;
  border-radius: 7px;
  transition: 0.2s;
  &:hover {
    background-color: ${darken(0.05, '#eaeaea')};
  }

  & > div {
    margin-left: 10px;
  }

  ${props =>
    props.small &&
    css`
      padding: 5px 10px;
      text-transform: none;
    `}

  ${props =>
    props.primary &&
    css`
      background-color: #8b0304;
      color: #fff;

      transition: 0.2s;
      &:hover {
        background-color: ${lighten(0.05, '#8b0304')};
      }
    `}

  ${props =>
    props.secondary &&
    css`
      background-color: #666;
      color: #fff;

      transition: 0.2s;
      &:hover {
        background-color: ${lighten(0.1, '#666')};
      }
    `}

  &:disabled {
    background-color: rgba(51, 51, 51, 0.2);
  }

  ${props =>
    props.loading &&
    css`
      cursor: default;
    `}
`;
