import React from 'react';
import { Link } from 'react-router-dom';

export const NotFound: React.FC = () => {
  return (
    <div>
      <h1>404 - Not Found!</h1>
      {window.location.href.includes('admin') ? (
        <Link to="/admin">Go to Admin</Link>
      ) : (
        <Link to="/">Go to Homepage</Link>
      )}
    </div>
  );
};
