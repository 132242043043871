import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { Button } from '../../../../components/Button';
import api from '../../../../services/api';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  handleSubmit(): void;
  id: number;
}

export const DialogRemoveBackgroundImage: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  handleSubmit,
  id,
}) => {
  const [loading, setLoading] = useState(false);

  const handleImageRemove = useCallback(async () => {
    setLoading(true);
    await api.delete(`/api/editions/${id}/remove-background`);
    setLoading(false);
    handleSubmit();
  }, [handleSubmit, id]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Confirm background remove
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to remove this background image? This action
          cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          primary
          onClick={() => handleImageRemove()}
          autoFocus
          disabled={loading}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
};
