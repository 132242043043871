import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 98;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const PopUp = styled.div`
  position: fixed;
  max-height: 95vh;
  top: 5vh;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #fff;
  border-radius: 20px;
  animation: slideinBottom 500ms cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

export const CloseButton = styled(FiX)`
  position: fixed;
  top: 5px;
  right: 10px;
  z-index: 99999999;
  cursor: pointer;
  color: #fff;
`;
