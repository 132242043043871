import React, { useCallback, useEffect, useState } from 'react';

import DataGrid, {
  Column,
  RowDragging,
  Scrolling,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { IconButton, Tooltip } from '@mui/material';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';
import { DialogEditCategory } from '../DialogEditCategory';
import { Container } from './styles';
import api, { ICategory } from '../../../../services/api';
import { DialogDeleteCategory } from '../DialogDeleteCategory';

interface Props {
  categoryId: number;
}

export const CategoryGrid: React.FC<Props> = ({ categoryId }) => {
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditId, setOpenEditId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteId, setOpenDeleteId] = useState(0);
  const [categoriesRaw, setCategoriesRaw] = useState<ICategory[]>([]);
  const [categories, setCategories] = useState<DataSource>();

  const loadCategories = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<ICategory[]>('/api/categories');

        const childCategories = data.filter(
          x => x.idParentCategory === categoryId,
        );

        setCategoriesRaw(childCategories);
        return childCategories;
      },
      update: async (id, data) => {
        await api.put(`/api/categories/${id}`, data);
      },
    });

    setCategories(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [categoryId]);

  const handleDrag = useCallback(
    async e => {
      const visibleRows = e.component.getVisibleRows();
      const newcategories = [...categoriesRaw];
      const toIndex = newcategories.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = newcategories.indexOf(e.itemData);
      newcategories.splice(fromIndex, 1);
      newcategories.splice(toIndex, 0, e.itemData);
      const newParentCategories = newcategories.filter(
        x => x.idParentCategory === categoryId,
      );

      setCategoriesRaw(newParentCategories);

      const store = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return newParentCategories;
        },
        update: async (id, data) => {
          await api.put(`/api/categories/${id}`, data);
        },
      });

      setCategories(
        new DataSource({
          store,
          paginate: true,
          reshapeOnPush: true,
        }),
      );

      const dataToUpdate = newcategories.map((item, index) => ({
        id: item.id,
        sortOrder: index + 1,
      }));
      await api.patch(
        `api/categories/order?idParentCategory=${categoryId}`,
        dataToUpdate,
      );
    },
    [categoriesRaw, categoryId],
  );

  const commandColumnRender = useCallback(e => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Tooltip title="Edit category" aria-label="open">
          <IconButton
            aria-label="Edit category"
            size="small"
            onClick={() => {
              setOpenEditId(e.key);
              setOpenEdit(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete category" aria-label="open">
          <IconButton
            aria-label="Delete category"
            size="small"
            onClick={() => {
              setOpenDeleteId(e.key);
              setOpenDelete(true);
            }}
          >
            <FiTrash2 size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }, []);

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  return (
    <Container>
      <DataGrid
        hoverStateEnabled={false}
        dataSource={categories}
        onRowUpdating={options => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Scrolling mode="infinite" />
        <Column dataField="name" />
        <Column dataField="status.name" caption="Status" />
        <Column dataField="parentCategory.name" caption="Parent Category" />
        <Column dataField="dateCreated" dataType="datetime" />
        <Column type="buttons" cellRender={commandColumnRender} width={120} />
      </DataGrid>
      {openEdit && (
        <DialogEditCategory
          open={openEdit}
          handleClose={() => {
            setOpenEdit(false);
            categories?.reload();
          }}
          categoryId={openEditId}
        />
      )}
      {openDelete && (
        <DialogDeleteCategory
          open={openDelete}
          handleClose={() => {
            setOpenDelete(false);
            categories?.reload();
          }}
          categoryId={openDeleteId}
        />
      )}
    </Container>
  );
};
