import React from 'react';

import PieChart, { Series } from 'devextreme-react/pie-chart';

import styled from 'styled-components';

interface Source {
  arg: string;
  val: number;
}

interface Props {
  title: string;
  id: string;
  source: Source[];
}

const Container = styled.div`
  width: 50%;
  padding: 15px;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
`;

export const ThePie: React.FC<Props> = ({ title, id, source }) => {
  return (
    <Container>
      <PieChart
        id={id}
        type="doughnut"
        title={{
          text: title.toUpperCase(),
          horizontalAlignment: 'center',
          font: { family: 'Roboto Condensed', weight: 700, color: '#333' },
        }}
        palette={['#CCC', '#8b0304', '#333', '#999', '#b30408', '#ff0106']}
        dataSource={source}
        legend={{
          orientation: 'vertical',
          margin: 20,
          horizontalAlignment: 'center',
          verticalAlignment: 'bottom',
          font: {
            family: 'Roboto Condensed',
            weight: 400,
            size: 11,
            color: '#AAA',
          },
          rowItemSpacing: 5,
        }}
        tooltip={{
          zIndex: 1000,
          font: { family: 'Roboto Condensed', color: '#333', size: '14px' },
          paddingLeftRight: 20,
          paddingTopBottom: 20,
          opacity: 1,
          arrowLength: 10,
          color: '#ffffff',
          shadow: { opacity: 0.2, blur: 10, color: '#000000' },
          enabled: true,
          interactive: true,
          customizeTooltip(pointInfo) {
            return {
              text: `${pointInfo.argumentText} : ${pointInfo.valueText.bold()}`,
            };
          },
        }}
      >
        <Series argumentField="arg" />
      </PieChart>
    </Container>
  );
};

export default ThePie;
