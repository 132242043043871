import { IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { FileUploader, SelectBox, TextArea, TextBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import React, { useCallback, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { useForm, Controller } from 'react-hook-form';
import { format, parseISO } from 'date-fns';
import { Button } from '../../../components/Button';
import { FormGroup } from '../../../components/FormGroup';
import { useToast } from '../../../hooks/toast';
import api, { ICategory, IEdition, IPost } from '../../../services/api';
import { useDocumentTitle } from '../../../hooks/documentTitle';
import { Container, MyDialog } from './styles';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const SendContent: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const { handleSubmit, control } = useForm();
  const { setTitle: setDocumentTitle } = useDocumentTitle();
  const [loading, setLoading] = useState(false);

  const [editions] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IEdition[]>('api/editions');
        return data.filter(x => x.idStatus === 4 && !x.extraEdition);
      },
    }),
    paginate: true,
  });

  const [categories] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<ICategory[]>('api/categories');
        return data.filter(x => !!x.idParentCategory && x.idStatus === 1);
      },
    }),
    paginate: true,
  });

  const editionSelectRender = useCallback(e => {
    return (
      <p>
        {e.name}
        <span style={{ opacity: 0.35 }}>
          {e.datePublished &&
            ` (launch on ${format(parseISO(e.datePublished), 'dd MMM yyyy')})`}
        </span>
      </p>
    );
  }, []);

  const handleSendContent = useCallback(
    async data => {
      setLoading(true);
      try {
        const formData = new FormData();

        if (data.files && data.files.length > 0) {
          data.files.forEach((file: any) => {
            formData.append(`files`, file, file.name);
          });
        }

        formData.append('idEdition', data.idEdition);
        formData.append('idCategory', data.idCategory);
        formData.append('text', data.text);
        formData.append('title', data.title);

        await api.post<IPost>('/api/send-content', formData);

        addToast({
          title: 'Content sent successfully.',
          type: 'success',
          description: '',
        });
        handleClose();
      } catch (error) {
        addToast({
          title: 'Something went wrong.',
          type: 'error',
          description: '',
        });
      }

      setLoading(false);
    },
    [addToast, handleClose],
  );

  useEffect(() => {
    setDocumentTitle('Send content');
  }, [setDocumentTitle]);

  return (
    <MyDialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: '20px',
        },
      }}
    >
      <Container>
        <div className="top">
          {/* <h1>Send Content</h1> */}
          <IconButton className="fiXIcon" onClick={handleClose}>
            <FiX size={25} />
          </IconButton>
        </div>

        <form
          className="postInfo"
          onSubmit={handleSubmit(handleSendContent)}
          encType="multipart/form-data"
        >
          <script src="https://player.vimeo.com/api/player.js" />

          <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
            <iframe
              src="https://player.vimeo.com/video/392541283?h=71ee54301b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
              }}
              title="Weekly Tutorial"
            />
          </div>
          <div className="postSelectors">
            <FormGroup fieldSetLabel="Edition">
              <Controller
                name="idEdition"
                control={control}
                rules={{ required: 'Edition is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <SelectBox
                      className="edition-select"
                      stylingMode="outlined"
                      valueExpr="id"
                      displayExpr="name"
                      itemRender={e => editionSelectRender(e)}
                      dataSource={editions}
                      onValueChanged={e => field.onChange(e.value)}
                      dropDownOptions={{ maxHeight: '21em' }}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />
            </FormGroup>
            <FormGroup fieldSetLabel="Category">
              <Controller
                name="idCategory"
                control={control}
                rules={{ required: 'Category is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <SelectBox
                      stylingMode="outlined"
                      valueExpr="id"
                      displayExpr="name"
                      dataSource={categories}
                      onValueChanged={e => field.onChange(e.value)}
                      dropDownOptions={{ maxHeight: '21em' }}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup fieldSetLabel="Title">
              <Controller
                name="title"
                control={control}
                rules={{ required: 'Title is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextBox
                      stylingMode="outlined"
                      onValueChanged={e => field.onChange(e.value)}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup fieldSetLabel="Content">
              <Controller
                name="text"
                control={control}
                rules={{ required: 'Text is required' }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <TextArea
                      stylingMode="outlined"
                      height={250}
                      onValueChanged={e => field.onChange(e.value)}
                    />
                    {error && <p>{error.message}</p>}
                  </>
                )}
              />
            </FormGroup>
          </div>
          <div>
            <FormGroup fieldSetLabel="Files">
              <Controller
                name="files"
                control={control}
                render={({ field }) => (
                  <FileUploader
                    multiple
                    onValueChanged={e => field.onChange(e.value)}
                    uploadMode="useForm"
                  />
                )}
              />
            </FormGroup>

            <Button
              primary
              disabled={loading}
              className="sendContentBtn"
              type="submit"
            >
              Send Content
              {loading && (
                <WhisperSpinner size={15} backColor="#FFF" frontColor="#fff" />
              )}
            </Button>
          </div>
        </form>
      </Container>
    </MyDialog>
  );
};
