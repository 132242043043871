import React, { useEffect, useState } from 'react';
import { WhisperSpinner } from 'react-spinners-kit';

import { Button, CardContainer, CardImage, Select } from './styles';
import ImageESP1 from '../../assets/endofyearcard/ESP/ESP/ESP_1.gif';
import ImageESP2 from '../../assets/endofyearcard/ESP/ESP/ESP_2.gif';
import ImageESP3 from '../../assets/endofyearcard/ESP/ESP/ESP_3.gif';
import ImageESP4 from '../../assets/endofyearcard/ESP/ESP/ESP_4.gif';
import ImageESP5 from '../../assets/endofyearcard/ESP/ESP/ESP_5.gif';
import ImageESP6 from '../../assets/endofyearcard/ESP/ESP/ESP_6.gif';
import ImageESP7 from '../../assets/endofyearcard/ESP/ESP/ESP_7.gif';
import ImageESP8 from '../../assets/endofyearcard/ESP/ESP/ESP_8.gif';
import ImageESP9 from '../../assets/endofyearcard/ESP/ESP/ESP_9.gif';
import ImageESP10 from '../../assets/endofyearcard/ESP/ESP/ESP_10.gif';

import ImageENG1 from '../../assets/endofyearcard/ENG/ENG/ENG-1.gif';
import ImageENG2 from '../../assets/endofyearcard/ENG/ENG/ENG-2.gif';
import ImageENG3 from '../../assets/endofyearcard/ENG/ENG/ENG-3.gif';
import ImageENG4 from '../../assets/endofyearcard/ENG/ENG/ENG-4.gif';
import ImageENG5 from '../../assets/endofyearcard/ENG/ENG/ENG-5.gif';
import ImageENG6 from '../../assets/endofyearcard/ENG/ENG/ENG-6.gif';
import ImageENG7 from '../../assets/endofyearcard/ENG/ENG/ENG-7.gif';
import ImageENG8 from '../../assets/endofyearcard/ENG/ENG/ENG-8.gif';
import ImageENG9 from '../../assets/endofyearcard/ENG/ENG/ENG-9.gif';
import ImageENG10 from '../../assets/endofyearcard/ENG/ENG/ENG-10.gif';

import ImageGER1 from '../../assets/endofyearcard/GER/GER/GER-1.gif';
import ImageGER2 from '../../assets/endofyearcard/GER/GER/GER-2.gif';
import ImageGER3 from '../../assets/endofyearcard/GER/GER/GER-3.gif';
import ImageGER4 from '../../assets/endofyearcard/GER/GER/GER-4.gif';
import ImageGER5 from '../../assets/endofyearcard/GER/GER/GER-5.gif';
import ImageGER6 from '../../assets/endofyearcard/GER/GER/GER-6.gif';
import ImageGER7 from '../../assets/endofyearcard/GER/GER/GER-7.gif';
import ImageGER8 from '../../assets/endofyearcard/GER/GER/GER-8.gif';
import ImageGER9 from '../../assets/endofyearcard/GER/GER/GER-9.gif';
import ImageGER10 from '../../assets/endofyearcard/GER/GER/GER-10.gif';

import ImagePOR1 from '../../assets/endofyearcard/POR/POR/PORT-1.gif';
import ImagePOR2 from '../../assets/endofyearcard/POR/POR/PORT-2.gif';
import ImagePOR3 from '../../assets/endofyearcard/POR/POR/PORT-3.gif';
import ImagePOR4 from '../../assets/endofyearcard/POR/POR/PORT-4.gif';
import ImagePOR5 from '../../assets/endofyearcard/POR/POR/PORT-5.gif';
import ImagePOR6 from '../../assets/endofyearcard/POR/POR/PORT-6.gif';
import ImagePOR7 from '../../assets/endofyearcard/POR/POR/PORT-7.gif';
import ImagePOR8 from '../../assets/endofyearcard/POR/POR/PORT-8.gif';
import ImagePOR9 from '../../assets/endofyearcard/POR/POR/PORT-9.gif';
import ImagePOR10 from '../../assets/endofyearcard/POR/POR/PORT-10.gif';

const ISRESPONSIVE = 768;
const LOAD_DELAY = 1000;

type Language = 'PT' | 'ENG' | 'GER' | 'ESP';

const imageMap: Record<Language, string[]> = {
  PT: [
    ImagePOR1,
    ImagePOR2,
    ImagePOR3,
    ImagePOR4,
    ImagePOR5,
    ImagePOR6,
    ImagePOR7,
    ImagePOR8,
    ImagePOR9,
    ImagePOR10,
  ],
  ENG: [
    ImageENG1,
    ImageENG2,
    ImageENG3,
    ImageENG4,
    ImageENG5,
    ImageENG6,
    ImageENG7,
    ImageENG8,
    ImageENG9,
    ImageENG10,
  ],
  GER: [
    ImageGER1,
    ImageGER2,
    ImageGER3,
    ImageGER4,
    ImageGER5,
    ImageGER6,
    ImageGER7,
    ImageGER8,
    ImageGER9,
    ImageGER10,
  ],
  ESP: [
    ImageESP1,
    ImageESP2,
    ImageESP3,
    ImageESP4,
    ImageESP5,
    ImageESP6,
    ImageESP7,
    ImageESP8,
    ImageESP9,
    ImageESP10,
  ],
};

export const EndOfYearCard: React.FC = () => {
  const [currentImage, setCurrentImage] = useState<string>('');
  const [language, setLanguage] = useState<Language>('PT');
  const [isResponsive, setIsResponsive] = useState(
    window.innerWidth >= ISRESPONSIVE,
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsResponsive(window.innerWidth > ISRESPONSIVE);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const [firstImage] = imageMap[language];

    setCurrentImage(firstImage);

    const img = new Image();
    img.onload = () => {
      setLoading(false);
    };
    img.src = firstImage;
  }, [language]);

  const handleLanguageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newLanguage = e.target.value as Language;
    setLoading(true);
    setLanguage(newLanguage);
  };

  const handleGenerateNewCard = () => {
    const images = imageMap[language];
    let randomIndex;
    let newImage;

    do {
      randomIndex = Math.floor(Math.random() * images.length);
      newImage = images[randomIndex];
    } while (newImage === currentImage);

    const loaderTimeout = setTimeout(() => {
      setLoading(true);
    }, LOAD_DELAY);

    setCurrentImage(newImage);

    const img = new Image();
    img.onload = () => {
      clearTimeout(loaderTimeout);
      setLoading(false);
    };
    img.src = newImage;
  };

  const handleDownload = () => {
    if (!currentImage) return;

    const link = document.createElement('a');
    link.href = currentImage;
    link.download = 'EndOfYearCard.gif';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <CardContainer style={{ gap: isResponsive ? '120px' : '20px' }}>
      {loading ? (
        <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
      ) : (
        <CardImage src={currentImage} alt="End of Year Card" />
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: isResponsive ? '2px' : '12px',
          alignItems: isResponsive ? 'baseline' : 'center ',
        }}
      >
        {isResponsive && (
          <p style={{ width: '200px' }}>
            Create a customized card with a randomized kaleidoscope design by
            clicking on “Generate new card”. Then click on “Download” to save
            the card as a .gif file for sharing with your contacts. You can
            download as many cards as you want!
          </p>
        )}
        <div
          style={{
            display: 'flex',
            flexDirection: isResponsive ? 'column' : 'row',
            gap: '2px',
          }}
        >
          <Button onClick={handleGenerateNewCard}>Generate New Card</Button>
          <Button onClick={handleDownload}>Download</Button>
        </div>
        <Select value={language} onChange={handleLanguageChange}>
          <option value="PT">PT</option>
          <option value="ENG">ENG</option>
          <option value="GER">GER</option>
          <option value="ESP">ESP</option>
        </Select>
      </div>
    </CardContainer>
  );
};
