import styled from 'styled-components';

export const Container = styled.section`
  padding: 30px 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;

  .header-name {
    h1 {
      font-size: 26px;
      white-space: nowrap;
      font-weight: 600;
      color: #172b4d;
    }
    p {
      font-size: 14px;
      color: #9699a6;
      white-space: nowrap;
    }
  }
`;
