import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup as GroupForm,
} from '@mui/material';
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useForm, Controller } from 'react-hook-form';
import { FiX, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { borderRadius } from 'polished';
import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  Content,
  ButtonBox,
  HeaderSearch,
  SearchAreaWrapper,
  CheckBoxWrapper,
  SearchButtonBox,
} from './styles';
import api, { ICategory, IEdition } from '../../../services/api';

interface SearchPageCustomProps {
  open: boolean;
  handleClose(): void;
}

type SearchProps = {
  query?: string;
  idEdition?: number;
  idTag?: number;
  idCategory?: number;
  byComment?: boolean;
  isPublished?: boolean;
};

export const SearchPage: React.FC<SearchPageCustomProps> = ({
  open,
  handleClose,
}) => {
  const history = useHistory();
  const { addToast } = useToast();
  const [openAdvanced, setOpenAdvanced] = useState(false);

  const [editions, setEditions] = useState<DataSource>();
  const [categories, setCategories] = useState<DataSource>();
  const [tags, setTags] = useState<DataSource>();

  const { control, handleSubmit } = useForm();

  const fetchData = useCallback(async () => {
    const editionsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IEdition[]>('/api/editions');
        return data
          .filter(x => x.status.id === 1)
          .map(item => ({
            ...item,
            displayName: `${item.name} - ${item.slogan}`,
          }));
      },
    });

    setEditions(
      new DataSource({
        store: editionsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const categoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/categories');
        return data.filter((x: ICategory) => {
          return !!x.idParentCategory;
        });
      },
    });

    setCategories(
      new DataSource({
        store: categoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const tagsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/post-tags');
        return data;
      },
    });

    setTags(
      new DataSource({
        store: tagsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = useCallback(
    async (data: SearchProps) => {
      handleClose();
      data.isPublished = true;
      history.push(`/search?filter=${encodeURI(JSON.stringify(data))}`);
    },
    [history, handleClose],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '20px',
        },
      }}
    >
      <DialogContent>
        <Container>
          <ButtonBox>
            <FiX onClick={handleClose} size={16} />
          </ButtonBox>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Content>
              <HeaderSearch>
                <Controller
                  name="query"
                  control={control}
                  rules={{
                    required: 'Should not be empty',
                    minLength: {
                      value: 2,
                      message: 'Should have at least 2 caracters',
                    },
                  }}
                  render={({ field, formState: { errors } }) => {
                    return (
                      <Input
                        placeholder="Type to search"
                        onChange={e => field.onChange(e.target.value)}
                        errorMessage={errors.query?.message}
                      />
                    );
                  }}
                />
                {openAdvanced && (
                  <>
                    <SearchAreaWrapper>
                      <FormGroup fieldSetLabel="Edition">
                        <Controller
                          name="idEdition"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              dataSource={editions}
                              valueExpr="id"
                              displayExpr="displayName"
                              onValueChanged={e => field.onChange(e.value)}
                              searchEnabled
                              showClearButton
                              dropDownOptions={{ maxHeight: '21em' }}
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup fieldSetLabel="Category">
                        <Controller
                          name="idCategory"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              dataSource={categories}
                              valueExpr="id"
                              displayExpr="name"
                              onValueChanged={e => field.onChange(e.value)}
                              searchEnabled
                              showClearButton
                              dropDownOptions={{ maxHeight: '21em' }}
                            />
                          )}
                        />
                      </FormGroup>
                      <FormGroup fieldSetLabel="Tag">
                        <Controller
                          name="idTag"
                          control={control}
                          render={({ field }) => (
                            <SelectBox
                              dataSource={tags}
                              valueExpr="id"
                              displayExpr="name"
                              onValueChanged={e => field.onChange(e.value)}
                              searchEnabled
                              showClearButton
                              dropDownOptions={{ maxHeight: '21em' }}
                            />
                          )}
                        />
                      </FormGroup>
                      <CheckBoxWrapper>
                        <GroupForm>
                          <Controller
                            name="byComment"
                            control={control}
                            render={({ field }) => (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    defaultChecked={false}
                                    onChange={e =>
                                      field.onChange(e.target.value === 'on')
                                    }
                                  />
                                }
                                label="Search by comment"
                              />
                            )}
                          />
                        </GroupForm>
                      </CheckBoxWrapper>
                    </SearchAreaWrapper>

                    {/* <DateArea>
                  <TitleBox>
                    <DateTitle>Search by Date</DateTitle>
                  </TitleBox>
                  <DateWrapper>
                    <DateFromBox>
                      <StyleComponent>From</StyleComponent>
                      <DateBox
                        type="date"
                        defaultValue={date}
                        pickerType="rollers"
                      />
                    </DateFromBox>
                    <DateToBox>
                      <StyleComponent>To</StyleComponent>
                      <DateBox
                        applyValueMode="useButtons"
                        type="date"
                        defaultValue={date}
                        pickerType="rollers"
                      />
                    </DateToBox>
                  </DateWrapper>
                </DateArea> */}
                  </>
                )}
                <Button primary style={{ gap: 10 }} type="submit">
                  <FiSearch />
                  Search
                </Button>
              </HeaderSearch>

              <SearchButtonBox>
                <Button
                  type="button"
                  onClick={() => setOpenAdvanced(value => !value)}
                  secondary={!!openAdvanced}
                >
                  Advanced
                </Button>
              </SearchButtonBox>
            </Content>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
