import React, { useCallback, useEffect, useState } from 'react';

import DataGrid, {
  Column,
  RowDragging,
  Scrolling,
} from 'devextreme-react/data-grid';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import { Container } from './styles';
import api, { IPost } from '../../../../services/api';

interface Props {
  editionId: number;
  categoryId: number;
}

export const PostsGrid: React.FC<Props> = ({ categoryId, editionId }) => {
  const [posts, setPosts] = useState<DataSource>();
  const [postsRaw, setPostsRaw] = useState<IPost[]>([]);

  const loadCategoriesPosts = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IPost[]>(
          `api/editions/${editionId}/posts/`,
        );

        const postsByCategory = data.filter(x => x.idCategory === categoryId);
        setPostsRaw(postsByCategory);
        return postsByCategory;
      },
    });

    setPosts(
      new DataSource({
        store,
        paginate: false,
        reshapeOnPush: true,
      }),
    );
  }, [categoryId, editionId]);

  const handleDrag = useCallback(
    async e => {
      const visibleRows = e.component.getVisibleRows();
      const newposts = [...postsRaw];
      const toIndex = newposts.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = newposts.indexOf(e.itemData);
      newposts.splice(fromIndex, 1);
      newposts.splice(toIndex, 0, e.itemData);
      const postsByCategory = newposts.filter(x => x.idCategory === categoryId);

      setPostsRaw(postsByCategory);

      const store = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return postsByCategory;
        },
      });

      setPosts(
        new DataSource({
          store,
          paginate: false,
          reshapeOnPush: true,
        }),
      );

      const dataToUpdate = newposts.map((item, index) => ({
        id: item.id,
        sortOrder: index + 1,
      }));
      await api.patch(
        `/api/editions/${editionId}/posts/order?idCategory=${categoryId}`,
        dataToUpdate,
      );
    },
    [postsRaw, editionId, categoryId],
  );

  useEffect(() => {
    loadCategoriesPosts();
  }, [loadCategoriesPosts]);

  return (
    <Container>
      <DataGrid
        hoverStateEnabled={false}
        dataSource={posts}
        onRowUpdating={options => {
          options.newData = { ...options.oldData, ...options.newData };
        }}
      >
        <RowDragging
          allowReordering
          onReorder={handleDrag}
          showDragIcons
          dropFeedbackMode="push"
        />
        <Scrolling mode="infinite" />
        <Column dataField="title" />
      </DataGrid>
    </Container>
  );
};
