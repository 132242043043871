/* eslint-disable no-nested-ternary */
import React from 'react';

import { ReviewerArea } from '../../ReviewerArea';

type Props = {
  postId: number;
};

export const ReviewTab: React.FC<Props> = ({ postId }) => {
  return <ReviewerArea postId={postId} />;
};
