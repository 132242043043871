import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;
  align-items: left;
  justify-content: flex-start;

  img {
    max-width: 48px;
    border-radius: 50%;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-self: center;

    p {
      margin: 0;
      font-size: 12px;
      color: #999;
      font-weight: 700;
    }

    .name {
      font-size: 14px;
      color: #42526e;
    }
  }
`;
