import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { SelectBox, TextBox } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';
import { Controller, useForm } from 'react-hook-form';

import DataSource from 'devextreme/data/data_source';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { InputError } from '../../../../components/InputError';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogNewCategory: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<DataSource>();
  const [statusLoad, setStatusLoad] = useState<DataSource>();
  const { control, handleSubmit } = useForm();

  const loadCategories = useCallback(async () => {
    const CategoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/categories');
        return data;
      },
    });

    setCategories(
      new DataSource({
        store: CategoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadStatus = useCallback(async () => {
    const statusStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/status');
        return data;
      },
    });

    setStatusLoad(
      new DataSource({
        store: statusStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadCategories();
    loadStatus();
  }, [loadCategories, loadStatus]);

  const handleSend = useCallback(
    async data => {
      setLoading(true);

      await api.post('/api/categories', data);
      addToast({
        title: 'New category created successfully!',
        type: 'success',
        description: '',
      });
      handleClose();
      setLoading(false);
    },
    [addToast, handleClose],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" style={{ marginLeft: '-5px' }}>
        New Category
      </DialogTitle>
      <form
        onSubmit={handleSubmit(handleSend)}
        style={{ width: '95%', marginLeft: '20px' }}
      >
        <FormGroup fieldSetLabel="Name*">
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({ field: { onChange, ref }, fieldState: { error } }) => (
              <>
                <TextBox onValueChanged={e => onChange(e.value)} ref={ref} />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Parent Category">
          <Controller
            control={control}
            name="idParentCategory"
            rules={{ required: false }}
            render={({ field: { onChange, ref }, fieldState: { error } }) => (
              <>
                <SelectBox
                  dataSource={categories}
                  searchExpr={['Name']}
                  minSearchLength={1}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => onChange(e.value)}
                  ref={ref}
                />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>

        <FormGroup fieldSetLabel="Status*">
          <Controller
            control={control}
            name="idStatus"
            rules={{ required: true }}
            render={({ field: { onChange, ref }, fieldState: { error } }) => (
              <>
                <SelectBox
                  dataSource={statusLoad}
                  searchExpr={['name']}
                  minSearchLength={1}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => onChange(e.value)}
                  ref={ref}
                />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>
        <DialogActions>
          <Button onClick={handleClose} style={{ marginRight: '-8px' }}>
            Close
          </Button>
          <Button
            type="submit"
            primary
            disabled={loading}
            style={{ marginRight: '-8px' }}
          >
            Add
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
