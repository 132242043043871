import React from 'react';

import { FiDownload } from 'react-icons/fi';
import { Container } from './styles';

type Props = {
  uri: string;
  text: string;
};

export const DownloadFilePost: React.FC<Props> = ({ uri, text }) => {
  return (
    <Container href={uri} target="_blank">
      <FiDownload size={24} />
      {text}
    </Container>
  );
};
