import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Tooltip } from '@mui/material';
import { FiHome, FiLogOut } from 'react-icons/fi';

import {
  BiNews,
  BiPurchaseTag,
  BiGlobe,
  BiCategory,
  BiLineChart,
  BiEdit,
} from 'react-icons/bi';
import { useAuth } from '../../hooks/auth';
import weeklyLogo from '../../assets/images/weekly-simple-logo.svg';

import {
  Container,
  Control,
  ControlComponent,
  NavigationItemsArea,
  ActionIconWrapper,
  NotificationsComponent,
  NavigationDialogItemComponent,
} from './styles';

export const Surface: React.FC = () => {
  const { signOut } = useAuth();

  return (
    <Container>
      <Control>
        <ControlComponent>
          <NavigationItemsArea>
            <ActionIconWrapper>
              <NotificationsComponent>
                <span>
                  <div className="iconContainer">
                    <Link to="/">
                      <Tooltip
                        title="Back to Weekly"
                        placement="right"
                        color="red"
                      >
                        <NavigationDialogItemComponent>
                          <img src={weeklyLogo} alt="Weekly Logo" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin">
                      <Tooltip title="Home" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <FiHome color="#9D9D9D" />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/dashboard">
                      <Tooltip title="Dashboard" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <BiLineChart color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/editions">
                      <Tooltip title="Editions" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <BiNews color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/posts">
                      <Tooltip title="Posts" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <BiEdit color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/categories">
                      <Tooltip title="Categories" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <BiCategory color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/tags">
                      <Tooltip title="Tags" placement="right" color="red">
                        <NavigationDialogItemComponent>
                          <BiPurchaseTag color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>
                  <div className="iconContainer">
                    <Link to="/admin/global-storage">
                      <Tooltip
                        title="Global Storage"
                        placement="right"
                        color="red"
                      >
                        <NavigationDialogItemComponent>
                          <BiGlobe color="#9D9D9D" size={22} />
                        </NavigationDialogItemComponent>
                      </Tooltip>
                    </Link>
                  </div>

                  <div className="iconContainer">
                    <Tooltip title="Log out" placement="right">
                      <NavigationDialogItemComponent
                        onClick={signOut}
                        style={{ position: 'absolute', bottom: 32 }}
                      >
                        <FiLogOut color="#9D9D9D" />
                      </NavigationDialogItemComponent>
                    </Tooltip>
                  </div>
                </span>
              </NotificationsComponent>
            </ActionIconWrapper>
          </NavigationItemsArea>
        </ControlComponent>
      </Control>
    </Container>
  );
};
