import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Roboto Condensed';

  h1 {
    display: flex;
    gap: 10px;
    place-content: center;
    font-weight: bold;
    font-size: 24px;
  }

  .audience {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    margin-bottom: 35px;

    svg {
      width: 40px;
      height: 40px;
    }

    p {
      b {
        font-weight: bold;
      }
    }

    .mailtoBtn {
      margin: 0 auto;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }

  .feedback {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;

    svg {
      width: 40px;
      height: 40px;
    }

    p {
      b {
        font-weight: bold;
      }
    }

    .mailtoBtn {
      margin: 0 auto;

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  justify-content: center;
  margin-top: 8px;

  .cancel-button {
    background-color: #888888;

    &:hover {
      background-color: ${darken(0.03, '#888888')};
    }
  }
`;
