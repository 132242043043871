import React, { useState } from 'react';

import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Container, TabPanel } from './styles';

import { ContentTab } from '../ContentTab';
import { FileManager } from '../FileManager';
import { ReviewTab } from '../ReviewTab';
import { PostTab } from '../PostTab';

export type TabType = {
  tabView?: 0 | 1 | 2 | 3;
};

type Props = TabType & {
  postId: number;
  isOldRender: boolean;
};

export const Tabs: React.FC<Props> = ({ postId, tabView, isOldRender }) => {
  const [value, setValue] = useState(tabView || 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Container>
      <MuiTabs value={value} onChange={handleChange}>
        <Tab label="Content" />
        <Tab label="Post" />
        <Tab label="File Manager" />
        <Tab label="Review" />
      </MuiTabs>

      {value === 0 && (
        <TabPanel>
          <ContentTab postId={postId} />
        </TabPanel>
      )}
      {value === 1 && (
        <TabPanel>
          <PostTab postId={postId} />
        </TabPanel>
      )}
      {value === 2 && (
        <TabPanel>
          <FileManager postId={postId} isOldRender={isOldRender} />
        </TabPanel>
      )}
      {value === 3 && (
        <TabPanel>
          <ReviewTab postId={postId} />
        </TabPanel>
      )}
    </Container>
  );
};
