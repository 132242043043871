import styled from 'styled-components';

export const Container = styled.textarea`
  width: 100%;
  height: 100%;
  overflow: auto;
  border: none;
  resize: none;
  padding: 18px;
  background-color: #333;
  color: #bbb;
`;
