import React, { useEffect, useMemo, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { EditPost } from '../../../../components/EditPost';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const PostView: React.FC = () => {
  const { postId } = useParams<{ postId: string }>();
  const { goBack } = useHistory();
  const query = useQuery();

  const [tabView, setTabView] = useState<0 | 1 | 2 | 3>(0);
  const [viewType, setViewType] = useState<'html' | 'editor' | 'preview'>(
    'editor',
  );

  useEffect(() => {
    const viewTypeFromQuery = query.get('viewType') as
      | 'html'
      | 'editor'
      | 'preview';
    const tabViewFromQuery = query.get('tabView')
      ? Number(query.get('tabView'))
      : 0;

    setTabView(tabViewFromQuery as 0 | 1 | 2 | 3);
    setViewType(viewTypeFromQuery || 'editor');
  }, [query]);

  return (
    <EditPost
      handleClose={goBack}
      postId={Number(postId)}
      tabView={tabView}
      viewType={viewType}
    />
  );
};
