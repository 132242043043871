import { Dialog, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';

import CustomStore from 'devextreme/data/custom_store';
import React, { BaseHTMLAttributes, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiX } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../Button';
import { useToast } from '../../../hooks/toast';
import api, { IPostComment } from '../../../services/api';
import { ButtonWrapper, Container, DeleteConfirmation } from './styles';

type DialogCustomProps = BaseHTMLAttributes<HTMLDivElement> & {
  postId: number;
  open: boolean;
  handleClose(): void;
  deleteCommentId: any;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CommentDeleteDialog: React.FC<DialogCustomProps> = ({
  postId: _postId,
  open,
  handleClose,
  deleteCommentId,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [postId, setPostId] = useState(_postId);
  const [comments, setComments] = useState<IPostComment[]>([]);

  const handleDeleteComment = useCallback(
    async commentId => {
      setLoading(true);
      await api.delete(`/api/posts/${postId}/comments/${commentId}`);
      addToast({
        title: 'Comment removed successfully.',
        type: 'success',
        description: '',
      });
      const { data } = await api.get<IPostComment[]>(
        `api/posts/${postId}/comments`,
      );
      setComments(data);
      setLoading(false);
      handleClose();
    },
    [addToast, handleClose, postId],
  );

  return (
    <Dialog
      className="postDelete"
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      sx={{
        width: 'fit-content',
        margin: '0 auto',
        borderRadius: '20px',
      }}
    >
      <Container>
        <DeleteConfirmation>
          Are you sure you want to delete?
        </DeleteConfirmation>

        <ButtonWrapper>
          <Button className="cancel-button" secondary onClick={handleClose}>
            Cancel
          </Button>
          <Button
            primary
            disabled={loading}
            onClick={() => handleDeleteComment(deleteCommentId)}
          >
            Delete
            {loading && (
              <WhisperSpinner size={15} backColor="#fff" frontColor="#fff" />
            )}
          </Button>
        </ButtonWrapper>
      </Container>
    </Dialog>
  );
};
