import React, {
  useCallback,
  useEffect,
  useState,
  BaseHTMLAttributes,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import {
  FiMessageSquare,
  FiSend,
  FiShare2,
  FiSmile,
  FiStar,
  FiThumbsUp,
  FiTrash2,
} from 'react-icons/fi';
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { useHistory } from 'react-router-dom';

import { WhisperSpinner } from 'react-spinners-kit';
import { InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import {
  CommentaryAuthor,
  CommentaryBox,
  CommentaryEntry,
  CommentaryPhoto,
  CommentaryShowed,
  CommentaryText,
  CommentaryWrapper,
  Container,
  HeaderIcon,
  HeaderIconButton,
  Content,
  ContainerLoading,
  NavigationContainer,
  NavigationButton,
  ButtonTagWrapper,
  ReactionButton,
  ReactionDialog,
  ReactionsContainer,
  ReactionIcon,
  RemoveCommentary,
  ArticleBody,
  ArticleTitle,
  ArticleCategory,
  ArticleUserInteraction,
  ArticleMainContent,
  CommentaryDate,
  CommentaryEntryBtn,
  SectionBreak,
  TotalsBox,
  PickerWrapper,
  NPButton,
} from './styles';
import { ImportantBanner, ProfessionalBanner } from '../PostBanners';
import { PDFViewer } from '../PDFViewer';
import { PostImage } from '../PostImage';
import { DownloadFilePost } from '../DownloadFilePost';
import api, {
  IPost,
  IPostComment,
  IPostLike,
  IPostTag,
  IReaction,
  ITotalReaction,
} from '../../services/api';

import { useAuth } from '../../hooks/auth';
import { useScreenMobile } from '../../hooks/mobileScreen';
import { useDocumentTitle } from '../../hooks/documentTitle';

import { CONTENT_TYPES } from '../EditPost/ContentEdit';
import { useToast } from '../../hooks/toast';
import weeklyGroups from '../../config/weeklyGroups';
import { CommentDeleteDialog } from './CommentDeleteDialog';

type ArticleCustomProps = BaseHTMLAttributes<HTMLDivElement> & {
  postId: number;
  navigation?: 'center' | 'top' | 'none';
  handleClose: () => void;
  onPostChanged?: (postId: number) => void;
  onFavoriteChanged?: (postId: number, value: boolean) => void;
  onRead?: (postId: number, value: boolean) => void;
  onReactionChanged?: (postId: number, value: ITotalReaction[]) => void;
  onCommentChanged?: (postId: number, value: number) => void;
  post?: IPost;
  onChangedCategoryId?: (categoryId: number) => void;
};

type CommentInput = {
  ChildNode: any;
  firstChild: any;
  style: any;
};

export const Post: React.FC<ArticleCustomProps> = ({
  postId: _postId,
  onPostChanged,
  onFavoriteChanged,
  onReactionChanged,
  onCommentChanged,
  onRead,
  onChangedCategoryId,
  ...rest
}) => {
  const { setTitle: setWindowTitle } = useDocumentTitle();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { addToast } = useToast();
  const { user, hasScopes } = useAuth();
  const { isMobile } = useScreenMobile();
  const [postId, setPostId] = useState(_postId);
  const [openReactions, setOpenReactions] = useState(false);
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [text, setText] = useState('');
  const [newComment, setNewComment] = useState('');
  const [isPublished, setIsPublished] = useState(false);
  const [nextPost, setNextPost] = useState<IPost | undefined>(undefined);
  const [previousPost, setPreviousPost] = useState<IPost | undefined>(
    undefined,
  );
  const [showFixedHeader, setShowFixedHeader] = useState(false);
  const [comments, setComments] = useState<IPostComment[]>([]);
  const [postReactions, setPostReactions] = useState<IPostLike[]>([]);
  const [totalReactions, setTotalReactions] = useState<ITotalReaction[]>([]);
  const [totalReactionsSum, setTotalReactionsSum] = useState<number>();
  const [totalComments, setTotalComments] = useState<number>();
  const [reactions, setReactions] = useState<IReaction[]>([]);
  const [openPicker, setOpenPicker] = useState(false);
  const [post, setPost] = useState<IPost>();

  const [isFavorited, setIsFavorited] = useState(false);
  const [userReaction, setUserReaction] = useState<IReaction | undefined>();
  const [openDeleteCommentDialog, setOpenDeleteCommentDialog] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState(0);
  const commentInput = useRef<CommentInput>(null);
  const reactionDialog = useRef<HTMLDivElement | null>(null);
  const [animateReactionDialog, setAnimateReactionDialog] = useState<boolean>();
  const [animateUserReaction, setAnimateUserReaction] = useState<boolean>(true);
  const emojiBtn = useRef<HTMLButtonElement | null>(null);
  const emojiPicker = useRef<HTMLDivElement | null>(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        openPicker &&
        emojiPicker.current &&
        !emojiPicker.current.contains(e.target as Node) &&
        emojiBtn.current &&
        !emojiBtn.current.contains(e.target as Node)
      ) {
        setOpenPicker(false);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openPicker]);

  const handleCommentInput = useCallback(() => {
    if (commentInput.current) {
      commentInput.current?.firstChild.focus();
      commentInput.current.style.transform = 'scale(1.2)';

      setTimeout(() => {
        if (commentInput.current)
          commentInput.current.style.transform = 'scale(1)';
      }, 500);
    }
  }, []);

  useEffect(
    () =>
      setTotalReactionsSum(
        totalReactions.reduce((acc: any, obj: { total: any }) => {
          return acc + obj.total;
        }, 0),
      ),
    [totalReactions],
  );

  const handleSharePost = useCallback(async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `${post?.title}`,
          text: `${post?.abstract}`,
          url: `${window.location.origin}/editions/${post?.idEdition}/posts/${post?.id}`,
        });
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    } else {
      try {
        await navigator.clipboard.writeText(
          `${window.location.origin}/editions/${post?.idEdition}/posts/${post?.id}`,
        );
        addToast({
          title: 'Link Copied!',
          type: 'success',
        });
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    }
  }, [addToast, post?.abstract, post?.id, post?.idEdition, post?.title]);

  const handleSaveFavorites = useCallback(
    async postFavoriteId => {
      try {
        setIsFavorited(true);
        await api.post(`/api/posts/${postFavoriteId}/favorites`);
        if (onFavoriteChanged) onFavoriteChanged(postId, true);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, onFavoriteChanged, postId],
  );

  const handleRemoveFavorites = useCallback(
    async postFavoriteId => {
      try {
        setIsFavorited(false);
        await api.delete(`/api/posts/${postFavoriteId}/favorites`);
        if (onFavoriteChanged) onFavoriteChanged(postId, false);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, onFavoriteChanged, postId],
  );

  const fetchPost = useCallback(async () => {
    setLoading(true);

    try {
      const [postResponse, commentsResponse, reactionsResponse, likesResponse] =
        await Promise.all([
          api.get<IPost>(`api/posts/${postId}`),
          api.get<IPostComment[]>(`api/posts/${postId}/comments`),
          api.get<IReaction[]>(`api/reactions`),
          api.get<IPostLike[]>(`api/posts/${postId}/reactions`),
        ]);

      setWindowTitle(`${postResponse.data.title}`);
      setPost(postResponse.data);
      setTitle(postResponse.data.title);
      setCategory(postResponse.data.category?.name);
      if (onChangedCategoryId)
        onChangedCategoryId(postResponse.data.category?.id);
      setText(postResponse.data.text);
      setIsPublished(postResponse.data.edition?.status?.name === 'Published');
      setNextPost(postResponse.data.nextPost);
      setTotalComments(commentsResponse.data.length);
      setTotalReactions(postResponse.data.totalReactions);
      setPreviousPost(postResponse.data.previousPost);

      if (postResponse.data.userReaction) {
        const reaction = reactionsResponse.data.find(
          x => x.id === postResponse.data.userReaction,
        );
        setUserReaction(reaction);
      }

      setReactions(reactionsResponse.data);
      // setPostEdition(postResponse.edition?);
      setPostReactions(likesResponse.data);
      setComments(commentsResponse.data);
      setIsFavorited(postResponse.data.isFavorited);

      // pegando todos os paragrafos do conteudo
      const allParagrafers = document
        ?.getElementById('content')
        ?.querySelectorAll('p');

      // para cada conteudo q existe eu procuro em cada paragrafo pra ver se tem algum q da match no alias
      postResponse.data.postContents.map(content => {
        allParagrafers?.forEach(p => {
          if (p.textContent?.includes(content.prefix)) {
            // altera de paragrafo para div para nao dar erro de semantica
            const element = document.createElement('div');
            element.className = p.className;
            element.setAttribute('style', p.getAttribute('style') || '');
            p.parentElement?.replaceChild(element, p);

            // se der match no alias, eu vou substituindo usando o render do React
            if (content.idType === CONTENT_TYPES.gallery) {
              const galleryItems = content.postContentFiles?.map(file => ({
                original: file.weeklyFile?.uri || '',
                thumbnail: file.weeklyFile?.uri || '',
              }));

              if ((galleryItems?.length || 0) > 0) {
                ReactDOM.render(
                  <ImageGallery items={galleryItems || []} />,
                  element,
                );
              }
            } else if (content.idType === CONTENT_TYPES.templateProfessional) {
              ReactDOM.render(
                <ProfessionalBanner
                  professionalName={content.professional?.name}
                  jobtitle={content.professional?.jobTitle?.name}
                  photo={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${content.professional?.login}_192_192.png`}
                  message={content.textContent || ''}
                  key={content.prefix}
                />,
                element,
              );
            } else if (content.idType === CONTENT_TYPES.importantMessage) {
              ReactDOM.render(<ImportantBanner />, element);
            } else if (content.idType === CONTENT_TYPES.iframe) {
              ReactDOM.render(
                <div
                  style={{
                    width: '100%',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: content.textContent || '',
                  }}
                />,
                element,
              );
            } else if (content.idType === CONTENT_TYPES.mosaic) {
              ReactDOM.render(
                <div
                  dangerouslySetInnerHTML={{ __html: '<h1>Mosaico</h1>' }}
                />,
                element,
              );
            } else if (content.idType === CONTENT_TYPES.file) {
              const file =
                content.postContentFiles &&
                content.postContentFiles[0]?.weeklyFile?.uri;

              ReactDOM.render(
                <DownloadFilePost
                  uri={file || ''}
                  text={content.textContent || 'Download file'}
                />,
                element,
              );
            } else if (content.idType === CONTENT_TYPES.image) {
              const file =
                content.postContentFiles &&
                content.postContentFiles[0]?.weeklyFile;

              ReactDOM.render(
                <PostImage
                  uri={file?.uri}
                  alt={file?.name || ''}
                  width={content.width}
                  height={content.height}
                />,
                element,
              );
            } else if (content.idType === CONTENT_TYPES.pdf) {
              const file =
                content.postContentFiles &&
                content.postContentFiles[0]?.weeklyFile?.uri;

              ReactDOM.render(<PDFViewer uri={file || ''} />, element);
            }
          }
        });
      });
    } catch (e) {
      addToast({
        title: 'There was a problem loading this post.',
        type: 'error',
        description: '',
      });
    }
    window.scrollTo(0, 0);
    setLoading(false);
  }, [addToast, postId, setWindowTitle]);

  const reloadComments = useCallback(async () => {
    const { data } = await api.get<IPostComment[]>(
      `api/posts/${postId}/comments`,
    );

    setComments(data);
    setTotalComments(data.length);

    if (onCommentChanged) onCommentChanged(postId, data.length);
  }, [postId, onCommentChanged]);

  const reloadReactions = useCallback(async () => {
    const { data } = await api.get<IPost>(`api/posts/${postId}`);

    if (onReactionChanged) onReactionChanged(postId, data.totalReactions);
  }, [postId, onReactionChanged]);

  const handleSendComment = useCallback(async () => {
    if (newComment?.trim() !== '') {
      await api.post(`/api/posts/${postId}/comments`, {
        comment: newComment?.trim(),
      });
      addToast({
        title: 'Comment added successfully.',
        type: 'success',
        description: '',
      });

      setNewComment('');
      reloadComments();
    }
  }, [addToast, newComment, postId, reloadComments]);

  useEffect(() => {
    contentRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setUserReaction(undefined);
    setText('');
    fetchPost();
  }, [fetchPost]);

  const handleNavigation = useCallback(
    async newPostId => {
      setPostId(newPostId);
      if (onPostChanged) {
        onPostChanged(newPostId);
      }
    },
    [onPostChanged],
  );

  const onEmojiClick = useCallback(
    (emoji: any) => {
      setNewComment(newComment + emoji.native);
      setOpenPicker(false);
    },
    [newComment],
  );

  const emojiFilter = useCallback((emoji: any) => {
    const unavailableEmoji = [
      '263A', // ☺
      '1F595', // 🖕
    ];
    return !unavailableEmoji.some(item => item === emoji.unified);
  }, []);

  const onScroll = useCallback((e: any) => {
    const position = e.target.scrollTop;

    setShowFixedHeader(position >= 275);
  }, []);

  const commentDate = useCallback(commentDateCreated => {
    const toMs = 24 * 60 * 60 * 1000;

    const currDate = new Date();
    const currYear = currDate.getFullYear();

    const commDate = new Date(commentDateCreated);
    const commYear = commDate.getFullYear();

    const defCurrDateHour = new Date(currDate.setHours(0, 0, 0, 0));
    const defCommDateHour = new Date(commDate.setHours(0, 0, 0, 0));

    const diff = defCurrDateHour.getTime() - defCommDateHour.getTime();

    if (diff < 0) {
      return 'Date error';
    }
    if (commYear !== currYear && diff > toMs * 3) {
      return format(commDate, 'LLL dd, y');
    }
    if (diff > toMs * 3) {
      return format(commDate, 'LLL dd');
    }
    if (diff === 0) {
      return 'today';
    }
    if (diff === toMs * 1) {
      return 'yesterday';
    }
    for (let i = 2; i <= 3; i += 1) {
      if (diff <= toMs * i) {
        return `${i} days ago`;
      }
    }
    return 'Date error 2';
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        openReactions &&
        reactionDialog.current &&
        !reactionDialog.current.contains(e.target)
      ) {
        setAnimateReactionDialog(false);

        setTimeout(() => {
          setOpenReactions(false);
        }, 250);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openReactions]);

  const handleOpenReactions = useCallback(() => {
    if (!openReactions) {
      setOpenReactions(true);
      setTimeout(() => {
        setAnimateReactionDialog(true);
      }, 1);
    } else {
      setAnimateReactionDialog(false);
      setTimeout(() => {
        setOpenReactions(false);
      }, 250);
    }
  }, [openReactions]);

  const handleEnterPress = useCallback(event => {
    if (
      commentInput.current?.firstChild === document.activeElement &&
      event.code === 'Enter'
    )
      event.preventDefault();
  }, []);

  const handleReactionSend = useCallback(
    async (idPost, idReaction) => {
      if (openReactions) {
        handleOpenReactions();
      }
      // remove current user reaction
      const reactionsSum = [...totalReactions];
      const index = reactionsSum.findIndex(
        x => x.idReaction === userReaction?.id,
      );
      if (reactionsSum[index]) reactionsSum[index].total -= 1;

      const reaction = reactions.find(x => x.id === idReaction);
      if (reaction?.id === userReaction?.id) setUserReaction(undefined);
      else {
        // add new user reaction
        setUserReaction(reaction);
        setAnimateUserReaction(false);
        setTimeout(() => {
          setAnimateUserReaction(true);
        }, 125);
        const indexNewReaction = reactionsSum.findIndex(
          x => x.idReaction === idReaction,
        );
        if (reactionsSum[indexNewReaction])
          reactionsSum[indexNewReaction].total += 1;
      }

      setTotalReactions(reactionsSum);

      await api.patch(`/api/posts/${idPost}/reactions`, {
        idReaction,
      });

      reloadReactions();
    },
    [
      openReactions,
      totalReactions,
      reactions,
      userReaction,
      reloadReactions,
      handleOpenReactions,
    ],
  );

  const handleKeyPress = useCallback(
    event => {
      if (commentInput.current?.firstChild !== document.activeElement) {
        switch (event.code) {
          case 'KeyF':
            return isFavorited
              ? handleRemoveFavorites(postId)
              : handleSaveFavorites(postId);
          case 'KeyC':
            return handleCommentInput();
          case 'ArrowLeft':
            return previousPost
              ? handleNavigation(previousPost?.id || 0)
              : addToast({
                  title: 'This is the first post!',
                  type: 'error',
                });
          case 'ArrowRight':
            return nextPost
              ? handleNavigation(nextPost?.id || 0)
              : addToast({
                  title: 'This is the last post!',
                  type: 'error',
                });
          case 'Digit1':
            return handleReactionSend(postId, 1);
          case 'Digit2':
            return handleReactionSend(postId, 2);
          case 'Digit3':
            return handleReactionSend(postId, 3);
          case 'Digit4':
            return handleReactionSend(postId, 4);
          case 'Digit5':
            return handleReactionSend(postId, 5);
          default:
            return null;
        }
      }
      if (
        commentInput.current?.firstChild === document.activeElement &&
        event.code === 'Enter'
      ) {
        // event.preventDefault();
        return handleSendComment();
      }
      return null;
    },
    [
      addToast,
      handleCommentInput,
      handleNavigation,
      handleReactionSend,
      handleRemoveFavorites,
      handleSaveFavorites,
      handleSendComment,
      isFavorited,
      nextPost,
      postId,
      previousPost,
    ],
  );

  useEffect(() => {
    window.addEventListener('keyup', handleKeyPress);
    window.addEventListener('keydown', handleEnterPress);

    return () => {
      window.removeEventListener('keyup', handleKeyPress);
      window.removeEventListener('keydown', handleEnterPress);
    };
  }, [handleEnterPress, handleKeyPress]);

  const handleDeleteComment = useCallback((commentId: number) => {
    setDeleteCommentId(commentId);
    setOpenDeleteCommentDialog(true);
  }, []);

  const minSwipeDistance = 100;

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (commentInput.current?.firstChild !== document.activeElement) {
      if (!touchStart || !touchEnd) return;

      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < -minSwipeDistance;
      if (isRightSwipe) {
        if (previousPost) {
          handleNavigation(previousPost?.id || 0);
        } else {
          addToast({
            title: 'This is the first post!',
            type: 'error',
          });
        }
      }

      if (isLeftSwipe) {
        if (nextPost) {
          handleNavigation(nextPost?.id || 0);
        } else {
          addToast({
            title: 'This is the last post!',
            type: 'error',
          });
        }
      }
    }
  };

  return (
    <Container
      onScroll={onScroll}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
    >
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}

      <Content {...rest} className="ql-snow" ref={contentRef}>
        {isPublished && (
          <HeaderIcon>
            <ReactionsContainer
              ref={reactionDialog}
              onClick={handleOpenReactions}
            >
              {openReactions ? (
                <ReactionDialog
                  // style={!isMobile ? { left: '70px' } : { opacity: '1' }}
                  style={
                    animateReactionDialog
                      ? { transform: 'scaleX(100%)' }
                      : { transform: 'scaleX(0)' }
                  }
                >
                  {reactions.map(reaction => (
                    <Tooltip
                      title={reaction.description}
                      key={reaction.id}
                      placement="top"
                    >
                      <ReactionButton
                        onMouseUp={() =>
                          handleReactionSend(postId, reaction.id)
                        }
                      >
                        <ReactionIcon
                          src={reaction.imageURL}
                          alt={reaction.description}
                        />
                        <TotalsBox style={{ right: '-20px' }}>
                          {
                            totalReactions.find(
                              x => x.idReaction === reaction.id,
                            )?.total
                          }
                        </TotalsBox>
                      </ReactionButton>
                    </Tooltip>
                  ))}
                </ReactionDialog>
              ) : (
                ''
              )}
              {userReaction ? (
                <ReactionIcon
                  src={userReaction?.imageURL}
                  alt={userReaction?.description}
                  style={
                    animateUserReaction
                      ? { transform: 'scaleY(1)' }
                      : { transform: 'scaleY(0)' }
                  }
                />
              ) : (
                <FiThumbsUp size={20} />
              )}

              <TotalsBox>{totalReactionsSum}</TotalsBox>
            </ReactionsContainer>

            <HeaderIconButton onClick={handleCommentInput}>
              <FiMessageSquare size={20} />
              <TotalsBox>{totalComments}</TotalsBox>
            </HeaderIconButton>

            <HeaderIconButton
              onClick={() =>
                isFavorited
                  ? handleRemoveFavorites(postId)
                  : handleSaveFavorites(postId)
              }
            >
              <FiStar size={20} color={isFavorited ? '#8b0304' : ''} />
            </HeaderIconButton>

            <HeaderIconButton onClick={handleSharePost}>
              <FiShare2 size={20} />
            </HeaderIconButton>
          </HeaderIcon>
        )}

        <ArticleMainContent>
          <p
            style={{
              color: 'rgb(204 204 204)',
              fontWeight: 'bold',
              fontSize: 12,
            }}
          >
            {new Date(post?.dateCreated ?? new Date()).toLocaleDateString(
              'en-US',
              {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              },
            )}
          </p>
          <ArticleCategory>{category}</ArticleCategory>
          <ArticleTitle>{title}</ArticleTitle>
          <ArticleBody
            className="ql-editor"
            id="content"
            style={{ padding: 0 }}
            dangerouslySetInnerHTML={{ __html: text }}
          />
          <SectionBreak />
          <NavigationContainer>
            {previousPost ? (
              <NavigationButton
                placement="previous"
                onClick={() => handleNavigation(previousPost?.id || 0)}
                style={{ alignItems: 'flex-end' }}
              >
                <NPButton> ⟵ Previous Post</NPButton>
                <div className="categoryName" style={{ textAlign: 'right' }}>
                  {previousPost?.category.name}
                </div>
                <div className="categoryTitle" style={{ textAlign: 'right' }}>
                  {previousPost?.title}
                </div>
              </NavigationButton>
            ) : (
              <div style={{ flex: 1 }} />
            )}
            {nextPost ? (
              <NavigationButton
                onClick={() => handleNavigation(nextPost?.id || 0)}
                placement="next"
                style={{ alignItems: 'flex-start' }}
              >
                <NPButton>Next Post ⟶</NPButton>
                <div className="categoryName" style={{ textAlign: 'left' }}>
                  {nextPost?.category.name}
                </div>
                <div className="categoryTitle" style={{ textAlign: 'left' }}>
                  {nextPost?.title}
                </div>
              </NavigationButton>
            ) : (
              <div style={{ flex: 1 }} />
            )}
          </NavigationContainer>

          {isMobile && <SectionBreak style={{ marginBottom: '0' }} />}
        </ArticleMainContent>
        {isPublished && (
          <ArticleUserInteraction>
            <CommentaryEntry>
              <CommentaryPhoto
                src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${user?.login}_192_192.png`}
              />
              <OutlinedInput
                className="comment"
                ref={commentInput}
                placeholder="Add your comment"
                multiline
                maxRows={15}
                value={newComment}
                onChange={e => setNewComment(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    {!isMobile && (
                      <Tooltip title="Emojis">
                        <CommentaryEntryBtn
                          ref={emojiBtn}
                          onMouseUp={() => {
                            setOpenPicker(x => !x);
                          }}
                        >
                          <FiSmile
                            size={18}
                            color={openPicker ? '#8b0304' : ''}
                          />
                        </CommentaryEntryBtn>
                      </Tooltip>
                    )}
                    <Tooltip title="Send">
                      <CommentaryEntryBtn onClick={handleSendComment}>
                        <FiSend size={18} />
                      </CommentaryEntryBtn>
                    </Tooltip>
                  </InputAdornment>
                }
              />
              {openPicker && (
                <PickerWrapper ref={emojiPicker}>
                  <Picker
                    color="#8b0304"
                    showSkinTones={false}
                    native
                    include={['people', 'recent']}
                    onSelect={emoji => onEmojiClick(emoji)}
                    showPreview={false}
                    style={{
                      position: 'absolute',
                      zIndex: '999',
                      top: '110%',
                      right: '50%',
                      transform: 'translateX(50%)',
                      width: '90%',
                      borderRadius: '10px',
                      boxShadow: '0 0 30px rgba(0 0 0 / 15%)',
                      border: 'none',
                      overflow: 'hidden',
                      padding: '10px 0 10px 10px',
                    }}
                    emojisToShowFilter={emoji => emojiFilter(emoji)}
                  />
                </PickerWrapper>
              )}
            </CommentaryEntry>
            <CommentaryShowed>
              {comments.map(comment => (
                <CommentaryWrapper key={comment.id}>
                  <CommentaryPhoto
                    src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${comment.professional?.login}_192_192.png`}
                  />
                  <CommentaryBox>
                    <CommentaryAuthor>
                      {comment.professional?.name}
                      <CommentaryDate>
                        {commentDate(comment.dateCreated)}
                      </CommentaryDate>
                    </CommentaryAuthor>
                    <CommentaryText>{comment.comment}</CommentaryText>
                    {(hasScopes([weeklyGroups.Admin]) ||
                      comment.idUser === user.userId) && (
                      <Tooltip title="Delete">
                        <RemoveCommentary
                          onClick={() => handleDeleteComment(comment.id)}
                        >
                          <FiTrash2 size={18} />
                        </RemoveCommentary>
                      </Tooltip>
                    )}
                  </CommentaryBox>
                </CommentaryWrapper>
              ))}
            </CommentaryShowed>
          </ArticleUserInteraction>
        )}
      </Content>

      {openDeleteCommentDialog && (
        <CommentDeleteDialog
          open={openDeleteCommentDialog}
          handleClose={() => {
            setOpenDeleteCommentDialog(false);
            reloadComments();
          }}
          deleteCommentId={deleteCommentId}
          postId={postId}
        />
      )}
    </Container>
  );
};
