import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const MyDialog = styled(Dialog)`
  .MuiPaper-root {
    border-radius: 20px;
    position: fixed;
    width: 100%;
    margin: auto;
  }
`;

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin-bottom: 30px;

  .top {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 15px;
    z-index: 3;
    background-color: white;
    box-shadow: 0 10px 30px white;

    @media (max-width: 768px) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .fiXIcon {
    padding: 0;
  }

  h1 {
    text-align: left;
    font-size: 1.6rem;
    font-weight: 700;

    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 1;
  }

  .videoWrapper {
    padding: 56.25% 0 0 0;
    position: relative;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .postInfo {
    gap: 30px;
    max-width: 650px;

    & > div {
      margin-left: 30px;
      margin-right: 30px;

      @media (max-width: 768px) {
        margin-left: 15px;
        margin-right: 15px;
      }
    }
  }

  .postSelectors {
    display: flex;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 0;
    }
  }

  .dx-fileuploader-wrapper {
    padding: 0;
  }

  .sendContentBtn {
    margin: auto;
  }
`;
