import React from 'react';

import { Container, Info, Message, Photo } from './styles';

export const ImportantBanner: React.FC = () => {
  return (
    <Container>
      <Info>
        <Photo
          src="https://weekly.integrationconsulting.com/Upload/1.6_Banners/exclamation.gif"
          alt=""
        />
      </Info>
      <Message>important message</Message>
    </Container>
  );
};
