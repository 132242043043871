import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const ErrorMessage = styled.span`
  font-size: 14px;
  color: #d15c56;
  font-weight: 700;
  margin-left: 2px;
  margin-top: 5px;
`;

export const ContainerInput = styled.input`
  width: 100%;
  font-size: 14px;
  background-color: #dedede;
  border: 2px solid #dedede;
  outline: none;
  border-radius: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  padding: 0 15px;
  height: 42px;

  &:focus {
    border: 2px solid #8b0304;
  }
`;
