import React, { useCallback, useMemo, useState } from 'react';

import FileManagerExtreme, {
  Permissions,
  ItemView,
  Toolbar,
  ToolbarItem,
} from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';

import fileIcon from '../../../assets/icons/file.png';
import filePdf from '../../../assets/icons/pdf.png';
import { Container } from './styles';
import api, { IWeeklyFile } from '../../../services/api';

type Props = {
  postId: number;
  isOldRender: boolean;
};

export const FileManager: React.FC<Props> = ({ postId, isOldRender }) => {
  const [viewMode, setViewMode] = useState('thumbnails');

  const onOptionChanged = useCallback(e => {
    if (e.fullName === 'itemView.mode') {
      setViewMode(e.value);
    }
  }, []);

  const getIcon = useCallback((thumb: string, contentType: string) => {
    if (thumb && contentType.includes('image')) return `${thumb}`;

    if (contentType.includes('pdf')) return filePdf;

    return fileIcon;
  }, []);

  // Creates a custom file system provider
  const customFileProvider = useMemo(
    () =>
      new CustomFileSystemProvider({
        keyExpr: 'id',
        dateModifiedExpr: 'updatedAt',
        // Function to get file system items
        getItems: async fileItem => {
          const params: { idParent: string; isPost: boolean } = {} as {
            idParent: string;
            isPost: boolean;
          };

          params.idParent = `${fileItem.key || postId}`;
          params.isPost = !fileItem.key;

          const { data } = await api.get<IWeeklyFile[]>(`/api/weekly-files`, {
            params,
          });

          return data.map(file => ({
            id: file.id,
            name: file.name,
            isDirectory: file.isDirectory,
            size: file.fileSize,
            thumbnail:
              !file.isDirectory && getIcon(file.uri || '', file.contentType),
          }));
        },
        // Functions to handle file operations
        // createDirectory: async (parentDirectory, name) => {
        //   await api.post(`/api/weekly-files/directory`, {
        //     directoryName: name,
        //     idParent: parentDirectory.key || postId,
        //   });
        // },
        deleteItem: async item => {
          await api.delete(`/api/weekly-files/${item.key}`);
        },
        moveItem: async (item, directory) => {
          await api.patch(`/api/weekly-files/${item.key}/parent`, {
            idParent: directory.key || postId,
            isRoot: !directory.key,
          });
        },
        downloadItems: async items => {
          const ids = items.map(x => x.key);
          const { data, headers } = await api.get(
            `/api/weekly-files/download?files=[${ids.join(
              ',',
            )}]&isOldRender=${isOldRender}`,
            {
              responseType: 'blob',
            },
          );

          const downloadUrl = window.URL.createObjectURL(new Blob([data]));

          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', headers['x-file-name']);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        uploadFileChunk: async (file, uploadInfo, directory) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('idParent', directory.key || `${postId}`);
          await api.post(`/api/weekly-files/files`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
              'X-CHUNK-TOTAL': `${uploadInfo.chunkCount}`,
              'X-CHUNK-INDEX': `${uploadInfo.chunkIndex + 1}`,
            },
          });
        },
      }),
    [postId, getIcon, isOldRender],
  );

  return (
    <Container>
      <FileManagerExtreme
        onOptionChanged={onOptionChanged}
        fileSystemProvider={customFileProvider}
      >
        <Toolbar>
          <ToolbarItem name="upload" />
          <ToolbarItem name="separator" />
          <ToolbarItem name="switchView" />
          <ToolbarItem name="refresh" />
        </Toolbar>
        <ItemView mode={viewMode} />
        <Permissions
          copy={!isOldRender}
          move={!isOldRender}
          delete={!isOldRender}
          rename={!isOldRender}
          upload={!isOldRender}
          download
        />
      </FileManagerExtreme>
    </Container>
  );
};
