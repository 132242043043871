import { Dialog, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { TextArea } from 'devextreme-react';

import React, { BaseHTMLAttributes, useCallback, useState } from 'react';
import { BiMessageDetail, BiUserVoice } from 'react-icons/bi';
import { FiX } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../components/Button';
import { useToast } from '../../../hooks/toast';
import api, { IPostComment } from '../../../services/api';
import { Body, Container } from './styles';

type DialogCustomProps = BaseHTMLAttributes<HTMLDivElement> & {
  open: boolean;
  handleClose(): void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const FeedbackDialog: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState<IPostComment[]>([]);

  const handleSendFeedback = useCallback(async () => {
    setLoading(true);
    await api.post(`/api/feedback`, {
      text: feedback,
    });
    addToast({
      title: 'Feedback sent successfully.',
      type: 'success',
      description: '',
    });
    setLoading(false);
    handleClose();
  }, [addToast, feedback, handleClose]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '20px',
        },
      }}
    >
      <Container>
        <Body>
          <IconButton onClick={handleClose} style={{ alignSelf: 'flex-end' }}>
            <FiX />
          </IconButton>
          <div className="audience">
            <h1>
              <BiUserVoice color="#8b0304" /> ListenInt
            </h1>
            <p>
              The <b>Professional Development Cell</b> is an open channel for
              you to clarify doubts, ask questions and send suggestions.
              Respecting the confidentiality of anyone who sends an email to our
              cell, names will not be disclosed in any company material.
            </p>
            <Button primary className="mailtoBtn">
              <a href="mailto:listenint@integrationconsulting.com">
                SEND YOUR EMAIL
              </a>
            </Button>
          </div>
          <div className="feedback">
            <h1>
              <BiMessageDetail color="#8b0304" />
              Weekly
            </h1>
            <p>
              If you have any suggestions for improvements, features or
              encountered a problem with the <b>Weekly</b>, please contact us
              via the button below.
            </p>
            <Button primary className="mailtoBtn">
              <a href="mailto:weekly@integrationconsulting.com">
                SEND YOUR EMAIL
              </a>
            </Button>
          </div>
        </Body>
      </Container>
    </Dialog>
  );
};
