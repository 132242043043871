import { lighten } from 'polished';
import styled, { css, keyframes, createGlobalStyle } from 'styled-components';
import { fadeIn } from 'react-animations';
import img from '../../assets/images/astronaut-bg.png';

export default createGlobalStyle`
  * {
    font-family: "Roboto Condensed", sans-serif ;
    }`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  overflow: auto;
`;

const fadeInAnimation = keyframes`${fadeIn}`;
const moveUpDown = keyframes`  
  0%, 100% {
  transform: translateY(0%);
  }
50% {
  transform: translateY(15%);
}
`;

export const WrapBox = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  animation: 0.5s ${fadeInAnimation};
`;

export const MenuBox = styled.div`
  text-align: -webkit-center;
  width: 96.5%;
  animation: 0.5s ${fadeInAnimation};
`;

type HeaderProps = {
  cover?: string;
};

export const HeaderContainerBox = styled.div<HeaderProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  ${props => css`
    background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.5) 0%,
        rgba(0, 0, 0, 0.15) 25%
      ),
      url('${props.cover || img}');
  `}
  border-radius: 0px 0px 20px 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
  position: relative;
  align-items: center;
`;

export const UserAreaButton = styled.button`
  display: flex;
  letter-spacing: 0.5px;
  flex-direction: column;
  align-items: center;
  background: #fff;
  color: #8b0304;
  font-size: 12px;
  font-weight: 700;
  border-style: none;
  border: 1px solid;
  border: none;
  padding: 0px 12px;
  padding-top: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  cursor: pointer;
  animation: 0.5s ${fadeInAnimation};
  transition: 0.2s;
  &:hover {
    color: ${lighten(0.1, '#8b0304')};
  }
`;

export const HeaderSearch = styled.div`
  width: 20%;

  .MuiOutlinedInput-root {
    border-radius: 28px;
    border-color: #fff;
  }

  .MuiInputBase-root {
    border-radius: 28px;
    border-color: #fff;
  }

  .MuiInputBase-colorPrimary {
    border-radius: 28px;
    border-color: #fff;
  }

  .MuiInputBase-fullWidth {
    border-radius: 28px;
    border-color: #fff;
  }

  .MuiInputBase-formControl {
    border-radius: 28px;
    border-color: #fff;
  }

  .css-1a0q5i4-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 28px;
    color: #fff;
  }

  .css-e6nygj-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff;
  }
`;

export const SearchButton = styled.button`
  background: none;
  border: none;
`;

type Overlay = {
  overlay?: boolean;
};

export const SectionMenu = styled.div<Overlay>`
  position: fixed;
  top: 60px;
  z-index: 3;
  background-color: transparent;
  box-shadow: 0 0 0 transparent;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  padding: 15px 2em;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  /* @media (max-width: 1024px) {
    top: 0;
    flex-direction: column;
    gap: 25px;
    height: 100%;
    background-color: white;
  } */

  ${props =>
    props.overlay &&
    css`
      background: white;
      box-shadow: 0 4px 30px rgba(0 0 0 / 15%);
    `}

  & button {
    cursor: pointer;
    border-radius: 20px;
    background-color: white;
    display: flex;
    gap: 1em;
    justify-content: center;
    align-items: center;
    padding: 0.2em 1em;
    font-size: 0.9rem;
    font-weight: 700;
    color: #bbb;
    text-decoration: none;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    div {
      display: flex;
      align-items: center;
      gap: 0.25em;
    }

    ${props =>
      props.overlay &&
      css`
        background-color: #eee;
      `}

    &:hover {
      color: #333;
    }
  }
`;

type ButtonProps = {
  selected?: boolean;
};

export const SectionMenuButton = styled.button<ButtonProps>`
  &:hover {
    color: #333;
  }

  ${props =>
    props.selected &&
    css`
      color: #333 !important;
    `}
`;

export const TitleBox = styled.div`
  max-width: 620px;
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  z-index: 2;
`;

export const MainEdition = styled.h2`
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-align: center;
  color: #fff;
`;

export const MainTitle = styled.h1`
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
`;

export const ScrollDown = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 1vh;
  width: 40px;
  height: 40px;
  animation: ${moveUpDown} 1.5s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  & svg {
    fill: none;
  }
`;

type Props = {
  background?: string;
};

export const Content = styled.div<Props>`
  background-color: #fff;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  ${props => css`
    background-image: url('${props.background || ''}');
  `}
  background-size: cover;
  background-repeat: repeat;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  margin: 0 30px;

  @media (max-width: 1024px) {
    margin: 0 10px;
  }
`;

export const BirthdayArea = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 20px 75px 0 75px;
  border: none;
  background: none;
  scroll-margin-top: 80px;
  background-color: white;
  border-radius: 20px;
  /* box-shadow: rgb(0 0 0 / 7%) 0px 4px 30px; */

  @media (max-width: 600px) {
    padding: 20px 0 0;
    overflow: hidden;
  }
`;

export const BirthdayTop = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #ccc;
  line-height: 1;
  margin-bottom: 1em;
  text-transform: uppercase;
  text-align: center;
`;

export const BirthdayBox = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1vw;
  position: relative;
  width: 100%;
  max-width: 1000px;
  margin: auto;

  @media (max-width: 600px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    padding: 15px 3vw;
    overflow-x: scroll;
    overflow-y: visible;
  }
`;

export const PhotoWrapper = styled.div`
  display: flex;
`;

export const BirthdayDetails = styled.div`
  cursor: pointer;
  display: flex;
  line-height: 14px !important;
  position: relative;
  line-height: 14px;
  padding: 14px;
  min-height: 200px;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: none;
  margin: 0 auto;
  margin: 4px;
  border-radius: 8px;
  background: #fff;

  transition: all 0.25s ease-in-out;

  &:hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  }
`;

export const BirthdayEmail = styled.a``;

export const BirthdayNameWrapper = styled.div`
  max-width: 68%;
  line-height: 12px !important;
`;

export const BirthdayName = styled.div`
  display: contents !important;
  margin: 0 auto;
  align-self: center;
  text-align: center;
  line-height: 6px !important;
  font-size: 14px;
  font-weight: 700;
  color: #8b0304;
`;

export const Birthdate = styled.h3`
  font-size: 20px;
  padding: 10px;
  align-self: center;
  text-align: center;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  color: #333;
`;

export const SectionTitle = styled.h2`
  text-align: center;
  font-size: 1.5rem;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #ccc;
  line-height: 1;
  margin: 25px 0;
  text-transform: uppercase;
  position: relative;
  scroll-margin-top: 90px;

  &:after {
    content: '';
    background-color: #ccc;
    width: 1em;
    height: 0.15em;
    position: absolute;
    top: 1.5em;
    left: 50%;
    transform: translateX(-50%);
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 50px 0;
  gap: 25px;
  width: 100%;
  padding: 0 10px;
`;

export const ThumbUpIconButton = styled.button`
  border: none;
  background: transparent;
  margin-left: 8px;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    color: ${lighten(0.02, '#8b0304')};
  }
  &:focus {
    color: ${lighten(0.05, '#8b0304')};
  }
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 32px;
  gap: 4px;
`;

export const ContentButtonsWrapper = styled.div``;

export const ContentButtonIcons = styled.button`
  background: none;
  border: none;
  color: #8b0304;
  cursor: pointer;

  &:hover {
    color: ${lighten(0.1, '#8b0304')};
  }
`;

export const ReadMoreButton = styled.button`
  font-size: 12px;
  align-self: flex-end;
  font-weight: 600;
  background: none;
  color: #8b0304;
  border: none;
  cursor: pointer;

  transition: transform 200ms;

  &:hover {
    color: ${lighten(0.1, '#8b0304')};
    transform: scale(1.02);
  }
`;

export const CommentaryShowed = styled.div`
  margin-top: 14px;
`;

export const CommentaryBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const CommentaryPhoto = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 20px;
`;

export const CommentaryAuthor = styled.h2`
  font-size: 14px;
  font-weight: 700;
`;

export const CommentaryText = styled.p``;

export const ContainerLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;
`;

export const LoadingPage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NavigationContainer = styled.div`
  display: flex;
  background-color: #f5f5f5;
  gap: 2em;
  padding-bottom: 30px;
  padding-top: 30px;
`;

type NavigationProps = {
  placement: 'next' | 'previous';
};

export const NavigationButton = styled.button<NavigationProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  background: none;
  border: none;
  width: 50%;

  color: #999;
  font-weight: 700;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.75;

  .categoryTitle {
    color: #333;
  }

  .categoryName {
    font-size: clamp(0.7rem, 2.5vw, 1rem);
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  transition: 0.2s all;

  &:hover {
    opacity: 1;
    /* transform: scale(1.04); */
  }
`;

export const NPButton = styled.div`
  cursor: pointer;
  background: none;

  transition: 0.2s all;

  ${NavigationButton}:hover & {
    color: #8b0304;
    /* transform: scale(1.04); */
  }
`;
