import styled, { css } from 'styled-components';

type ContainerProps = {
  uri?: string;
};

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .cssDataGrid .dx-cell-focus-disabled .dx-datagrid-text-content {
    color: #ccc;
  }
`;

export const Header = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  min-height: 150px;
  overflow: auto;
  padding: 40px;
  align-items: flex-start;
  ${props =>
    props.uri &&
    css`
      background-image: linear-gradient(
          to bottom,
          rgba(245, 245, 245, 0),
          rgba(245, 245, 245, 0.7)
        ),
        url(${props.uri});
    `}

  background-position: center;
  background-size: cover;
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;

export const Photo = styled.img`
  max-width: 50px;
  border-radius: 50%;
`;
