import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiImage,
  FiCode,
  FiUsers,
  FiEdit2,
  FiCopy,
  FiX,
  FiTrash2,
  FiAlertTriangle,
} from 'react-icons/fi';
import { BiImages, BiGlobe, BiBarChart, BiFile } from 'react-icons/bi';

import { IconButton, Tooltip } from '@mui/material';
import { Button } from '../../Button';
import api, { IPostContent } from '../../../services/api';
import { ContentAdd } from '../ContentAdd';
import { ContentEdit, CONTENT_TYPES } from '../ContentEdit';

import { Container, Header, ContentList, ContentItem } from './styles';
import { useToast } from '../../../hooks/toast';

interface Props {
  postId: number;
}

export const ContentTab: React.FC<Props> = ({ postId }) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditId, setOpenEditId] = useState(0);
  const [contents, setContents] = useState<IPostContent[]>([]);

  const icons = useMemo(
    () => [
      { id: CONTENT_TYPES.gallery, icon: <BiImages size={20} /> },
      { id: CONTENT_TYPES.mosaic, icon: <BiImages size={20} /> },
      { id: CONTENT_TYPES.image, icon: <FiImage size={20} /> },
      { id: CONTENT_TYPES.globalStorage, icon: <BiGlobe size={20} /> },
      { id: CONTENT_TYPES.pdf, icon: <BiFile size={20} /> },
      { id: CONTENT_TYPES.iframe, icon: <FiCode size={20} /> },
      { id: CONTENT_TYPES.templateProfessional, icon: <FiUsers size={20} /> },
      { id: CONTENT_TYPES.templateSalesChart, icon: <BiBarChart size={20} /> },
      { id: CONTENT_TYPES.file, icon: <BiFile size={20} /> },
      {
        id: CONTENT_TYPES.importantMessage,
        icon: <FiAlertTriangle size={20} />,
      },
    ],
    [],
  );

  const getIcon = useCallback(
    idType => {
      const icon = icons.find(x => x.id === idType);

      return icon?.icon;
    },
    [icons],
  );

  const fetchData = useCallback(async () => {
    const { data } = await api.get<IPostContent[]>(
      `api/posts/${postId}/contents`,
    );

    setContents(data);
  }, [postId]);

  const handleDeleteContent = useCallback(
    async contentId => {
      setLoading(true);
      await api.delete(`api/posts/${postId}/contents/${contentId}`);
      fetchData();
      setLoading(false);
    },
    [fetchData, postId],
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAdd = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleClickItem = useCallback(
    prefix => {
      navigator.clipboard.writeText(prefix);
      addToast({
        type: 'info',
        title: 'Copied!',
        description: 'Paste on editor',
      });
    },
    [addToast],
  );

  const handleEdit = useCallback(() => {
    fetchData();
  }, [fetchData]);

  const handleOpenEdit = useCallback(contentId => {
    setOpenEditId(contentId);
    setOpenEdit(true);
  }, []);

  return (
    <Container>
      <Header>
        <p>Add and manage content for this post</p>
        <Button small onClick={() => setOpenAdd(true)}>
          Add content
        </Button>
      </Header>
      <ContentList>
        {contents.length <= 0 && <p>No contents</p>}
        {contents.map(content => (
          <ContentItem key={content.id}>
            <div className="icon">{getIcon(content.idType)}</div>
            <div className="content">
              <p>
                {content.postContentType?.description}{' '}
                {content.alias && ` - ${content.alias}`}
              </p>
              <span>{content.prefix}</span>
            </div>
            <div className="edit">
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  onClick={() => handleOpenEdit(content.id)}
                >
                  <FiEdit2 />
                </IconButton>
              </Tooltip>
              <Tooltip title="Copy">
                <IconButton
                  size="small"
                  onClick={() => handleClickItem(content.prefix)}
                >
                  <FiCopy />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  size="small"
                  onClick={() => handleDeleteContent(content.id)}
                >
                  <FiTrash2 />
                </IconButton>
              </Tooltip>
            </div>
          </ContentItem>
        ))}
      </ContentList>

      {openAdd && (
        <ContentAdd
          handleClose={() => setOpenAdd(false)}
          open={openAdd}
          onAdded={handleAdd}
          postId={postId}
        />
      )}

      {openEdit && (
        <ContentEdit
          handleClose={() => setOpenEdit(false)}
          open={openEdit}
          onSaved={handleEdit}
          postId={postId}
          contentId={openEditId}
        />
      )}
    </Container>
  );
};
