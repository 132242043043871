import { Box, Drawer, IconButton } from '@mui/material';
import { formatInTimeZone } from 'date-fns-tz';

import React, { useCallback, useEffect, useState } from 'react';
import { FiTrash2, FiX } from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { useToast } from '../../../hooks/toast';
import { useDocumentTitle } from '../../../hooks/documentTitle';

import api, {
  IPost,
  IPostContent,
  IPostFavorites,
} from '../../../services/api';
import {
  FavoriteDate,
  FavoriteInfoWrapper,
  FavoritesHeader,
  HeaderBox,
  HeaderTitle,
  IconXWrapper,
  LoadingContainer,
  RemoveFavorite,
  SearchFavoritsInput,
  Top,
} from './styles';

interface DialogCustomProps {
  openFavorites: boolean;
  handleClose(): void;
  onClickPost: (postId: number) => void;
  onRemovePost: (postId: number, value: boolean) => void;
}

export const FavoritesContent: React.FC<DialogCustomProps> = ({
  openFavorites,
  handleClose,
  onClickPost,
  onRemovePost,
}) => {
  const { setTitle } = useDocumentTitle();
  const [loading, setLoading] = useState(false);
  const [favoritePosts, setFavoritePosts] = useState<IPostFavorites[]>([]);
  const [query, setQuery] = useState('');

  const { addToast } = useToast();

  const favoriteLoading = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IPostFavorites[]>(`api/posts-favorites`);
    setFavoritePosts(data);
    setLoading(false);
  }, []);

  const handleRemoveFavorites = useCallback(
    async postId => {
      try {
        await api.delete(`/api/posts/${postId}/favorites`);
        favoriteLoading();
        onRemovePost(postId, false);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, favoriteLoading, onRemovePost],
  );

  useEffect(() => {
    setTitle('My favorites');
    favoriteLoading();
  }, [favoriteLoading, setTitle]);

  return (
    <Drawer
      anchor="right"
      open={openFavorites}
      onClose={handleClose}
      variant="temporary"
      PaperProps={{
        sx: {
          borderRadius: '0',
          width: '100%',
          maxWidth: '425px',
        },
      }}
    >
      <Box>
        <Top>
          <IconXWrapper>
            <FavoritesHeader>Favorites</FavoritesHeader>
            <IconButton className="fiXIcon" onClick={handleClose}>
              <FiX size={24} />
            </IconButton>
          </IconXWrapper>

          <SearchFavoritsInput
            placeholder="Search..."
            onChange={event => setQuery(event.target.value)}
          />
          <hr />
        </Top>
        {favoritePosts.length > 0 ? (
          favoritePosts
            .filter(favoritePost => {
              if (query === '') {
                return favoritePost;
              }
              if (
                favoritePost.post.title
                  .toLowerCase()
                  .includes(query.toLowerCase()) ||
                favoritePost.post.category.name
                  .toLowerCase()
                  .includes(query.toLowerCase())
              ) {
                return favoritePost;
              }
              return null;
            })
            .reverse()
            .map(favoritePost => (
              <HeaderBox key={favoritePost.id}>
                {/* <img
                  className="favoriteImg"
                  src={
                    favoritePost.post.mainImage?.uri ||
                    'https://images.unsplash.com/photo-1659270102126-97cc33f3f757?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80' // trocar
                  }
                  alt=""
                /> */}
                <FavoriteInfoWrapper>
                  <FavoriteDate
                    onClick={() => {
                      handleClose();
                      onClickPost(favoritePost.post.id);
                    }}
                  >
                    {formatInTimeZone(
                      favoritePost.post.dateCreated,
                      'Europe/London',
                      'dd MMM yyyy',
                    ).toUpperCase()}
                  </FavoriteDate>
                  <HeaderTitle
                    onClick={() => {
                      handleClose();
                      onClickPost(favoritePost.post.id);
                    }}
                  >
                    {favoritePost.post.title}
                  </HeaderTitle>
                </FavoriteInfoWrapper>

                <RemoveFavorite
                  onClick={() => handleRemoveFavorites(favoritePost.post.id)}
                >
                  <FiTrash2 size={18} />
                </RemoveFavorite>
              </HeaderBox>
            ))
        ) : (
          <FavoritesHeader>No favorites yet</FavoritesHeader>
        )}
      </Box>

      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </LoadingContainer>
      )}
    </Drawer>
  );
};
