import React, { useEffect, useCallback, useState } from 'react';
import axios, { AxiosError } from 'axios';

import CustomStore from 'devextreme/data/custom_store';

import { DataGrid } from 'devextreme-react';
import {
  Column,
  Editing,
  HeaderFilter,
  Scrolling,
  SearchPanel,
} from 'devextreme-react/data-grid';
import DataSource from 'devextreme/data/data_source';
import { BoardBody } from '../../../components/BoardBody';
import { BoardHeader } from '../../../components/BoardHeader';
import { Container } from './styles';
import { Button } from '../../../components/Button';
import { DialogNewTag } from './DialogNewTag';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

export const Tag: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [tags, setTags] = useState<DataSource>();
  const { addToast } = useToast();

  const loadTags = useCallback(async () => {
    const tagsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/post-tags');
        return data.sort((a: any, b: any) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
      },
      update: async (id, data) => {
        try {
          await api.put(`/api/post-tags/${id}`, {
            name: data.name,
          });
        } catch (error) {
          if (axios.isAxiosError(error)) {
            const err = error as AxiosError;
            addToast({
              title: err.response?.data,
              type: 'error',
            });
          } else {
            addToast({
              title: 'Something went wrong...',
              type: 'error',
            });
          }
        }
      },
    });

    setTags(
      new DataSource({
        store: tagsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadTags();
  }, [loadTags]);

  return (
    <ApplicationLayers>
      <Container>
        <BoardHeader title="Tags" subtitle="Tags" />

        <BoardBody>
          <Button className="newTagBtn" primary onClick={() => setOpen(true)}>
            NEW TAG
          </Button>
          <DataGrid hoverStateEnabled dataSource={tags}>
            <SearchPanel width={1260} visible placeholder="Search here" />
            <Editing allowUpdating />
            <HeaderFilter visible allowSearch />
            <Scrolling mode="infinite" />
            <Column dataField="name" />
          </DataGrid>
        </BoardBody>
        {open && (
          <DialogNewTag
            open={open}
            handleClose={() => {
              setOpen(false);
              tags?.reload();
            }}
          />
        )}
      </Container>
    </ApplicationLayers>
  );
};
