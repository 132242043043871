import styled from 'styled-components';

export const Container = styled.a`
  font-weight: 700;
  color: #8b0304;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e6e7e9;
  border-radius: 18px;
  text-decoration: none;

  & > svg {
    margin-right: 15px;
  }
`;
