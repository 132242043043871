import React, { useCallback, useState, useEffect } from 'react';

import { useForm, Controller } from 'react-hook-form';

import CustomStore from 'devextreme/data/custom_store';
import { SelectBox, TextBox } from 'devextreme-react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import api, { IPostContentType } from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { FormGroup } from '../../FormGroup';
import { Button } from '../../Button';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  onAdded?(): void;
  postId: number;
}

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const ContentAdd: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  onAdded,
  postId,
}) => {
  const [loading, setLoading] = useState(false);
  const [types] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IPostContentType[]>(
          'api/posts-content-types',
        );
        return data.filter(x => x.isActive);
      },
    }),
    paginate: true,
  });

  const { addToast } = useToast();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const handleSave = useCallback(
    async data => {
      try {
        setLoading(true);
        await api.post(`api/posts/${postId}/contents`, data);
        setLoading(false);
        if (onAdded) onAdded();
        handleClose();
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [postId, onAdded, handleClose, addToast],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add content</DialogTitle>
      <form onSubmit={handleSubmit(handleSave)}>
        <DialogContent>
          <FormGroup fieldSetLabel="Type*">
            <Controller
              name="idType"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <SelectBox
                  valueExpr="id"
                  displayExpr="description"
                  dataSource={types}
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Name">
            <Controller
              name="alias"
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <TextBox
                  onValueChanged={e => field.onChange(e.value)}
                  value={field.value}
                />
              )}
            />
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" primary loading={loading}>
            Create content
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
