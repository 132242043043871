import React from 'react';

import { Image } from './styles';

interface Props {
  uri?: string;
  alt: string;
  width?: number;
  height?: number;
}

export const PostImage: React.FC<Props> = ({ alt, uri, width, height }) => {
  return <Image src={uri} alt={alt} width={width} height={height} />;
};
