import styled, { css, keyframes } from 'styled-components';

import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

// export const Container = styled.div`
//   position: fixed;
//   top: 0;
//   width: 100%;
//   z-index: 10;

//   animation: 0.5s ${fadeInAnimation};
// `;

export const Header = styled.div<Overlay>`
  padding: 10px 2em;
  position: fixed;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: white;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  z-index: 4;
  top: 0;

  @media (min-width: 1025px) {
    ${props =>
      props.overlay &&
      css`
        transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
        background-color: transparent;
        box-shadow: 0 0 0 transparent;
      `}
  }

  @media (max-width: 1024px) {
    padding: 10px 1em;
  }
`;

export const LogoBox = styled.div`
  display: flex;
  animation: 0.5s ${fadeInAnimation};
  cursor: pointer;
`;

export const WeeklyLogo = styled.img`
  align-self: center;
  max-width: 120px;
  @media (max-width: 1024px) {
    max-width: 100px;
  }
`;

export const OpenMenuButton = styled.div<Overlay>`
  outline: none;
  border: none;
  padding: 5px;
  color: #bbb;
  background-color: transparent;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    color: #8b0304;
    & svg {
      fill: #8b0304;
    }
  }

  ${props =>
    props.overlay &&
    css`
      color: white;

      & svg {
        fill: white;
      }
    `}
`;

type Overlay = {
  overlay?: boolean;
  selected?: boolean;
};

export const MyWeeklyHeader = styled.ul`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

  @media (max-width: 1024px) {
    position: fixed;
    background: white;
    width: 60%;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 40px 15px 15px 15px;
    top: 0;
    right: 0;
    height: 100%;
    z-index: -1;
    transform: translateX(200%);
  }
`;
export const Overlay = styled.div`
  z-index: -2;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const UserMenuButtons = styled.li<Overlay>`
  cursor: pointer;
  gap: 0.5em;
  display: flex;
  align-items: center;
  border: none;
  padding: 0.2em;
  color: #bbb;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  font-size: 0.9rem;
  background: transparent;
  font-weight: 700;
  animation: 0.5s ${fadeInAnimation};
  transition: 0.2s;

  & svg {
    fill: #bbb;
    transition: 0.2s;
    width: 1.2em;
    height: 1.2em;
  }

  &:hover {
    color: #8b0304;
    & svg {
      fill: #8b0304;
    }
  }

  ${props =>
    props.selected &&
    css`
      color: #8b0304;
      & svg {
        fill: #8b0304;
      }
    `}

  ${props =>
    props.overlay &&
    css`
      color: white;

      & svg {
        fill: white;
      }
    `}

  @media (max-width: 1024px) {
    font-size: 1rem;
    margin-top: 1em;
    color: #bbb;

    & svg {
      fill: #bbb;
    }
  }
`;
