import React, { useCallback, useEffect, useState } from 'react';

import { Controller, useForm } from 'react-hook-form';

import { WhisperSpinner } from 'react-spinners-kit';
import CustomStore from 'devextreme/data/custom_store';
import { TextBox as DxTextBox } from 'devextreme-react/text-box';
import { Container, ContainerLoading } from './styles';
import api, {
  ICategory,
  IPost,
  IPostTag,
  IWeeklyFile,
} from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { FormGroup } from '../../FormGroup';
import { TextBox } from '../../TextBox';
import { TextArea } from '../../TextArea';
import { SelectBox } from '../../SelectBox';
import { TagBox } from '../../TagBox';

type Props = {
  postId: number;
};

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const PostTab: React.FC<Props> = ({ postId }) => {
  const [loading, setLoading] = useState(false);
  const [tagsRaw, setTagsRaw] = useState<IPostTag[]>([]);
  const [tags, setTags] = useState([] as number[]);
  const [abstract, setAbstract] = useState('');
  const { control, setValue } = useForm();
  const { addToast } = useToast();

  const [images] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/weekly-files?idParent=${postId}&isPost=true`,
        );
        return data.filter((x: IWeeklyFile) => !!x.uri);
      },
    }),
    paginate: true,
  });

  const [editions] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('api/editions');
        return data;
      },
    }),
    paginate: true,
  });

  const [categories] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<ICategory[]>('api/categories');
        return data.filter(x => !!x.idParentCategory && x.idStatus === 1);
      },
    }),
    paginate: true,
  });

  const [postTags] = useState<CustomStoreProps>({
    store: new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('api/post-tags');
        setTagsRaw(data);
        return data;
      },
    }),
    paginate: true,
  });

  const fetchPost = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IPost>(`api/posts/${postId}`);
    setTags(data.postTags.map(x => x.id));
    setValue('title', data.title);
    setValue('idEdition', data.idEdition);
    setValue('idCategory', data.idCategory);
    setValue('idWeeklyFile', data.idWeeklyFile);
    setAbstract(data.abstract);
    setLoading(false);
  }, [postId, setValue]);

  useEffect(() => {
    fetchPost();
  }, [fetchPost]);

  const onSubmit = useCallback(
    async (fieldName, value) => {
      try {
        if (fieldName === 'tags') {
          const tagsString = tagsRaw
            .filter(x => value.includes(x.id))
            .map(x => x.name)
            .join(',');
          await api.put(`api/posts/${postId}`, {
            tags: tagsString,
          });
        } else if (fieldName === 'idEdition' && value === null) {
          await api.put(`api/posts/${postId}`, {
            idEdition: 0,
          });
        } else {
          await api.put(`api/posts/${postId}`, {
            [fieldName]: value,
          });
        }
        addToast({
          type: 'success',
          title: 'Saved',
        });
      } catch {
        addToast({
          type: 'error',
          title: 'Error on save',
        });
      }
    },
    [addToast, tagsRaw, postId],
  );

  const renderImageList = useCallback(data => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          height: '90px',
        }}
      >
        <img
          src={`${data && data.uri}`}
          alt=""
          style={{ maxWidth: '100%', maxHeight: 80 }}
        />
        <p style={{ fontWeight: 700, fontSize: '16px' }}>
          {(data && data.name) || 'No picture selected.'}
        </p>
      </div>
    );
  }, []);

  const renderMainImg = useCallback(data => {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '15px',
          height: '90px',
          marginLeft: '15px',
        }}
      >
        <img
          src={`${data && data.uri}`}
          alt=""
          style={{ maxWidth: '100%', maxHeight: 80 }}
        />
        <DxTextBox
          defaultValue={(data && data.name) || 'No picture selected.'}
          readOnly
        />
      </div>
    );
  }, []);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <form>
        <FormGroup fieldSetLabel="Main Image">
          <Controller
            name="idWeeklyFile"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectBox
                valueExpr="id"
                fieldRender={renderMainImg}
                itemRender={renderImageList}
                dataSource={images}
                onChanged={() => {
                  return null;
                }}
                onValueChange={e => onSubmit(field.name, e)}
                value={field.value}
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Title">
          <Controller
            name="title"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextBox
                onChanged={value => onSubmit(field.name, value)}
                value={field.value}
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Edition">
          <Controller
            name="idEdition"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectBox
                valueExpr="id"
                displayExpr="name"
                dataSource={editions}
                onChanged={() => {
                  return null;
                }}
                onValueChange={e => onSubmit(field.name, e)}
                value={field.value}
                showClearButton
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Category">
          <Controller
            name="idCategory"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <SelectBox
                valueExpr="id"
                displayExpr="name"
                dataSource={categories}
                onChanged={() => {
                  return null;
                }}
                onValueChange={e => onSubmit(field.name, e)}
                value={field.value}
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Tags">
          <Controller
            name="tags"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TagBox
                searchEnabled
                valueExpr="id"
                displayExpr="name"
                dataSource={postTags}
                onValueChange={e => onSubmit(field.name, e)}
                value={tags}
              />
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Abstract">
          <Controller
            name="tags"
            control={control}
            rules={{ required: true }}
            render={() => (
              <TextArea
                maxLength={250}
                onChanged={value => onSubmit('abstract', value)}
                value={abstract}
              />
            )}
          />
        </FormGroup>
      </form>
    </Container>
  );
};
