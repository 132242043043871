import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { InputAdornment, OutlinedInput, Tooltip } from '@mui/material';
import { CheckBox } from 'devextreme-react';
import {
  FiAlertCircle,
  FiCheckCircle,
  FiMinusCircle,
  FiSend,
} from 'react-icons/fi';
import { WhisperSpinner } from 'react-spinners-kit';
import { useAuth } from '../../hooks/auth';
import api, { IPost, IPostReviewerComment } from '../../services/api';

import {
  AuxBody,
  AuxHeader,
  CloseButton,
  Container,
  CheckItem,
  CheckList,
  CommentItem,
  CommentList,
  Header,
  Wrapper,
  CommentaryEntry,
  CommentaryPhoto,
  ContainerLoading,
  CommentaryEntryBtn,
} from './styles';

type Props = {
  handleClose?: () => void;
  postId: number;
};

export const ReviewerArea: React.FC<Props> = ({ handleClose, postId }) => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sendComment, setSendComment] = useState('');
  const [hasPhoto, setHasPhoto] = useState(0);
  const [hasText, setHasText] = useState(0);
  const [hasVideo, setHasVideo] = useState(0);
  const [isPartnerRevised, setIsPartnerRevised] = useState(false);
  const [isRevised, setIsRevised] = useState(false);
  const [isTag, setIsTag] = useState(0);
  const [comments, setComments] = useState<IPostReviewerComment[]>([]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IPost>(`/api/posts/${postId}`);

    setIsRevised(data.isRevised || false);
    setIsPartnerRevised(data.isPartnerRevised || false);
    setHasText(data.hasText);
    setHasPhoto(data.hasPhoto);
    setHasVideo(data.hasVideo);
    setIsTag(data.isTag);
    setLoading(false);
  }, [postId]);

  const fetchComments = useCallback(async () => {
    const { data } = await api.get(`/api/posts/${postId}/reviewer-comments`);

    setComments(data);
  }, [postId]);

  useEffect(() => {
    fetchData();
    fetchComments();
  }, [fetchData, fetchComments]);

  const getText = useCallback(status => {
    if (status === 1) return 'Approved';
    if (status === 2) return 'No Info';
    if (status === 3) return 'Pending';
    return 'No Info';
  }, []);

  const getIcon = useCallback(status => {
    if (status === 1) return <FiCheckCircle size={20} color="#5ab65e" />;
    if (status === 3) return <FiAlertCircle size={20} color="#edce61" />;
    return <FiMinusCircle size={20} />;
  }, []);

  const setCheckValue = useCallback(value => {
    if (value === 1) return 2;
    if (value === 3) return 1;

    return 3;
  }, []);

  const handleChanged = useCallback(
    async (type: string, value: number | boolean) => {
      await api.put(`/api/posts/${postId}`, {
        [type]: value,
      });
    },
    [postId],
  );

  const handleSendComment = useCallback(async () => {
    await api.post(`api/posts/${postId}/reviewer-comments`, {
      comment: sendComment,
    });
    setSendComment('');
    fetchComments();
  }, [postId, sendComment, fetchComments]);

  return (
    <Container>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={30} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}

      <AuxHeader>
        <span>Review tab</span>
        {handleClose && (
          <CloseButton onClick={() => handleClose && handleClose()} />
        )}
      </AuxHeader>
      <AuxBody>
        <Wrapper>
          <CheckList style={{ marginBottom: 5 }}>
            <Tooltip
              title={isPartnerRevised ? 'Approved' : 'Pending'}
              placement="left-start"
            >
              <CheckItem
                status={isPartnerRevised ? 1 : 3}
                onClick={() => {
                  const newValue = !isPartnerRevised;
                  setIsPartnerRevised(newValue);
                  handleChanged('isPartnerRevised', newValue);
                }}
              >
                {isPartnerRevised ? (
                  <FiCheckCircle size={20} color="#5ab65e" />
                ) : (
                  <FiAlertCircle size={20} color="#edce61" />
                )}
                <span>Partner</span>
              </CheckItem>
            </Tooltip>
            <Tooltip
              title={isRevised ? 'Approved' : 'Pending'}
              placement="left-start"
            >
              <CheckItem
                status={isRevised ? 1 : 3}
                onClick={() => {
                  const newValue = !isRevised;
                  setIsRevised(newValue);
                  handleChanged('isRevised', newValue);
                }}
              >
                {isRevised ? (
                  <FiCheckCircle size={20} color="#5ab65e" />
                ) : (
                  <FiAlertCircle size={20} color="#edce61" />
                )}
                <span>Reviewer</span>
              </CheckItem>
            </Tooltip>
          </CheckList>
          <CheckList>
            <Tooltip title={getText(hasText)} placement="left-start">
              <CheckItem
                onClick={() => {
                  const newValue = setCheckValue(hasText);
                  setHasText(newValue);
                  handleChanged('hasText', newValue);
                }}
                status={hasText}
              >
                {getIcon(hasText)}
                <span>Text</span>
              </CheckItem>
            </Tooltip>
            <Tooltip title={getText(hasPhoto)} placement="left-start">
              <CheckItem
                onClick={() => {
                  const newValue = setCheckValue(hasPhoto);
                  setHasPhoto(newValue);
                  handleChanged('hasPhoto', newValue);
                }}
                status={hasPhoto}
              >
                {getIcon(hasPhoto)}
                <span>Photo</span>
              </CheckItem>
            </Tooltip>
            <Tooltip title={getText(hasVideo)} placement="left-start">
              <CheckItem
                onClick={() => {
                  const newValue = setCheckValue(hasVideo);
                  setHasVideo(newValue);
                  handleChanged('hasVideo', newValue);
                }}
                status={hasVideo}
              >
                {getIcon(hasVideo)}
                <span>Video</span>
              </CheckItem>
            </Tooltip>
            <Tooltip title={getText(isTag)} placement="left-start">
              <CheckItem
                onClick={() => {
                  const newValue = setCheckValue(isTag);
                  setIsTag(newValue);
                  handleChanged('isTag', newValue);
                }}
                status={isTag}
              >
                {getIcon(isTag)}
                <span>Tag</span>
              </CheckItem>
            </Tooltip>
          </CheckList>

          <Header>
            <p>All reviewers comments</p>
          </Header>

          <CommentaryEntry>
            <CommentaryPhoto
              src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${user?.login}_192_192.png`}
            />
            <OutlinedInput
              className="textarea"
              placeholder="Type your review..."
              onChange={e => setSendComment(e.target.value)}
              value={sendComment}
              multiline
              maxRows={6}
              onKeyPress={e => {
                if (e.code === 'Enter' && e.shiftKey === false) {
                  handleSendComment();
                }
              }}
              endAdornment={
                <InputAdornment position="end">
                  <Tooltip title="Send">
                    <CommentaryEntryBtn onClick={handleSendComment}>
                      <FiSend size={18} />
                    </CommentaryEntryBtn>
                  </Tooltip>
                </InputAdornment>
              }
            />
          </CommentaryEntry>

          {comments.length < 1 ? (
            <h1>There is no comments</h1>
          ) : (
            <CommentList>
              {comments.map(comment => (
                <CommentItem key={comment.id}>
                  <span className="name">
                    {comment.reviewer?.name} -{' '}
                    {new Date(comment.createdAt).toLocaleString()}
                  </span>
                  {comment.comment}
                </CommentItem>
              ))}
            </CommentList>
          )}
        </Wrapper>
      </AuxBody>
    </Container>
  );
};
