import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { DateBox, TextBox, CheckBox } from 'devextreme-react';

import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogNewEdition: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [slogan, setSlogan] = useState('');
  const [datePublished, setDatePublished] = useState('');
  const [extraEdition, setExtraEdition] = useState(false);

  const handleSend = useCallback(async () => {
    setLoading(true);
    if (slogan !== '') {
      await api.post('/api/editions', {
        slogan,
        datePublished,
        extraEdition,
      });
      handleClose();
    } else
      addToast({
        title: 'Please fill all the required (*) fields.',
        type: 'error',
        description: '',
      });
    setLoading(false);
  }, [addToast, datePublished, handleClose, slogan, extraEdition]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
      onSubmit={() => {
        handleClose();
      }}
    >
      <DialogTitle id="form-dialog-title">New Edition</DialogTitle>
      <DialogContent>
        <FormGroup fieldSetLabel="Slogan*">
          <TextBox onValueChange={e => setSlogan(e)} />
        </FormGroup>
        <FormGroup fieldSetLabel="Publish Date*">
          <DateBox openOnFieldClick onValueChange={e => setDatePublished(e)} />
        </FormGroup>
        <FormGroup fieldSetLabel="Is it a extra edition?">
          <CheckBox onValueChanged={e => setExtraEdition(e.value)} />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSend} primary disabled={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
