import React, { useState, useCallback } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';

import { useForm } from 'react-hook-form';

import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import api from '../../../../services/api';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  categoryId: number;
}

export const DialogDeleteCategory: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  categoryId,
}) => {
  const [loading, setLoading] = useState(false);

  const { handleSubmit } = useForm();

  const handleDelete = useCallback(async () => {
    setLoading(true);
    await api.delete(`/api/categories/${categoryId}`);
    setLoading(false);
    handleClose();
  }, [categoryId, handleClose]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>Delete Category</DialogTitle>

      <form onSubmit={handleSubmit(handleDelete)}>
        <p style={{ fontWeight: 600, textAlign: 'center' }}>
          Are you sure you want to delete this category?
        </p>
        <DialogActions style={{ placeContent: 'center', marginTop: '10px' }}>
          <Button onClick={handleClose}>Close</Button>
          <Button type="submit" primary disabled={loading}>
            Delete
            {loading && (
              <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
