import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 15px;
  background-color: #e6e7e9;
  border-radius: 18px;
  margin-bottom: 32px;
  position: relative;

  .react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
  }

  &:hover {
    .page-controls {
      opacity: 1;
    }
  }

  .page-controls {
    position: absolute;
    border-radius: 16px;
    bottom: 20px;
    left: 50%;
    background: #e6e7e9;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;

    span {
      font: inherit;
      font-size: 14px;
      font-weight: 700;
      padding: 0 0.5em;
    }

    button {
      width: 44px;
      height: 44px;
      background: #eaeaea;
      color: #999;
      border: 0;
      font: inherit;
      font-size: 18px;
      font-family: 'Roboto Condensed', sans-serif;

      &:enabled {
        &:hover {
          cursor: pointer;
        }

        &:hover,
        &:focus {
          background-color: #e6e6e6;
        }
      }

      &:first-child {
        border-top-left-radius: 16px;
        border-bottom-left-radius: 16px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-child {
        border-top-right-radius: 16px;
        border-bottom-right-radius: 16px;

        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .page-controls.zoom {
    bottom: 70px;

    button {
      font-size: 14px;
      color: #333;
      font-weight: 700;
    }
  }
`;
