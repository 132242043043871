import React, { useCallback, useEffect, useRef, useState } from 'react';
import { BiGridAlt, BiMessageDetail, BiNews } from 'react-icons/bi';
import { FiMenu } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import WeeklyIcon from '../../assets/images/weekly-logo.svg';
import weeklyGroups from '../../config/weeklyGroups';
import { useAuth } from '../../hooks/auth';
import { useScreenMobile } from '../../hooks/mobileScreen';

import {
  Header,
  LogoBox,
  MyWeeklyHeader,
  UserMenuButtons,
  WeeklyLogo,
  OpenMenuButton,
  Overlay,
} from './styles';

type Props = {
  overlay: boolean;
  openAdmin: () => void;
  openSearch: () => void;
  openSendContent: () => void;
  openFavorites: () => void;
  openFeedback: () => void;
  openEditions: () => void;
  openSectionDialog?: () => void;
  selectedSection?: boolean;
};

export const MainHeader: React.FC<Props> = ({
  overlay,
  openAdmin,
  openFavorites,
  openSearch,
  openSendContent,
  openFeedback,
  openEditions,
  openSectionDialog,
  selectedSection,
}) => {
  const navigation = useHistory();
  const { isMobile } = useScreenMobile();
  const [openMenu, setOpenMenu] = useState(false);
  const [animateBtnMenu, setAnimateBtnMenu] = useState(false);
  const { hasScopes } = useAuth();
  const menuList = useRef<HTMLUListElement>(null);
  const menuMobileBtn = useRef<HTMLDivElement>(null);
  const menuOverlay = useRef<HTMLDivElement>(null);

  const handleMobileMenu = useCallback(() => {
    if (!openMenu) {
      setOpenMenu(true);
      setAnimateBtnMenu(true);
      setTimeout(() => {
        if (menuList.current && menuOverlay.current) {
          if (menuList.current)
            menuList.current.style.transform = 'translateX(0)';
          if (menuOverlay.current) menuOverlay.current.style.opacity = '1';
        }
      }, 1);
    }
    if (openMenu) {
      setAnimateBtnMenu(false);
      if (menuList.current) menuList.current.style.transform = '';
      if (menuOverlay.current) menuOverlay.current.style.opacity = '';
      setTimeout(() => {
        setOpenMenu(false);
      }, 250);
    }
  }, [openMenu]);

  useEffect(() => {
    const checkIfClickedOutside = (e: MouseEvent) => {
      if (
        openMenu &&
        menuList.current &&
        !menuList.current.contains(e.target as Node) &&
        menuMobileBtn.current &&
        !menuMobileBtn.current.contains(e.target as Node)
      ) {
        handleMobileMenu();
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [handleMobileMenu, openMenu]);

  return (
    <Header overlay={!overlay}>
      <LogoBox onClick={() => navigation.push('/')}>
        <WeeklyLogo src={WeeklyIcon} alt="Weekly Logo" />
      </LogoBox>
      {isMobile && (
        <>
          {openMenu && <Overlay ref={menuOverlay} />}
          <OpenMenuButton
            overlay={!overlay}
            onClick={() => handleMobileMenu()}
            ref={menuMobileBtn}
          >
            <FiMenu
              size={25}
              style={{
                transition: 'all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1)',
                transform: animateBtnMenu ? 'rotate(360deg)' : 'rotate(0)',
                color: animateBtnMenu ? '#8b0304' : '#CCC',
              }}
            />
          </OpenMenuButton>
        </>
      )}
      {((isMobile && openMenu) || !isMobile) && (
        <MyWeeklyHeader ref={menuList}>
          <UserMenuButtons
            overlay={!overlay}
            onClick={
              isMobile
                ? () => {
                    openSearch();
                    handleMobileMenu();
                  }
                : openSearch
            }
          >
            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.385 1.61266C10.2761 2.50486 10.8339 3.676 10.9649 4.93017C11.096 6.18435 10.7924 7.4455 10.105 8.50266C10.225 8.60266 10.325 8.71266 10.465 8.81266C10.665 8.97266 10.935 9.17266 11.275 9.40266C11.615 9.64266 11.835 9.79265 11.935 9.87265C12.355 10.1827 12.665 10.4427 12.875 10.6527C13.195 10.9727 13.475 11.3027 13.715 11.6527C13.965 12.0027 14.155 12.3427 14.305 12.6927C14.445 13.0427 14.515 13.3727 14.485 13.6927C14.465 14.0127 14.345 14.2827 14.125 14.5027C13.905 14.7227 13.635 14.8427 13.315 14.8627C13.005 14.8827 12.665 14.8227 12.325 14.6727C11.975 14.5327 11.625 14.3327 11.285 14.0827C10.935 13.8427 10.605 13.5627 10.285 13.2427C10.075 13.0327 9.815 12.7227 9.515 12.3127C9.415 12.1827 9.265 11.9627 9.045 11.6527C8.825 11.3327 8.645 11.0827 8.485 10.8727C8.325 10.6727 8.195 10.5227 8.045 10.3727C7.00705 10.9161 5.82272 11.1142 4.66442 10.9379C3.50612 10.7616 2.4343 10.2203 1.605 9.39266C-0.535 7.24266 -0.535 3.75266 1.605 1.61266C2.11562 1.10142 2.72201 0.695853 3.38949 0.419141C4.05696 0.142428 4.77244 0 5.495 0C6.21756 0 6.93304 0.142428 7.60051 0.419141C8.26799 0.695853 8.87438 1.10142 9.385 1.61266ZM7.975 7.97266C8.62829 7.31459 8.9949 6.42493 8.9949 5.49765C8.9949 4.57038 8.62829 3.68072 7.975 3.02266C7.65032 2.69702 7.26458 2.43865 6.83987 2.26236C6.41516 2.08607 5.95984 1.99533 5.5 1.99533C5.04016 1.99533 4.58484 2.08607 4.16013 2.26236C3.73542 2.43865 3.34967 2.69702 3.025 3.02266C2.69936 3.34733 2.44099 3.73307 2.2647 4.15778C2.08842 4.58249 1.99767 5.03781 1.99767 5.49765C1.99767 5.9575 2.08842 6.41282 2.2647 6.83753C2.44099 7.26224 2.69936 7.64798 3.025 7.97266C3.34967 8.29829 3.73542 8.55666 4.16013 8.73295C4.58484 8.90924 5.04016 8.99998 5.5 8.99998C5.95984 8.99998 6.41516 8.90924 6.83987 8.73295C7.26458 8.55666 7.65032 8.29829 7.975 7.97266Z" />
            </svg>
            Search
          </UserMenuButtons>
          {openSectionDialog && (
            <UserMenuButtons
              overlay={!overlay}
              selected={selectedSection}
              onClick={
                isMobile
                  ? () => {
                      openSectionDialog();
                      handleMobileMenu();
                    }
                  : openSectionDialog
              }
            >
              <BiGridAlt />
              Sections
            </UserMenuButtons>
          )}
          <UserMenuButtons
            overlay={!overlay}
            onClick={
              isMobile
                ? () => {
                    openEditions();
                    handleMobileMenu();
                  }
                : openEditions
            }
          >
            <BiNews size={20} />
            Editions
          </UserMenuButtons>

          <UserMenuButtons
            overlay={!overlay}
            onClick={
              isMobile
                ? () => {
                    openSendContent();
                    handleMobileMenu();
                  }
                : openSendContent
            }
          >
            <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.90669 5.11755L6.87595 9.3602C6.84112 9.40899 6.81975 9.46609 6.81398 9.52576C6.80822 9.58543 6.81827 9.64556 6.84312 9.70011L8.94875 14.3136C9.13802 14.7423 9.75556 14.7138 9.90331 14.2687L14.2488 1.2322C14.2788 1.14202 14.283 1.04529 14.2612 0.952818C14.2393 0.860347 14.1921 0.775785 14.1249 0.708593C14.0577 0.641401 13.9732 0.594228 13.8807 0.572352C13.7882 0.550476 13.6915 0.554759 13.6013 0.584723L0.56485 4.93021C0.120161 5.07844 0.0916742 5.69599 0.519947 5.88477L5.13389 7.98992C5.18845 8.01477 5.24858 8.02482 5.30825 8.01906C5.36792 8.01329 5.42502 7.99192 5.47381 7.95709L9.71645 4.92635C9.74276 4.90729 9.775 4.89822 9.80739 4.90079C9.83978 4.90336 9.87019 4.91739 9.89316 4.94036C9.91613 4.96334 9.93016 4.99375 9.93273 5.02613C9.9353 5.05852 9.92624 5.09076 9.90717 5.11707L9.90669 5.11755Z" />
            </svg>
            Send content
          </UserMenuButtons>

          <UserMenuButtons
            overlay={!overlay}
            onClick={
              isMobile
                ? () => {
                    openFavorites();
                    handleMobileMenu();
                  }
                : openFavorites
            }
          >
            <svg viewBox="0 0 16 15" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.517137 5.28193L5.31363 4.59758L7.45778 0.330127C7.51635 0.213287 7.61269 0.118702 7.73171 0.0612089C8.03019 -0.0834504 8.3929 0.037099 8.54214 0.330127L10.6863 4.59758L15.4828 5.28193C15.615 5.30047 15.7359 5.36167 15.8285 5.45441C15.9404 5.56733 16.0021 5.71924 15.9999 5.87678C15.9978 6.03431 15.9321 6.18457 15.8172 6.29454L12.3468 9.61614L13.1667 14.3064C13.1859 14.4156 13.1736 14.5278 13.1312 14.6304C13.0888 14.733 13.0179 14.8218 12.9267 14.8869C12.8354 14.952 12.7274 14.9906 12.6149 14.9985C12.5024 15.0064 12.3899 14.9831 12.2902 14.9314L7.99996 12.717L3.70976 14.9314C3.59263 14.9926 3.45661 15.013 3.32627 14.9908C2.99756 14.9352 2.77653 14.6291 2.8332 14.3064L3.65308 9.61614L0.182761 6.29454C0.0883055 6.20367 0.0259647 6.08497 0.0070734 5.95515C-0.0439339 5.63059 0.18654 5.33015 0.517137 5.28193Z" />
            </svg>
            Favorites
          </UserMenuButtons>
          <UserMenuButtons overlay={!overlay} onClick={openFeedback}>
            <BiMessageDetail />
            Contact Us
          </UserMenuButtons>
          {hasScopes([weeklyGroups.Admin]) && (
            <UserMenuButtons
              overlay={!overlay}
              onClick={
                isMobile
                  ? () => {
                      openAdmin();
                      handleMobileMenu();
                    }
                  : openAdmin
              }
            >
              <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.9541 5.20625C16.0635 5.475 15.9707 5.77812 15.7452 5.975L14.3096 7.20625C14.3461 7.46563 14.366 7.73125 14.366 8C14.366 8.26875 14.3461 8.53438 14.3096 8.79375L15.7452 10.025C15.9707 10.2219 16.0635 10.525 15.9541 10.7937C15.8082 11.1656 15.6325 11.525 15.4336 11.8656L15.2778 12.1187C15.0589 12.4625 14.8136 12.7875 14.545 13.0969C14.3461 13.3188 14.0245 13.3969 13.7327 13.3094L11.886 12.7531C11.4417 13.075 10.9212 13.3438 10.4272 13.55L10.0127 15.3344C9.94644 15.6156 9.71435 15.8156 9.40933 15.8906C8.95179 15.9625 8.48099 16 7.9704 16C7.51949 16 7.04869 15.9625 6.59115 15.8906C6.28612 15.8156 6.05404 15.6156 5.98773 15.3344L5.57329 13.55C5.04944 13.3438 4.55875 13.075 4.11447 12.7531L2.26906 13.3094C1.97597 13.3969 1.65271 13.3188 1.45644 13.0969C1.18755 12.7875 0.942201 12.4625 0.723379 12.1187L0.568213 11.8656C0.366962 11.525 0.191904 11.1656 0.0450271 10.7937C-0.0623951 10.525 0.0281181 10.2219 0.255561 10.025L1.68985 8.79375C1.65338 8.53438 1.63448 8.26875 1.63448 8C1.63448 7.73125 1.65338 7.46563 1.68985 7.20625L0.255561 5.975C0.0281181 5.77812 -0.0623951 5.47813 0.0450271 5.20625C0.191904 4.83438 0.367294 4.475 0.568213 4.13438L0.723047 3.88125C0.942201 3.5375 1.18755 3.2125 1.45644 2.90469C1.65271 2.68125 1.97597 2.60375 2.26906 2.69187L4.11447 3.24688C4.55875 2.92375 5.04944 2.655 5.57329 2.45094L5.98773 0.666562C6.05404 0.382812 6.28612 0.1575 6.59115 0.109687C7.04869 0.0375312 7.51949 0 8.00024 0C8.48099 0 8.95179 0.0375312 9.40933 0.109687C9.71435 0.1575 9.94644 0.382812 10.0127 0.666562L10.4272 2.45094C10.9212 2.655 11.4417 2.92375 11.886 3.24688L13.7327 2.69187C14.0245 2.60375 14.3461 2.68125 14.545 2.90469C14.8136 3.2125 15.0589 3.5375 15.2778 3.88125L15.4336 4.13438C15.6325 4.475 15.8082 4.83438 15.9541 5.20625ZM8.00024 10.5C9.46569 10.5 10.6526 9.38125 10.6526 7.97188C10.6526 6.61875 9.46569 5.47188 8.00024 5.47188C6.53479 5.47188 5.34784 6.61875 5.34784 7.97188C5.34784 9.38125 6.53479 10.5 8.00024 10.5Z" />
              </svg>
              Admin Area
            </UserMenuButtons>
          )}
        </MyWeeklyHeader>
      )}
    </Header>
  );
};
