import React, { useState, useCallback, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { SelectBox } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';

import DataSource from 'devextreme/data/data_source';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import { Input } from '../../../../components/Input';
import { useToast } from '../../../../hooks/toast';
import api, { ICategory, IEdition } from '../../../../services/api';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
}

export const DialogNewPost: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [edition, setEdition] = useState(0);
  const [category, setCategory] = useState(0);
  const [editions, setEditions] = useState<DataSource>();
  const [categories, setCategories] = useState<DataSource>();

  const handleSend = useCallback(async () => {
    setLoading(true);
    if (category !== 0 && edition !== 0 && title !== '') {
      try {
        await api.post('/api/posts', {
          idEdition: edition,
          idCategory: category,
          title,
        });
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
      handleClose();
    } else {
      addToast({
        title: 'Please fill all the required (*) fields.',
        type: 'error',
        description: '',
      });
    }
    setLoading(false);
  }, [addToast, category, edition, handleClose, title]);

  const loadSelectBoxes = useCallback(() => {
    const editionsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IEdition[]>('/api/editions');
        return data
          .filter(x => x.idStatus !== 1)
          .map(item => ({
            ...item,
            displayName: `${item.name} - ${item.slogan}`,
          }));
      },
    });

    setEditions(
      new DataSource({
        store: editionsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    const categoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<ICategory[]>('/api/categories');
        return data.filter(x => {
          return !!x.idParentCategory && x.idStatus === 1;
        });
      },
    });

    setCategories(
      new DataSource({
        store: categoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadSelectBoxes();
  }, [loadSelectBoxes]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">New Post</DialogTitle>
      <DialogContent>
        <FormGroup fieldSetLabel="Title">
          <Input onChange={e => setTitle(e.target.value)} />
        </FormGroup>
        <FormGroup fieldSetLabel="Edition*">
          <SelectBox
            dataSource={editions}
            valueExpr="id"
            displayExpr="displayName"
            onValueChange={e => setEdition(e)}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Category*">
          <SelectBox
            valueExpr="id"
            displayExpr="name"
            dataSource={categories}
            onValueChange={e => setCategory(e)}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleSend} primary disabled={loading}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};
