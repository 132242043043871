import React, { useMemo } from 'react';
// import Slide from '@mui/material/Slide';
// import { TransitionProps } from '@mui/material/transitions';

import { Post as PostArticle } from '../../../components/Post';
import { EditPost } from '../../../components/EditPost';
import { Container, PopUp, CloseButton } from './styles';
import { ITotalReaction } from '../../../services/api';

interface ArticleCustomProps {
  open: boolean;
  handleClose(): void;
  postId: number;
  preview: boolean;
  navigation?: 'center' | 'top' | 'none';
  onFavoriteChanged: (postId: number, value: boolean) => void;
  onRead: (postId: number, value: boolean) => void;
  onReactionChanged: (postId: number, value: ITotalReaction[]) => void;
  onCommentChanged: (postId: number, value: number) => void;
  onChangedCategoryId?: (categoryId: number) => void;
}

export const Post: React.FC<ArticleCustomProps> = ({
  open,
  handleClose,
  postId,
  preview,
  navigation = 'none',
  onFavoriteChanged,
  onRead,
  onCommentChanged,
  onReactionChanged,
  onChangedCategoryId,
}) => {
  return open ? (
    <Container>
      <CloseButton onClick={handleClose} size={22} />
      <PopUp>
        {preview ? (
          <EditPost
            postId={postId}
            handleClose={handleClose}
            tabView={3}
            viewType="preview"
          />
        ) : (
          <PostArticle
            postId={postId}
            onFavoriteChanged={onFavoriteChanged}
            navigation={navigation}
            handleClose={handleClose}
            onReactionChanged={onReactionChanged}
            onCommentChanged={onCommentChanged}
            onRead={onRead}
            onChangedCategoryId={onChangedCategoryId}
          />
        )}
      </PopUp>
    </Container>
  ) : null;
};
