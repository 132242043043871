import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  p {
    font-size: 14px;
    color: #6b778c;
    margin-bottom: 10px;
  }
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

export const ContentItem = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;

  &:hover {
    background-color: #eaeaea;
  }

  /* &:active {
    background-color: rgba(139, 3, 3, 0.1);
    border: 2px solid #8b0304;
  } */

  .icon {
    margin: 0px 4px 0px 0px !important;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    display: flex;
    background-color: #8b0304;
    align-items: center;
    justify-content: center;

    & > svg {
      color: #fff;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    flex: 1;

    span {
      font-size: 12px;
      color: #6b778c;
    }
  }

  .edit {
    align-items: center;
  }
`;
