import React, { useState, useCallback, useMemo, useEffect } from 'react';

// import ReactQuill, { ReactQuillProps } from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

import { Control, Controller } from 'react-hook-form';
// import { HTMLEditorToolBar, modules } from '../HTMLEditorToolBar';

import HtmlEditor, {
  Toolbar,
  MediaResizing,
  Item,
  IHtmlEditorOptions,
  TableContextMenu,
  TableResizing,
} from 'devextreme-react/html-editor';

import { Container } from './styles';
import api from '../../services/master';

// type Props = ReactQuillProps & {
//   name: string;
//   control: Control;
//   onChanged: (value: string) => void;
// };

type Props = IHtmlEditorOptions & {
  name: string;
  control: Control;
  handleChange: (
    newValue: string,
    onChange: (text: string) => void,
    oldValue?: string,
  ) => void;
};

type MentionProps = {
  dataSource: any[];
  searchExpr: string;
  displayExpr: string;
  valueExpr: string;
};

export const HTMLEditorForm: React.FC<Props> = ({
  name,
  control,
  handleChange,
  ...rest
}) => {
  const [mentions, setMentions] = useState<MentionProps[]>([]);

  const fetchMention = useCallback(async () => {
    const { data } = await api.get('/master/professionals');

    setMentions([
      {
        dataSource: data,
        searchExpr: 'Name',
        displayExpr: 'Name',
        valueExpr: 'ID',
      },
    ]);
  }, []);

  useEffect(() => {
    fetchMention();
  }, [fetchMention]);

  const headerValues = useMemo(() => [false, 1, 2, 3], []);

  return (
    <Container>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, ref } }) => (
          <HtmlEditor
            ref={ref}
            height="100%"
            onValueChange={textValue => {
              handleChange(textValue, onChange);
            }}
            mentions={mentions}
            {...rest}
          >
            <Toolbar multiline={false}>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="header" acceptedValues={headerValues} />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="link" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="codeBlock" />
              <Item name="blockquote" />
              <Item name="separator" />
              <Item name="insertTable" />
              <Item name="deleteTable" />
              <Item name="insertRowAbove" />
              <Item name="insertRowBelow" />
              <Item name="deleteRow" />
              <Item name="insertColumnLeft" />
              <Item name="insertColumnRight" />
              <Item name="deleteColumn" />
              <Item name="cellProperties" />
              <Item name="tableProperties" />
            </Toolbar>
          </HtmlEditor>
        )}
      />
    </Container>
  );
};
