import React, { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';

import 'react-quill/dist/quill.snow.css';

import { WhisperSpinner } from 'react-spinners-kit';
import { FiCode, FiEdit, FiEye } from 'react-icons/fi';
import { Prompt } from 'react-router-dom';

import {
  Container,
  EditorArea,
  AuxArea,
  AuxHeader,
  AuxBody,
  CloseButton,
  LoadingContainer,
  Saving,
} from './styles';
import { Button } from '../Button';
import { HTMLEditorForm } from '../HTMLEditorForm';
import { Post } from '../Post';
import { Tabs, TabType } from './Tabs';
import api, { IPost } from '../../services/api';
import { useToast } from '../../hooks/toast';
import { TextAreaForm } from '../TextAreaForm';

type EditPostProps = TabType & {
  handleClose(): void;
  postId: number;
  viewType?: 'html' | 'editor' | 'preview';
};

export const EditPost: React.FC<EditPostProps> = ({
  handleClose,
  postId: _postId,
  viewType,
  tabView,
}) => {
  const [postId, setPostId] = useState(_postId);
  const [isOldRender, setIsOldRender] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);
  const [savedTime, setSavedTime] = useState<Date | null>(null);
  const [view, setView] = useState<'html' | 'editor' | 'preview'>(
    viewType || 'editor',
  );
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();

  const { control, setValue } = useForm();
  const { addToast } = useToast();

  const textForm = useWatch({
    control,
    name: 'text',
  });

  const loadPost = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get<IPost>(`api/posts/${postId}`);

    setIsOldRender(data.isOldRender);
    setValue('text', data.text);
    setLoading(false);
  }, [postId, setValue]);

  const unloadCallback = useCallback(
    (event: { preventDefault: () => void; returnValue: string }) => {
      event.preventDefault();
      event.returnValue =
        'A save operation is in progress. Are you sure you want to leave?';
    },
    [],
  );

  const handleSaveText = useCallback(
    async (text: string) => {
      if (!isOldRender) {
        try {
          setIsSaving(true);
          setShouldBlockNavigation(true);

          window.addEventListener('beforeunload', unloadCallback);

          await api.put(`api/posts/${postId}`, {
            text,
          });

          setSavedTime(new Date());

          window.removeEventListener('beforeunload', unloadCallback);
        } catch (error) {
          let errorMessage = 'Save failed';
          if (error instanceof Error) {
            errorMessage = error.message;
          }
        } finally {
          setIsSaving(false);
          setShouldBlockNavigation(false);
        }
      } else {
        addToast({
          type: 'error',
          title: 'You cannot edit legacy posts',
        });
      }
    },
    [isOldRender, unloadCallback, postId, addToast],
  );

  const handleChange = useCallback(
    (newValue: string, onChange, oldValue?: string) => {
      onChange(newValue);
      if (oldValue) if (!oldValue || newValue === oldValue) return;

      if (typingTimeout) clearTimeout(typingTimeout);

      window.addEventListener('beforeunload', unloadCallback);
      setShouldBlockNavigation(true);

      setTypingTimeout(
        setTimeout(() => {
          handleSaveText(newValue || '');
          window.removeEventListener('beforeunload', unloadCallback);
          setShouldBlockNavigation(false);
        }, 2000),
      );
    },
    [typingTimeout, unloadCallback, handleSaveText],
  );

  useEffect(() => {
    loadPost();
  }, [loadPost]);

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
        </LoadingContainer>
      )}
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />

      <EditorArea>
        {view === 'editor' && (
          <HTMLEditorForm
            control={control}
            name="text"
            value={textForm}
            handleChange={handleChange}
            style={{ height: 'calc(100% - 100px)', width: '100%' }}
            readOnly={isOldRender}
          />
        )}

        {view === 'html' && (
          <TextAreaForm
            control={control}
            name="text"
            handleChange={handleChange}
            readOnly={isOldRender}
          />
        )}

        {view === 'preview' && (
          <Post
            postId={postId}
            navigation="top"
            style={{
              paddingBottom: 40,
              justifyContent: 'center',
              width: '100%',
            }}
            onPostChanged={value => setPostId(value)}
            handleClose={handleClose}
          />
        )}
      </EditorArea>

      <AuxArea>
        <AuxHeader>
          <Button primary={view === 'editor'} onClick={() => setView('editor')}>
            Editor
          </Button>

          <Button primary={view === 'html'} onClick={() => setView('html')}>
            Code
          </Button>
          <Button
            primary={view === 'preview'}
            onClick={() => setView('preview')}
          >
            Preview
          </Button>
          <Saving>
            {isSaving ? 'Saving...' : 'Saved'}
            <span style={{ fontSize: '0.75em' }}>
              {savedTime
                ? savedTime.toLocaleTimeString([], { timeStyle: 'medium' })
                : ''}
            </span>
          </Saving>

          <CloseButton
            onClick={() =>
              isSaving || shouldBlockNavigation ? '' : handleClose()
            }
          />
        </AuxHeader>
        <AuxBody>
          <Tabs postId={postId} tabView={tabView} isOldRender={isOldRender} />
        </AuxBody>
      </AuxArea>
    </Container>
  );
};
