import React from 'react';
import { useTransition } from 'react-spring';

import { Container } from './styles';
import { Toast } from './Toast';

import { MessageProps } from '../../hooks/toast';

interface ToastContainerProps {
  messages: MessageProps[];
}

export const ToastContainer: React.FC<ToastContainerProps> = ({ messages }) => {
  // const messagesWithTransitions = useTransition(messages, {
  //   from: { opacity: 0 },
  //   enter: { opacity: 1 },
  //   leave: { opacity: 0 },
  //   delay: 200,
  // });

  return (
    <Container>
      {messages.map(message => (
        <Toast key={message.id} message={message} />
      ))}
      {/* {messagesWithTransitions((style, item, t, i) => (
        <Toast key={i} message={item} style={style} />
      ))} */}
    </Container>
  );
};
