import React, { useCallback, useMemo, useState } from 'react';

import FileManagerExtreme, {
  Permissions,
  ItemView,
} from 'devextreme-react/file-manager';
import CustomFileSystemProvider from 'devextreme/file_management/custom_provider';

import { BoardBody } from '../../../components/BoardBody';
import { BoardHeader } from '../../../components/BoardHeader';
import { Container } from './styles';

import fileIcon from '../../../assets/icons/file.png';
import filePdf from '../../../assets/icons/pdf.png';

import api, { IWeeklyFile } from '../../../services/api';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

export const GlobalStorage: React.FC = () => {
  const [viewMode, setViewMode] = useState('thumbnails');

  const onOptionChanged = useCallback(e => {
    if (e.fullName === 'itemView.mode') {
      setViewMode(e.value);
    }
  }, []);

  const getIcon = useCallback((thumb: string, contentType: string) => {
    if (thumb && contentType.includes('image')) return `${thumb}`;

    if (contentType.includes('pdf')) return filePdf;

    return fileIcon;
  }, []);

  // Creates a custom file system provider
  const customFileProvider = useMemo(
    () =>
      new CustomFileSystemProvider({
        keyExpr: 'id',
        dateModifiedExpr: 'updatedAt',
        // Function to get file system items
        getItems: async fileItem => {
          const params: { idParent: string } = {} as { idParent: string };

          if (fileItem.key) params.idParent = `${fileItem.key || ''}`;

          const { data } = await api.get<IWeeklyFile[]>(`/api/global-files`, {
            params,
          });

          return data.map(file => ({
            id: file.id,
            name: file.name,
            isDirectory: file.isDirectory,
            size: file.fileSize,
            thumbnail:
              !file.isDirectory && getIcon(file.uri || '', file.contentType),
          }));
        },
        // Functions to handle file operations
        createDirectory: async (parentDirectory, name) => {
          await api.post(`/api/global-files/directory`, {
            directoryName: name,
            idParent: parentDirectory.key || '',
          });
        },
        deleteItem: async item => {
          await api.delete(`/api/global-files/${item.key}`);
        },
        moveItem: async (item, directory) => {
          await api.patch(`/api/global-files/${item.key}/parent`, {
            idParent: directory.key || '',
          });
        },
        downloadItems: async items => {
          const ids = items.map(x => x.key);
          const { data, headers } = await api.get(
            `/api/global-files/download?files=[${ids.join(',')}]`,
            {
              responseType: 'blob',
            },
          );

          const downloadUrl = window.URL.createObjectURL(new Blob([data]));

          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', headers['x-file-name']);
          document.body.appendChild(link);
          link.click();
          link.remove();
        },
        uploadFileChunk: async (file, uploadInfo, directory) => {
          const formData = new FormData();
          formData.append('file', file);
          formData.append('idParent', directory.key || '');
          await api.post(`/api/global-files/upload`, formData, {
            headers: {
              'content-type': 'multipart/form-data',
              'X-CHUNK-TOTAL': `${uploadInfo.chunkCount}`,
              'X-CHUNK-INDEX': `${uploadInfo.chunkIndex + 1}`,
            },
          });
        },
      }),
    [getIcon],
  );
  return (
    <ApplicationLayers>
      <Container>
        <BoardHeader title="GlobalStorage" subtitle="GlobalStorage" />

        <BoardBody style={{ height: '100%' }}>
          <FileManagerExtreme
            onOptionChanged={onOptionChanged}
            fileSystemProvider={customFileProvider}
            height="100%"
            width="100%"
          >
            <ItemView mode={viewMode} />
            <Permissions create delete rename upload download />
          </FileManagerExtreme>
        </BoardBody>
      </Container>
    </ApplicationLayers>
  );
};
