import styled from 'styled-components';

export const PostWrapper = styled.div`
  box-shadow: 0 3px 30px rgb(0 0 0 / 10%);
  border-radius: 20px;
  height: 100%;

  @media (min-width: 1024px) {
    display: flex;
    height: 300px;
    width: 1000px;
  }
`;

export const Post = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 20px;
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 30px 0px;

  &:hover {
    transform: scale(1.02);
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

export const PostImageWrapper = styled.div`
  overflow: hidden;
  height: 170px;
  display: flex;
  align-items: center;

  @media (min-width: 1024px) {
    border-radius: 20px;
    height: 100%;
    width: 100%;
  }

  & img {
    filter: brightness(0.9);
    transition: all 0.75s cubic-bezier(0.075, 0.82, 0.165, 1);
    max-width: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
    height: 100%;

    @media (min-width: 1024px) {
      border-radius: 20px;
    }
  }
  ${Post}:hover & img {
    filter: brightness(0.98);
    transform: scale(1.1);
  }
`;

export const PostCardTextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4em 2em 2em 2em;
  box-sizing: border-box;

  @media (min-width: 1024px) {
    padding: 25px 25px 25px 15px;
    flex: 1;
  }
`;

export const HeaderSubtitle = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #cccccc;
  margin-bottom: 0.25em;

  @media (min-width: 1024px) {
    font-size: 1.15rem;
  }
`;

export const HeaderTitle = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.25;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  color: #8b0304;
  margin-bottom: 1.15em;
  position: relative;

  @media (min-width: 1024px) {
    font-size: 1.5rem;

    &:after {
      content: '';
      position: absolute;
      background-color: #ddd;
      height: 4px;
      width: 30px;
      bottom: -15px;
      left: 0;
    }
  }

  ${Post}:hover & {
    color: #b80304;
  }
`;

export const ReactionIcon = styled.img`
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 50%;
`;

export const PostHeader = styled.div`
  @media (min-width: 1024px) {
    display: flex;
    border-radius: 20px;
    padding: 25px 15px 40px 25px;
    height: 100%;
    flex: 1;
    max-width: 450px;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  position: absolute;
  top: calc(170px - 32px);
  left: 50%;
  transform: translateX(-50%);
  gap: 8px;

  @media (min-width: 1024px) {
    top: calc(260px - 32px);
    left: 23%;
  }

  /* .reactions {
    position: absolute;
    display: flex;
    border-radius: 20px;
    background-color: whitesmoke;
    width: fit-content;
    align-self: flex-end;
    margin-bottom: 10px;
  }

  .reactionsSum {
    display: flex;
    gap: 5px;
    align-self: flex-end;
    width: fit-content;
    margin-top: 10px;
    margin-bottom: 10px;

    div {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: 600;
    } */
  /* } */
`;

export const ButtonTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
  margin-top: auto;
`;

export const TagButton = styled.button`
  border: none;
  color: #666;
  background-color: #f5f5f5;
  border-radius: 20px;
  padding: 0.5em 1em;
  cursor: pointer;
  font-size: 0.7rem;
  font-weight: 700;
  white-space: nowrap;
  max-width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;

  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: #8b0304;
    color: #fff;
  }
`;

export const IconButton = styled.button`
  position: relative;
  border: none;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ccc;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0 0 0 / 15%);
  width: 50px;
  height: 50px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  line-height: 0;

  &:hover {
    color: #333;
  }
`;

export const ThumbsUpCounterBox = styled.div`
  font-weight: 700;
  display: inline-grid;
  position: inherit;
`;

export const TotalsBox = styled.div`
  font-size: 0.8rem;
  font-weight: 700;
  color: white;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  line-height: 20;
  background-color: #ccc;
  border: 1px white solid;
  border-radius: 50%;
  right: -5px;
  bottom: -5px;
  box-sizing: border-box;
  padding-top: 0.2em;
`;

export const PostAbstract = styled.p`
  font-size: 1rem;
  margin-bottom: 1em;
`;

export const CommentarySection = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  place-self: self-end;
  align-items: center;
`;

export const PostReviewTab = styled.div`
  display: flex;
  border-radius: 20px;
  padding: 0 2em;
  height: 60px;
  align-items: center;
  justify-content: center;
  gap: 25px;

  & div {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 0.7rem;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    letter-spacing: 0.05em;
  }
`;
