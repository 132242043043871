import { formatInTimeZone } from 'date-fns-tz';
import { Drawer } from 'devextreme-react';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useScreenMobile } from '../../hooks/mobileScreen';
import { IconEmail, IconHat, IconTeams } from '../CustomIcons/icons';

import {
  BirthdayName,
  BirthdayPhoto,
  Birthdate,
  BirthdayTooltip,
  BirthdayTooltipWrapper,
  BirthdayEmail,
  BirthdayTeams,
  BirthdayLinksWrapper,
  PhotoWrapper,
  BirthdayDrawer,
  Darken,
} from './styles';

interface Props {
  professional?: any;
}

export const ProfessionalBirthday: React.FC<Props> = ({ professional }) => {
  const [openToolTip, setOpenToolTip] = useState(false);
  const [animateToolTip, setAnimateToolTip] = useState(false);
  const [toolTipMobile, setToolTipMobile] = useState(false);
  const [isToday, setIsToday] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { isMobile } = useScreenMobile();

  const adjustTimezone = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Get the timezone offset in milliseconds
    const adjustedDate = new Date(date.getTime() + timezoneOffset);
    return adjustedDate;
  };

  useEffect(() => {
    const currDate = adjustTimezone(new Date());
    currDate.setHours(0, 0, 0, 0);
    const currYear = currDate.getFullYear();

    const birthday = new Date(professional.Birthday);
    const localBirthday = adjustTimezone(birthday);

    const proBirthOffset = new Date(localBirthday);
    proBirthOffset.setFullYear(currYear);
    proBirthOffset.setHours(0, 0, 0, 0);

    setIsToday(currDate.getTime() === proBirthOffset.getTime());
  }, [professional]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (openToolTip && ref.current && !ref.current.contains(e.target)) {
        setAnimateToolTip(false);
        setTimeout(() => {
          setOpenToolTip(false);
        }, 250);
      }

      if (toolTipMobile && ref.current && !ref.current.contains(e.target)) {
        setAnimateToolTip(false);
        setTimeout(() => {
          setToolTipMobile(false);
        }, 250);
      }
    };

    document.addEventListener('mousedown', checkIfClickedOutside);

    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [openToolTip, toolTipMobile]);

  const toolTipOpener = useCallback(() => {
    if (!openToolTip) {
      setOpenToolTip(true);
      setTimeout(() => {
        setAnimateToolTip(true);
      }, 1);
    }
    if (openToolTip) {
      setAnimateToolTip(false);
      setTimeout(() => {
        setOpenToolTip(false);
      }, 250);
    }
  }, [openToolTip]);

  const mobileToolTipOpener = useCallback(() => {
    if (!toolTipMobile) {
      setToolTipMobile(true);
      setTimeout(() => {
        setAnimateToolTip(true);
      }, 1);
    }
    if (toolTipMobile) {
      setAnimateToolTip(false);
      setTimeout(() => {
        setToolTipMobile(false);
      }, 250);
    }
  }, [toolTipMobile]);

  return (
    <BirthdayTooltipWrapper ref={ref}>
      <PhotoWrapper>
        {isToday && <IconHat />}
        <BirthdayPhoto
          onClick={isMobile ? mobileToolTipOpener : toolTipOpener}
          src={`https://weekly.integrationconsulting.com/Upload/1.1_Professionals/${professional.Login}_192_192.png`}
          alt={professional.Name}
          red={isToday}
        />
      </PhotoWrapper>
      <Birthdate red={isToday}>
        {formatInTimeZone(
          new Date(professional.Birthday),
          'Europe/London',
          'dd MMM',
        )}
      </Birthdate>
      {openToolTip && (
        <BirthdayTooltip
          style={animateToolTip ? { top: '0', opacity: '1' } : {}}
        >
          <BirthdayName>{professional.Name}</BirthdayName>
          <Birthdate>
            {formatInTimeZone(
              new Date(professional.Birthday),
              'Europe/London',
              'dd MMM',
            )}
          </Birthdate>
          <BirthdayLinksWrapper>
            <BirthdayEmail href={`mailto:${professional.Email}`}>
              <IconEmail />
            </BirthdayEmail>
            <BirthdayTeams
              href={`https://teams.microsoft.com/l/chat/0/0?users=${professional.Login}@integrationconsulting.com`}
              target="_blank"
            >
              <IconTeams />
            </BirthdayTeams>
          </BirthdayLinksWrapper>
        </BirthdayTooltip>
      )}

      {toolTipMobile && (
        <Darken
          onClick={mobileToolTipOpener}
          style={animateToolTip ? { opacity: '1' } : {}}
        >
          <BirthdayDrawer
            style={animateToolTip ? { bottom: '0', opacity: '1' } : {}}
          >
            <BirthdayName>{professional.Name}</BirthdayName>
            <Birthdate>
              {formatInTimeZone(
                new Date(professional.Birthday),
                'Europe/London',
                'dd MMM',
              )}
            </Birthdate>
            <BirthdayLinksWrapper>
              <BirthdayEmail href={`mailto:${professional.Email}`}>
                <IconEmail />
              </BirthdayEmail>
              <BirthdayTeams
                href={`https://teams.microsoft.com/l/chat/0/0?users=${professional.Login}@integrationconsulting.com`}
                target="_blank"
              >
                <IconTeams />
              </BirthdayTeams>
            </BirthdayLinksWrapper>
          </BirthdayDrawer>
        </Darken>
      )}
    </BirthdayTooltipWrapper>
  );
};
