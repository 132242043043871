import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 30px;
  width: 100%;
`;

export const HeaderAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  img {
    max-width: 60px;
    max-height: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    p {
      letter-spacing: 2px;
      font-weight: 700;
      text-transform: uppercase;
    }

    p.welcome {
      color: #42526e;
      font-size: 12px;
    }

    p.user-name {
      color: #8b0304;
      font-size: 16px;
    }
  }
`;

export const HeaderInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;

  & > div {
    margin: 0 30px;

    & > p {
      font-weight: 700;
    }

    .value {
      color: #42526e;
      font-size: 18px;
    }

    .label {
      color: #999;
      font-size: 14px;
    }

    .highlight {
      color: #8b0304;
    }
  }
`;

export const HeaderLogo = styled.div`
  img {
    max-width: 100px;
  }
`;
