import styled from 'styled-components';

export const Container = styled.div`
  .infoHeader {
    display: flex;
    flex-direction: column;
    margin-left: 30px;

    h1 {
      font-size: 26px;
      white-space: nowrap;
      font-weight: 600;
      color: #172b4d;
    }

    h2 {
      display: flex;
      gap: 5px;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      color: #9699a6;
      white-space: nowrap;
      margin-bottom: 5px;
    }

    .nameEditTextBox {
      width: 25%;
      margin-bottom: 10px;
    }
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: 100%;
  margin: 0;
  border-radius: 8px;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;

  h1 {
    margin-top: 0;
    align-self: center;
    font-size: 26px;
    white-space: nowrap;
    font-weight: 600;
    color: #172b4d;
  }
  h2 {
    align-self: center;
    font-size: 14px;
    color: #9699a6;
    white-space: nowrap;
    margin-bottom: 10px;
  }

  .uploadContainer {
    width: 100%;
    margin-top: 10px;
  }

  .existingThumb {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 35px;
    gap: 5px;

    img {
      width: 215px;
      height: 138px;
    }

    p {
      text-align: left;
      font-weight: 600;
    }
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
