import React, { useState, useCallback, useEffect } from 'react';

import {
  SelectBox as SelectBoxDev,
  ISelectBoxOptions,
} from 'devextreme-react/select-box';
import { Container } from './styles';

interface SelectBoxProps extends ISelectBoxOptions {
  onChanged(value: string): void;
  value: any;
}

export const SelectBox: React.FC<SelectBoxProps> = ({
  value: defaultValue,
  onChanged,
  readOnly: _readOnly,
  ...rest
}) => {
  const [value, setValue] = useState('');
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    setReadOnly(_readOnly || false);
  }, [_readOnly]);

  const handleChange = useCallback(
    e => {
      setValue(e.value);
      onChanged(e.value);
    },
    [onChanged],
  );

  return (
    <Container>
      <SelectBoxDev
        value={value}
        onValueChanged={handleChange}
        readOnly={readOnly}
        {...rest}
      />
    </Container>
  );
};
