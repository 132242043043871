import React, { BaseHTMLAttributes } from 'react';

import styled from 'styled-components';

interface BigNumbersProps extends BaseHTMLAttributes<HTMLDivElement> {
  title: string;
  number: number;
  relation: number;
  relationPositive: boolean;
  percentBignumber?: boolean;
  percentComparison?: boolean;
}

const Container = styled.div`
  line-height: 1;

  h2 {
    font-size: 2rem;
    color: #333;
    font-weight: bold;

    & small {
      font-size: 0.7em;
    }
  }

  h3 {
    font-size: 1rem;
    color: #ccc;
    font-weight: bold;
    letter-spacing: 0.02em;
  }

  h4 {
    font-size: 1rem;
    font-weight: bold;
    margin-top: 0.5em;
    letter-spacing: 0.01em;

    & small {
      font-size: 0.7em;
    }
  }
`;

export const BigNumbers: React.FC<BigNumbersProps> = ({
  title,
  number,
  relation,
  relationPositive,
  percentBignumber,
  percentComparison,
}) => {
  return (
    <Container>
      <h2>
        {number}
        {percentBignumber && <small>%</small>}
      </h2>
      <h3>{title}</h3>
      <h4 style={relationPositive ? { color: 'green' } : { color: 'red' }}>
        {relationPositive ? '▲' : '▼'} {relation}
        {percentComparison && <small>%</small>}
      </h4>
    </Container>
  );
};
