import React from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

export const PrevButton: React.FC = () => {
  const history = useHistory();

  return (
    <Container onClick={() => history.goBack()}>
      <FiArrowLeft size="24" />
    </Container>
  );
};
