import React, { useState, useCallback, useEffect } from 'react';

import { TagBox as TagBoxDev, ITagBoxOptions } from 'devextreme-react/tag-box';
import { Container } from './styles';

interface TagBoxProps extends ITagBoxOptions {
  onChanged?(value: any[]): void;
  onAdd?(value: any): void;
  onRemove?(value: any): void;
}

export const TagBox: React.FC<TagBoxProps> = ({
  value: defaultValue,
  onChanged,
  onAdd,
  onRemove,
  readOnly: _readOnly,
  ...rest
}) => {
  const [value, setValue] = useState<any[]>([]);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    setValue(defaultValue || []);
  }, [defaultValue]);

  useEffect(() => {
    setReadOnly(_readOnly || false);
  }, [_readOnly]);

  const handleChange = useCallback(
    e => {
      const newValue = e.value as any[];
      const intersectionAdd = newValue.filter(x => !value.includes(x));
      const intersectionDelete = value.filter(x => !newValue.includes(x));
      setValue(e.value);
      if (onChanged) onChanged(e.value);

      if (onAdd && intersectionAdd.length > 0) onAdd(intersectionAdd[0]);

      if (onRemove && intersectionDelete.length > 0)
        onRemove(intersectionDelete[0]);
    },
    [onChanged, value, onAdd, onRemove],
  );

  return (
    <Container>
      <TagBoxDev value={value} onValueChanged={handleChange} {...rest} />
    </Container>
  );
};
