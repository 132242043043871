import React from 'react';
import { Redirect, Switch, useLocation } from 'react-router-dom';
import Route from './Route';
import { SignIn } from '../pages/SignIn';
import { Home } from '../pages/Home';
import { Admin } from '../pages/Admin';
import { Category } from '../pages/Admin/Category';
import { Dashboard } from '../pages/Admin/Dashboard';
import { Edition } from '../pages/Admin/Edition';
import { Tag } from '../pages/Admin/Tag';
import { Post as AdminPost } from '../pages/Admin/Post';
import { GlobalStorage } from '../pages/Admin/GlobalStorage';
import { Permissions } from '../services/api';
import { EditionView } from '../pages/Admin/EditionView';
import { Search } from '../pages/Search';
import { NotFound } from '../components/NotFound/NotFound';
import { PostView } from '../pages/Admin/Post/PostView';
import { EndOfYearCard } from '../pages/EndOfYearCard';

// import RouteEdition from './RouteEdition';
// import RoutePosts from './RoutePosts';

const Routes: React.FC = () => {
  const { pathname } = useLocation();
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/endofyear" exact component={EndOfYearCard} />
      <Route path="/auth/signin" exact component={SignIn} />
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      <Route path="/editions/:id" exact component={Home} isPrivate />
      <Route
        path="/editions/:id/posts/:postIdRouteParam"
        exact
        component={Home}
        isPrivate
      />
      <Route path="/search" component={Search} isPrivate exact />
      <Route
        path="/admin"
        exact
        component={Admin}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/posts"
        exact
        component={AdminPost}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/posts/:postId"
        exact
        component={PostView}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/dashboard"
        exact
        component={Dashboard}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/tags"
        exact
        component={Tag}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/global-storage"
        exact
        component={GlobalStorage}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/categories"
        exact
        component={Category}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        path="/admin/editions"
        exact
        component={Edition}
        isPrivate
        scopes={[Permissions.Admin]}
      />
      <Route
        isPrivate
        exact
        path="/admin/editions/:id"
        component={EditionView}
        scopes={[Permissions.Admin]}
      />
      <Route path={'*' || '/404'} isPrivate component={NotFound} />
    </Switch>
  );
};

export default Routes;
