import { lighten } from 'polished';
import styled from 'styled-components';

export const Top = styled.div`
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 10px 30px white;
  padding: 30px 15px 15px 15px;
  z-index: 10;
`;

export const IconXWrapper = styled.div`
  display: flex;
  margin-bottom: 25px;
  justify-content: space-between;
`;

export const SearchFavoritsInput = styled.input`
  width: 100%;
  border: none;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 14px;

  &:focus {
    outline: none;
  }
`;

export const FavoritesHeader = styled.h1`
  display: flex;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  font-weight: 700;
  color: #ccc;
  cursor: default;

  hr {
    border-color: #ccc;
    margin: 0 auto;
    width: 50%;
  }
`;

export const FavoriteInfoWrapper = styled.div`
  width: 50%;
  padding: 15px 0;
`;

export const HeaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  width: 90%;
  height: 120px;
  border-radius: 7px;
  overflow: hidden;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 29px 0px;
  margin: 20px 5%;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  padding-left: 20px;

  .favoriteImg {
    object-fit: cover;
    filter: brightness(0.9);
    width: 30%;
    height: 100%;
    border-radius: 7px;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    transform: scale(1.04);
  }

  &:hover .favoriteImg {
    transform: scale(1.1);
  }
`;

export const FavoriteDate = styled.div`
  font-size: clamp(0.75rem, 2.5vw, 0.95rem);
  font-weight: 700;
  font-family: 'Roboto Condensed';
  text-align: left;
  cursor: pointer;
  color: #cccccc;

  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

export const HeaderTitle = styled.h2`
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  font-weight: 700;
  line-height: 1.25;
  color: #333333;
  cursor: pointer;
  inline-size: 150px;
  overflow: hidden;
  text-align: left;

  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  ${HeaderBox}:hover & {
    color: #8b0304;
  }
`;

export const RemoveFavorite = styled.div`
  background: none;
  border: none;
  color: #ccc;
  cursor: pointer;
  padding: 15px 15px 15px 0;

  transition: 0.2s;

  &:hover {
    color: ${lighten(0.05, '#8b0304')};
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;
