import styled from 'styled-components';

export const Container = styled.div`
  .dx-filemanager {
    border: none !important;
    height: 100%;
  }
  .dx-drawer-panel-content.dx-drawer-panel-content-initial {
    display: none !important;
  }
  /* .dx-filemanager-breadcrumbs {
    display: none !important;
  } */

  .dx-filemanager-thumbnails-item-thumbnail {
    user-select: none;
    pointer-events: none;
  }
`;
