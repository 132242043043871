import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { WhisperSpinner } from 'react-spinners-kit';
import { MainHeader } from '../../components/MainHeader';
import { HomePostGrid } from '../../components/HomePostGrid/HomePost';
import { useToast } from '../../hooks/toast';
import { useDocumentTitle } from '../../hooks/documentTitle';

import { SearchPage } from '../Home/DialogSearch';
import { DialogEditions } from '../Home/DialogEditions';
import { FavoritesContent } from '../Home/DialogFavorites';
import { SendContent } from '../Home/DialogSendContent';
import { Post } from '../Home/Post';

import {
  Container,
  ContainerLoading,
  Content,
  ContentWrapper,
  NewsWrapper,
  Title,
} from './styles';
import api, { IPost, IReaction, ITotalReaction } from '../../services/api';
import { LoadingPage } from '../Home/styles';
import { FeedbackDialog } from '../Home/DialogFeedback';

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const Search: React.FC = () => {
  const { setTitle } = useDocumentTitle();
  const query = useQuery();
  const navigation = useHistory();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { addToast } = useToast();

  const [posts, setPosts] = useState<IPost[]>([]);
  const [totalPosts, setTotalPosts] = useState(0);
  const [page, setPage] = useState(1);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [openSearch, setOpenSearch] = useState(false);
  // const [postsResponse, setPostsResponse] = useState<any | null>();
  const [openSendContent, setOpenSendContent] = useState(false);
  const [openFavorites, setOpenFavorites] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openEditions, setOpenEditions] = useState(false);
  const [reactions, setReactions] = useState<IReaction[]>([]);

  const [openPost, setOpenPost] = useState(false);
  const [openIdPost, setIdPost] = useState(0);

  const fetchReactions = useCallback(async () => {
    const reationsResponse = await api.get<IReaction[]>(`api/reactions`);

    setReactions(reationsResponse.data);
  }, []);

  // const fetchPosts = useCallback(async () => {
  //   try {
  //     const filter = query.get('filter');
  //     const response = await api.get(`api/posts`, {
  //       params: {
  //         filter,
  //         page,
  //       },
  //     });
  //     setPostsResponse(response);
  //   } catch (err) {
  //     if (err instanceof Error) {
  //       const errorMessage = err.message;
  //       addToast({
  //         type: 'error',
  //         title: errorMessage,
  //       });
  //     }
  //     navigation.push('/404');
  //   } finally {
  //     setTotalPosts(Number(postsResponse.headers['x-total']));
  //     setPosts(state => [...state, ...postsResponse.data]);
  //     setLoading(false);
  //     setFirstLoading(false);
  //   }
  // }, [query, page, navigation, addToast]);

  const fetchPosts = useCallback(async () => {
    setLoading(true);
    const filter = query.get('filter');

    if (filter) {
      const postsResponse = await api.get(`api/posts`, {
        params: {
          filter,
          page,
        },
      });
      setTotalPosts(Number(postsResponse.headers['x-total']));
      setPosts(state => [...state, ...postsResponse.data]);
      setLoading(false);
      setFirstLoading(false);
      setTitle('Search');
      fetchReactions();
    } else {
      addToast({
        type: 'error',
        title: 'Invalid Search',
      });
      navigation.push('/404');
    }
  }, [query, page, setTitle, fetchReactions, addToast, navigation]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts, fetchReactions, setTitle]);

  useEffect(() => {
    containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
    setPosts([]);
    setPage(1);
    setFirstLoading(true);
  }, [query]);

  const handleAdmin = useCallback(() => {
    navigation.push('/admin');
  }, [navigation]);

  const handleFavoriteChanged = useCallback(
    (postId: number, value: boolean) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined) {
        currentPosts[index].isFavorited = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleReactionChanged = useCallback(
    (postId: number, value: ITotalReaction[]) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined) {
        currentPosts[index].totalReactions = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleReadPost = useCallback(
    (postId: number, value: boolean) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined && currentPosts[index]) {
        currentPosts[index].isRead = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleCommentChanged = useCallback(
    (postId: number, value: number) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined) {
        currentPosts[index].totalComments = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleOpenPost = useCallback(postId => {
    setIdPost(postId);
    setOpenPost(true);
  }, []);

  const handleSaveFavorites = useCallback(
    async postId => {
      try {
        handleFavoriteChanged(postId, true);
        await api.post(`/api/posts/${postId}/favorites`);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, handleFavoriteChanged],
  );

  const handleRemoveFavorites = useCallback(
    async postId => {
      try {
        handleFavoriteChanged(postId, false);
        await api.delete(`/api/posts/${postId}/favorites`);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, handleFavoriteChanged],
  );

  const onScroll = useCallback(
    (e: any) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

      if (loading) return;

      if (totalPosts > 0 && posts.length === totalPosts) return;

      if (bottom) {
        setPage(state => state + 1);
      }
    },
    [totalPosts, posts, loading],
  );

  return (
    <>
      <Container onScroll={onScroll}>
        {loading && firstLoading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}

        <MainHeader
          openAdmin={handleAdmin}
          openSearch={() => setOpenSearch(true)}
          openFavorites={() => setOpenFavorites(true)}
          openSendContent={() => setOpenSendContent(true)}
          openFeedback={() => setOpenFeedback(true)}
          overlay
          openEditions={() => setOpenEditions(true)}
        />

        <Content ref={containerRef}>
          <Title>
            {posts.length > 0
              ? 'Results:'
              : 'There is no results for your search'}
          </Title>
          <NewsWrapper>
            {posts.map(post => (
              <HomePostGrid
                key={post.id}
                post={post}
                isPublished
                isTherePostCategory
                onFavorite={handleSaveFavorites}
                onUnfavorite={handleRemoveFavorites}
                onOpenPost={postId => {
                  setOpenPost(true);
                  setIdPost(postId);
                }}
                reactions={reactions}
              />
            ))}
          </NewsWrapper>

          {loading && (
            <LoadingPage>
              <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
            </LoadingPage>
          )}
        </Content>
      </Container>

      {openPost && (
        <Post
          postId={openIdPost}
          open={openPost}
          handleClose={() => {
            setOpenPost(false);
          }}
          preview={false}
          onRead={handleReadPost}
          onFavoriteChanged={handleFavoriteChanged}
          onReactionChanged={handleReactionChanged}
          onCommentChanged={handleCommentChanged}
        />
      )}
      {openSearch && (
        <SearchPage
          open={openSearch}
          handleClose={() => {
            setOpenSearch(false);
          }}
        />
      )}
      {openSendContent && (
        <SendContent
          open={openSendContent}
          handleClose={() => {
            setOpenSendContent(false);
          }}
        />
      )}
      {openFavorites && (
        <FavoritesContent
          openFavorites={openFavorites}
          handleClose={() => {
            setOpenFavorites(false);
          }}
          onClickPost={handleOpenPost}
          onRemovePost={handleFavoriteChanged}
        />
      )}
      {openFeedback && (
        <FeedbackDialog
          open={openFeedback}
          handleClose={() => {
            setOpenFeedback(false);
          }}
        />
      )}
      {openEditions && (
        <DialogEditions
          open={openEditions}
          handleClose={() => {
            setOpenEditions(false);
          }}
        />
      )}
    </>
  );
};
