import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: white;
  overflow: auto;
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-weight: 700;
  padding-top: 100px;
  padding-bottom: 50px;
`;

export const ContainerLoading = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 9999;
`;

export const ContentWrapper = styled.div`
  max-width: 1170px;
  width: 100%;
  text-align: center;

  @media (max-width: 1169px) {
    padding: 0 15px;
  }
`;

export const Content = styled.div`
  background: white;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const NewsWrapper = styled.div`
  width: 100%;
  padding: 0 25px;
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-wrap: wrap;
`;
