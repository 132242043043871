import axios from 'axios';
import { IProfessionalProps } from './master';

const UNAUTHORIZED = 401;
const FORBBIDEN = 403;

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response) {
      const { status } = error.response;
      if (status === UNAUTHORIZED || status === FORBBIDEN) {
        localStorage.clear();
        window.location.href = '/auth/signin';
      }
    }

    return Promise.reject(error);
  },
);
api.defaults.headers.common.Authorization =
  localStorage.getItem('accessToken') || '';

export default api;

export const Permissions = {
  Admin: 'API.Weekly.Admin',
};

export type IPost = {
  image: string | undefined;
  alias: string;
  id: number;
  dateCreated: string;
  title: string;
  text: string;
  idEdition?: number;
  idStatus: number;
  idCategory: number;
  idWeeklyFile: number;
  isOldRender: boolean;
  isTag: number;
  hasPhoto: number;
  hasText: number;
  isFavorited: boolean;
  isRead: boolean;
  hasVideo: number;
  isPartnerRevised: boolean;
  isRevised: boolean;
  totalComments: number;
  postContents: IPostContent[];
  tags: string;
  category: ICategory;
  postTags: IPostTag[];
  professional: IProfessionalProps;
  edition?: IEdition;
  abstract: string;
  nextPost?: IPost;
  previousPost?: IPost;
  postLikes?: IPostLike[];
  postComments?: IPostComment[];
  totalReactions: ITotalReaction[];
  userReaction?: number;
  totalAbsoluteReactions: number;
  mainImage?: IWeeklyFile;
};

export type IStatus = {
  id: number;
  name: string;
};

export type ITotalReaction = {
  idReaction: number;
  total: number;
};

export type IPostFavorites = {
  id: number;
  idPost: number;
  idProfessional: number;
  post: IPost;
};

export type IPostLike = {
  id: number;
  idUser: number;
  idPost: number;
  post: IPost;
  idReaction: number;
  reaction: IReaction;
  dateCreated: Date;
  professional: IProfessionalProps;
};

export type IReaction = {
  id: number;
  description: string;
  imageURL: string;
  isActive: boolean;
};

export type IPostComment = {
  comment: string;
  dateCreated: string;
  idUser: number;
  id: number;
  professional: IProfessionalProps;
};

export type IPostTag = {
  id: number;
  name: string;
};

export type IWeeklyFile = {
  id: number;
  name: string;
  isDirectory: boolean;
  fileSize: number;
  // thumbnail: string;
  uri?: string;
  contentType: string;
  idParent?: number;
};

export type IPostContentType = {
  id: number;
  description: string;
  isActive: boolean;
};

export type IPostContent = {
  id: number;
  prefix: string;
  idType: number;
  idProfessional?: number;
  professional: IProfessionalProps;
  postContentType: IPostContentType;
  textContent?: string;
  alias?: string;
  postContentFiles?: IPostContentFile[];
  postContentSalesCharts?: IPostContentSalesChart[];
  width?: number;
  height?: number;
  isGlobal: boolean;
};

export type IPostContentFile = {
  id: number;
  idWeeklyFile: number;
  sortOrder: number;
  weeklyFile: IWeeklyFile;
};

export type IPostContentSalesChart = {
  id: number;
  idClient: number;
  idGeographic: number;
  idPractice: number;
  projectName: string;
  projectCode: string;
  idProposal?: number;
};

export type ICategory = {
  id: number;
  sortOrder: number;
  name: string;
  idParentCategory: number;
  idStatus: number;
  idCategory: number;
  parentCategory: IParentCategory;
};

export type IParentCategory = {
  dateCreated: Date;
  id: number;
  idStatus: number;
  name: string;
  oldOrder: number;
  sortOrder: number;
  status: { id: number; name: string; dateCreated: Date };
};

export type IEdition = {
  id: number;
  status: Status;
  name: string;
  slogan: string;
  nrEdition: number;
  idStatus: number;
  imageCover: IWeeklyFile;
  imageBackground: IWeeklyFile;
  extraEdition: boolean;
};

export type Status = {
  id: number;
  name: 'Draft' | 'Published' | 'Preview';
};

export type IPostReviewerComment = {
  id: number;
  createdAt: Date;
  reviewer: IProfessionalProps;
  comment: string;
};

export type IDashboardEdition = {
  totalPost: number;
  totalReaction: number;
  totalComment: number;
  totalVideo: number;
  totalView: number;
  editionViewRatio: number;
  editionPostInteractionRatio: number;
  editionMostViewPosts: IEditionMostViewPosts[];
  editionMostLikePosts: IEditionMostLikePosts[];
  editionMostCommentPosts: IEditionMostCommentPosts[];
  editionPractice: IEditionPractice[];
  editionOffice: IEditionOffice[];
  editionJobTitle: IEditionJobTitle[];
};

export type IEditionMostViewPosts = {
  id: number;
  title: string;
  countView: number;
};

export type IEditionMostLikePosts = {
  id: number;
  title: string;
  countLike: number;
};

export type IEditionMostCommentPosts = {
  id: number;
  title: string;
  countComment: number;
};

export type IEditionPractice = {
  idEdition: number;
  practice: string;
  totalLike: number;
  totalSignIn: number;
  totalView: number;
  totalProfessional: number;
  totalPost: number;
};

export type IEditionOffice = {
  idEdition: number;
  office: string;
  totalLike: number;
  totalSignIn: number;
  totalView: number;
  totalProfessional: number;
  totalPost: number;
};

export type IEditionJobTitle = {
  idEdition: number;
  jobTitle: string;
  totalLike: number;
  totalSignIn: number;
  totalView: number;
  totalProfessional: number;
  totalPost: number;
};
