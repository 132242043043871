import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { WhisperSpinner } from 'react-spinners-kit';
import DataSource from 'devextreme/data/data_source';
import { Typography, Drawer, Box } from '@mui/material';
import { CheckBox, SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import { Button } from '../../../../components/Button';
import api, { IStatus } from '../../../../services/api';

import { useToast } from '../../../../hooks/toast';

import { LoadingContainer } from './styles';
import { FormGroup } from '../../../../components/FormGroup';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  onChanged?(status?: IStatus): void;
  idEdition: number;
}

export const DialogEditStatus: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  onChanged,
  idEdition,
}) => {
  const { setValue, control, handleSubmit, watch } = useForm();
  const watchStatus = watch('idStatus');
  const [statusSource, setStatusSource] = useState<DataSource>();
  const [statusRaw, setStatusRaw] = useState<IStatus[]>([]);
  const [loading, setLoading] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const { addToast } = useToast();

  const loadStatusSource = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/editions/${idEdition}`);
    setValue('idStatus', data.idStatus);
    const statusStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get<IStatus[]>('/api/status');

        setStatusRaw(response.data);
        return response.data;
      },
    });

    setStatusSource(
      new DataSource({
        store: statusStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
    setLoading(false);
  }, [idEdition, setValue]);

  useEffect(() => {
    loadStatusSource();
  }, [loadStatusSource]);

  const handleChangeStatus = useCallback(
    async data => {
      setLoading(true);
      try {
        await api.put(`api/editions/${idEdition}`, data);

        const newStatus = statusRaw.find(x => x.id === data.idStatus);

        if (onChanged) onChanged(newStatus);

        addToast({
          type: 'success',
          title: 'Status edited with success.',
        });
      } catch (e) {
        addToast({
          type: 'error',
          title: 'Something went wrong...',
        });
      }
      setLoading(false);
      handleClose();
    },
    [idEdition, addToast, handleClose, onChanged, statusRaw],
  );

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      style={{ position: 'relative' }}
    >
      {loading && (
        <LoadingContainer>
          <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
        </LoadingContainer>
      )}

      <Box width={400} m={3}>
        <Typography
          variant="h5"
          component="h5"
          style={{ fontWeight: 500, marginBottom: '15px' }}
        >
          Edit status
        </Typography>
        {!loading && (
          <form onSubmit={handleSubmit(handleChangeStatus)}>
            <FormGroup fieldSetLabel="Status">
              <Controller
                name="idStatus"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <SelectBox
                    dataSource={statusSource}
                    defaultValue={field.value}
                    value={field.value}
                    displayExpr="name"
                    valueExpr="id"
                    onValueChanged={e => field.onChange(e.value)}
                  />
                )}
              />
            </FormGroup>

            {watchStatus !== 4 && (
              <FormGroup
                fieldSetLabel={`Send email ${
                  watchStatus === 1 ? ' to all professionals' : ' to preview'
                }`}
              >
                <Controller
                  name="sendEmail"
                  control={control}
                  render={({ field }) => (
                    <CheckBox
                      defaultValue={false}
                      onValueChanged={e => {
                        field.onChange(e.value);
                        setSendEmail(e.value);
                      }}
                    />
                  )}
                />
              </FormGroup>
            )}

            <div
              style={{
                display: 'flex',
                position: 'absolute',
                bottom: 15,
                right: 15,
              }}
            >
              <Button
                type="button"
                onClick={handleClose}
                style={{ marginRight: 15 }}
              >
                Cancel
              </Button>

              <Button type="submit" primary>
                Save {sendEmail && ' and send e-mail'}
              </Button>
            </div>
          </form>
        )}
      </Box>
    </Drawer>
  );
};
