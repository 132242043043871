import React, { useState, useCallback, useEffect } from 'react';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import { SelectBox, TextBox } from 'devextreme-react';

import CustomStore from 'devextreme/data/custom_store';
import { Controller, useForm } from 'react-hook-form';

import DataSource from 'devextreme/data/data_source';
// import { WhisperSpinner } from 'react-spinners-kit';
import { Button } from '../../../../components/Button';
import { FormGroup } from '../../../../components/FormGroup';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import { InputError } from '../../../../components/InputError';
import { ContainerLoading } from './styles';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  categoryId: number;
}

export const DialogEditCategory: React.FC<DialogCustomProps> = ({
  open,
  handleClose,
  categoryId,
}) => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState<DataSource>();
  const [status, setStatus] = useState<DataSource>();

  const { control, handleSubmit, setValue } = useForm();

  const loadCategory = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/categories/${categoryId}`);
    setValue('name', data.name);
    setValue('idParentCategory', data.idParentCategory);
    setValue('idStatus', data.idStatus);
    setLoading(false);

    return data;
  }, [categoryId, setValue]);

  const loadCategories = useCallback(async () => {
    const CategoriesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/categories');
        return data;
      },
    });

    setCategories(
      new DataSource({
        store: CategoriesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadStatus = useCallback(async () => {
    const statusStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get('/api/status');
        return data;
      },
    });

    setStatus(
      new DataSource({
        store: statusStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    loadCategory();
    loadCategories();
    loadStatus();
  }, [loadCategories, loadStatus, loadCategory]);

  const handleSend = useCallback(
    async data => {
      setLoading(true);

      await api.put(`/api/categories/${categoryId}`, data);

      handleClose();
      setLoading(false);
    },
    [categoryId, handleClose],
  );

  return (
    <Dialog
      open={open}
      onClose={() => {
        handleClose();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" style={{ marginLeft: '-5px' }}>
        Edit Category
      </DialogTitle>

      <form
        onSubmit={handleSubmit(handleSend)}
        style={{ width: '95%', marginLeft: '18px' }}
      >
        <FormGroup fieldSetLabel="Name*">
          <Controller
            control={control}
            name="name"
            rules={{ required: true }}
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <>
                <TextBox
                  onValueChanged={e => onChange(e.value)}
                  ref={ref}
                  value={value}
                />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>
        <FormGroup fieldSetLabel="Parent Category">
          <Controller
            control={control}
            name="idParentCategory"
            rules={{ required: false }}
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <>
                <SelectBox
                  dataSource={categories}
                  searchExpr={['Name']}
                  minSearchLength={1}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => onChange(e.value)}
                  ref={ref}
                  value={value}
                />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>

        <FormGroup fieldSetLabel="Status*">
          <Controller
            control={control}
            name="idStatus"
            rules={{ required: true }}
            render={({
              field: { onChange, ref, value },
              fieldState: { error },
            }) => (
              <>
                <SelectBox
                  dataSource={status}
                  searchExpr={['name']}
                  minSearchLength={1}
                  showDataBeforeSearch
                  searchEnabled
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={e => onChange(e.value)}
                  ref={ref}
                  value={value}
                />
                {error && <InputError>Please fill this field</InputError>}
              </>
            )}
          />
        </FormGroup>
        <DialogActions>
          <Button onClick={handleClose} style={{ marginRight: '-8px' }}>
            Close
          </Button>
          <Button
            type="submit"
            primary
            disabled={loading}
            style={{ marginRight: '-8px' }}
          >
            Edit
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
