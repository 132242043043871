import React, { useEffect } from 'react';

import { useDocumentTitle } from '../../hooks/documentTitle';

import { Container, Body } from './styles';
import { HeaderComponent } from '../../components/Header';
import { ApplicationLayers } from '../../components/ApplicationLayers';

export const Admin: React.FC<{ title?: string }> = ({ title }) => {
  const { setTitle } = useDocumentTitle();
  useEffect(() => {
    setTitle('Admin');
  }, [setTitle]);

  return (
    <ApplicationLayers>
      <Container>
        <HeaderComponent />
        <Body>
          <section className="welcomeHeader">
            <h1>welcome to the weekly admin</h1>
            <hr />
          </section>
        </Body>
      </Container>
    </ApplicationLayers>
  );
};
