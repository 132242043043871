import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DataGrid, SelectBox } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  Paging,
  SearchPanel,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Tooltip } from '@mui/material';
import DataSource from 'devextreme/data/data_source';
import { WhisperSpinner } from 'react-spinners-kit';
import { BoardBody } from '../../../components/BoardBody';
import { Container, ContainerLoading, Header, Photo } from './styles';
import { ApplicationLayers } from '../../../components/ApplicationLayers';
import { ThePie } from './ThePie';

import { mostViewedPosts, quickChartByOffice } from './data.js';
import { DashboardHeader } from './DashboardHeader';
import { BigNumbers } from './BigNumbers';
import TheBar from './TheBar';
import { Button } from '../../../components/Button';
import api, { IDashboardEdition, IEdition } from '../../../services/api';
import BrazilIcon from '../../../assets/images/brazil-icon.svg';
import UKIcon from '../../../assets/images/uk-icon.svg';
import ChileIcon from '../../../assets/images/chile-icon.svg';
import ArgentinaIcon from '../../../assets/images/argentina-icon.svg';
import MexicoIcon from '../../../assets/images/mexico-icon.svg';
import GermanyIcon from '../../../assets/images/germany-icon.svg';
import USAIcon from '../../../assets/images/usa-icon.svg';
import { ProfessionalLookup } from '../../../components/ProfessionalLookup';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';

interface ChartProps {
  arg: string;
  val: number;
}

interface Chart2Props {
  arg: string;
  postViews: number;
  avgInteraction: number;
  logins: number;
}

export const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { addToast } = useToast();
  const { user } = useAuth();
  const [rawEditions, setRawEditions] = useState<IEdition[]>([]);
  const [editionId, setEditionId] = useState<number>(0);
  const [periodType, setPeriodType] = useState(4);
  const [editionUri, setEditionUri] = useState<string | undefined>('');
  const [editions, setEditions] = useState<DataSource>();
  const [accessPerProfessionalSource, setAccessPerProfessionalSource] =
    useState<DataSource>();
  const [accessPerPeriodSource, setAccessPerPeriodSource] =
    useState<DataSource>();

  const [totalPosts, setTotalPosts] = useState(0);
  const [totalReaction, setTotalReaction] = useState(0);
  const [totalComment, setTotalComment] = useState(0);
  const [totalVideo, setTotalVideo] = useState(0);
  const [totalView, setTotalView] = useState(0);
  const [editionViewRatio, setEditionViewRatio] = useState(0);
  const [editionPostInteractionRatio, setEditionPostInteractionRatio] =
    useState(0);

  const [totalPostsPrevious, setTotalPostsPrevious] = useState(0);
  const [totalReactionPrevious, setTotalReactionPrevious] = useState(0);
  const [totalCommentPrevious, setTotalCommentPrevious] = useState(0);
  const [totalVideoPrevious, setTotalVideoPrevious] = useState(0);
  const [totalViewPrevious, setTotalViewPrevious] = useState(0);
  const [editionViewRatioPrevious, setEditionViewRatioPrevious] = useState(0);
  const [
    editionPostInteractionRatioPrevious,
    setEditionPostInteractionRatioPrevious,
  ] = useState(0);

  const [mostViewData, setMostViewData] = useState<ChartProps[]>([]);
  const [mostLikeData, setMostLikeData] = useState<ChartProps[]>([]);
  const [mostCommentData, setMostCommentData] = useState<ChartProps[]>([]);
  const [editionPracticeData, setEditionPracticeData] = useState<Chart2Props[]>(
    [],
  );
  const [editionOfficeData, setEditionOfficeData] = useState<Chart2Props[]>([]);
  const [editionJobTitleData, setEditionJobTitleData] = useState<Chart2Props[]>(
    [],
  );

  const officeIcons: any = useMemo(
    () => ({
      Brazil: BrazilIcon,
      UK: UKIcon,
      'United Kingdom': UKIcon,
      Chile: ChileIcon,
      Argentina: ArgentinaIcon,
      Germany: GermanyIcon,
      Mexico: MexicoIcon,
      USA: USAIcon,
    }),
    [],
  );

  const loadEditions = useCallback(async () => {
    const { data } = await api.get<IEdition[]>('api/editions');
    const filtered = data.filter(x => x.idStatus === 1);

    setEditionId(filtered[0].id);
    setEditionUri(filtered[0].imageCover.uri);

    setRawEditions(filtered);

    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        return filtered;
      },
    });

    setEditions(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const loadAccessPerUsers = useCallback(async () => {
    const store = new CustomStore({
      key: 'login',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(`api/reports?editionId=${editionId}`);
        return data;
      },
    });

    setAccessPerProfessionalSource(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [editionId]);

  const loadAccessPerPeriod = useCallback(async () => {
    const store = new CustomStore({
      key: 'login',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get(
          `api/reports/period?periodType=${periodType}`,
        );
        return data;
      },
    });

    setAccessPerPeriodSource(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [periodType]);

  const fetchData = useCallback(async () => {
    if (!editionId) return;

    setLoading(true);
    try {
      const [current, previous] = await Promise.all([
        api.get<IDashboardEdition>(`/api/dashboard/editions/${editionId}`),
        api.get<IDashboardEdition>(
          `/api/dashboard/editions/${editionId}/previous`,
        ),
      ]);

      const { data } = current;

      setTotalPosts(data.totalPost);
      setTotalReaction(data.totalReaction);
      setTotalComment(data.totalComment);
      setTotalVideo(data.totalVideo);
      setTotalView(data.totalView);
      setEditionViewRatio(
        data.editionViewRatio
          ? Number((data.editionViewRatio * 100).toFixed(1))
          : 0,
      );
      setEditionPostInteractionRatio(
        data.editionPostInteractionRatio
          ? Number((data.editionPostInteractionRatio * 100).toFixed(1))
          : 0,
      );

      setMostViewData(
        data.editionMostViewPosts.splice(0, 5).map(x => ({
          val: x.countView,
          arg: x.title,
        })),
      );
      setMostLikeData(
        data.editionMostLikePosts.splice(0, 5).map(x => ({
          val: x.countLike,
          arg: x.title,
        })),
      );
      setMostCommentData(
        data.editionMostCommentPosts.splice(0, 5).map(x => ({
          val: x.countComment,
          arg: x.title,
        })),
      );
      setEditionOfficeData(
        data.editionOffice
          .filter(x => x.totalLike || x.totalSignIn || x.totalView)
          .map(x => ({
            arg: x.office,
            avgInteraction: Number(
              (
                (x.totalLike / x.totalPost / x.totalProfessional) *
                100
              )?.toFixed(2),
            ),
            logins: Number(
              (
                (x.totalSignIn / x.totalPost / x.totalProfessional) *
                100
              )?.toFixed(2),
            ),
            postViews: Number(
              ((x.totalView / x.totalPost / x.totalProfessional) * 100).toFixed(
                2,
              ),
            ),
          })),
      );
      setEditionPracticeData(
        data.editionPractice
          .filter(x => x.totalLike || x.totalSignIn || x.totalView)
          .map(x => ({
            arg: x.practice,
            avgInteraction: Number(
              ((x.totalLike / x.totalPost / x.totalProfessional) * 100).toFixed(
                2,
              ),
            ),
            logins: Number(
              (
                (x.totalSignIn / x.totalPost / x.totalProfessional) *
                100
              ).toFixed(2),
            ),
            postViews: Number(
              ((x.totalView / x.totalPost / x.totalProfessional) * 100).toFixed(
                2,
              ),
            ),
          })),
      );
      setEditionJobTitleData(
        data.editionJobTitle
          .filter(x => x.totalLike || x.totalSignIn || x.totalView)
          .map(x => ({
            arg: x.jobTitle,
            avgInteraction: Number(
              ((x.totalLike / x.totalPost / x.totalProfessional) * 100).toFixed(
                2,
              ),
            ),
            logins: Number(
              (
                (x.totalSignIn / x.totalPost / x.totalProfessional) *
                100
              ).toFixed(2),
            ),
            postViews: Number(
              ((x.totalView / x.totalPost / x.totalProfessional) * 100).toFixed(
                2,
              ),
            ),
          })),
      );

      if (previous.data.totalPost !== 0) {
        setTotalPostsPrevious(
          Number((data.totalPost / previous.data.totalPost).toFixed(1)),
        );
      }
      if (previous.data.totalComment !== 0) {
        setTotalCommentPrevious(
          Number((data.totalComment / previous.data.totalComment).toFixed(1)),
        );
      }
      if (previous.data.totalReaction !== 0) {
        setTotalReactionPrevious(
          Number((data.totalReaction / previous.data.totalReaction).toFixed(1)),
        );
      }
      if (previous.data.totalVideo !== 0) {
        setTotalVideoPrevious(
          Number((data.totalVideo / previous.data.totalVideo).toFixed(1)),
        );
      }
      if (previous.data.totalView !== 0) {
        setTotalViewPrevious(
          Number((data.totalView / previous.data.totalView).toFixed(1)),
        );
      }
      if (previous.data.editionPostInteractionRatio !== 0) {
        setEditionPostInteractionRatioPrevious(
          Number(
            (
              data.editionPostInteractionRatio /
              previous.data.editionPostInteractionRatio
            ).toFixed(1),
          ),
        );
      }
      if (previous.data.editionViewRatio !== 0) {
        setEditionViewRatioPrevious(
          Number(
            (data.editionViewRatio / previous.data.editionViewRatio).toFixed(1),
          ),
        );
      }
    } catch {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
    }

    setLoading(false);
  }, [editionId, addToast]);

  useEffect(() => {
    fetchData();
  }, [fetchData, editionId]);

  useEffect(() => {
    loadEditions();
  }, [loadEditions]);

  useEffect(() => {
    loadAccessPerUsers();
  }, [loadAccessPerUsers]);

  useEffect(() => {
    loadAccessPerPeriod();
  }, [loadAccessPerPeriod]);

  const professionalCell = useCallback(e => {
    return (
      <ProfessionalLookup
        jobtitle={e.data.jobTitle.name}
        login={e.data.login}
        name={e.data.name}
      />
    );
  }, []);

  const practiceCell = useCallback(
    e => (
      <p style={{ color: '#333', fontSize: '1rem', fontWeight: 'bold' }}>
        {e.text}
      </p>
    ),
    [],
  );

  const officePhotoCell = useCallback(
    e => {
      const src = officeIcons[e.text];
      return (
        <Tooltip title={e.text}>
          <Photo src={src} />
        </Tooltip>
      );
    },
    [officeIcons],
  );

  const numbersCell = useCallback(
    e => (
      <p style={{ fontSize: '2rem', fontWeight: 'bold', color: '#8b0304' }}>
        {e.text}
      </p>
    ),
    [],
  );

  const handleGenerateExcelByEdition = useCallback(async () => {
    try {
      addToast({
        type: 'info',
        title: 'We are generating your report. It could take a while',
      });

      const response = await api.get(
        `api/reports/edition/excel?editionId=${editionId}`,
        {
          responseType: 'blob',
        },
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-access-by-edition.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Your report is ready. Open or save it below',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Something went wrong with your report. Try again please.',
      });
    }
  }, [addToast, editionId]);

  const handleGenerateExcelByPeriod = useCallback(async () => {
    try {
      addToast({
        type: 'info',
        title: 'We are generating your report. It could take a while',
      });

      const response = await api.get(
        `api/reports/period/excel?periodType=${periodType}`,
        {
          responseType: 'blob',
        },
      );

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `report-access-by-period.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      addToast({
        type: 'success',
        title: 'Your report is ready. Open or save it below',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Something went wrong with your report. Try again please.',
      });
    }
  }, [addToast, periodType]);

  const hasAccessToProfessionalInfo = !!(
    user.login === 'flima' ||
    user.login === 'aforato' ||
    user.login === 'jjamil' ||
    user.login === 'carlima'
  );

  return (
    <ApplicationLayers>
      {loading && (
        <ContainerLoading>
          <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
        </ContainerLoading>
      )}
      <Container>
        <Header className="header" uri={editionUri}>
          <DashboardHeader title="Dashboard" />
          <SelectBox
            placeholder="Select edition..."
            dataSource={editions}
            valueExpr="id"
            displayExpr="name"
            value={editionId}
            onValueChanged={async e => {
              setEditionId(e.value);
              const data = rawEditions.find(x => x.id === e.value);
              if (data?.imageCover?.uri) setEditionUri(data.imageCover.uri);
              accessPerProfessionalSource?.reload();
            }}
          />
        </Header>
        <BoardBody>
          <section>
            <div className="bigNumbersWrapper">
              <BigNumbers
                title="posts"
                number={totalPosts}
                relation={totalPostsPrevious}
                relationPositive={totalPostsPrevious >= 1}
              />
              <BigNumbers
                title="videos"
                number={totalVideo}
                relation={totalVideoPrevious}
                relationPositive={totalVideoPrevious >= 1}
              />
              <BigNumbers
                title="reactions"
                number={totalReaction}
                relation={totalReactionPrevious}
                relationPositive={totalReactionPrevious >= 1}
              />
              <BigNumbers
                title="comments"
                number={totalComment}
                relation={totalCommentPrevious}
                relationPositive={totalCommentPrevious >= 1}
              />
            </div>
            <div className="bigNumbersWrapper">
              <BigNumbers
                title="post views"
                number={totalView}
                relation={totalViewPrevious}
                relationPositive={totalViewPrevious >= 1}
              />
              <BigNumbers
                title="views ratio"
                number={editionViewRatio}
                relation={editionViewRatioPrevious}
                relationPositive={editionViewRatioPrevious >= 1}
                percentBignumber
              />
              <BigNumbers
                title="avg interaction"
                number={editionPostInteractionRatio}
                relation={editionPostInteractionRatioPrevious}
                percentBignumber
                relationPositive={editionPostInteractionRatioPrevious >= 1}
              />
            </div>
          </section>

          <section>
            <ThePie id="one" title="Most Viewed Posts" source={mostViewData} />
            <ThePie
              id="two"
              title="Most Interacted Posts"
              source={mostLikeData}
            />
            <ThePie
              id="three"
              title="Most Commented Posts"
              source={mostCommentData}
            />
          </section>

          <section>
            <TheBar id="barOne" title="By Office" source={editionOfficeData} />
            <TheBar
              id="barTwo"
              title="By Co-management"
              source={editionPracticeData}
            />
            <TheBar
              id="barThree"
              title="By Level"
              source={editionJobTitleData}
            />
          </section>
          {hasAccessToProfessionalInfo && (
            <>
              <span
                style={{
                  marginTop: '15px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontSize: '24px' }}>Access Per Edition</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <SelectBox
                    placeholder="Select edition..."
                    dataSource={editions}
                    valueExpr="id"
                    displayExpr="name"
                    value={editionId}
                    onValueChanged={async e => {
                      setEditionId(e.value);
                      const data = rawEditions.find(x => x.id === e.value);
                      if (data?.imageCover?.uri)
                        setEditionUri(data.imageCover.uri);
                      accessPerProfessionalSource?.reload();
                    }}
                  />
                  <Button primary onClick={handleGenerateExcelByEdition}>
                    Export to Excel
                  </Button>
                </div>
              </span>
              <DataGrid
                className="cssDataGrid"
                dataSource={accessPerProfessionalSource}
              >
                <SearchPanel visible />
                <HeaderFilter visible allowSearch />
                <Column
                  dataField="name"
                  caption="Professional"
                  cellRender={professionalCell}
                />
                <Column
                  dataField="practices[0].name"
                  caption="Practice"
                  cellRender={practiceCell}
                />
                <Column
                  dataField="offices[0].name"
                  caption="Office"
                  cellRender={officePhotoCell}
                  alignment="center"
                  width={130}
                />
                <Column
                  dataField="seenNr"
                  caption="Posts Seen"
                  width={130}
                  alignment="center"
                  cellRender={numbersCell}
                />
                <Column
                  dataField="reactionNr"
                  caption="Reactions"
                  width={130}
                  alignment="center"
                  cellRender={numbersCell}
                />
                <Column
                  dataField="commentNr"
                  caption="Comments"
                  width={130}
                  alignment="center"
                  cellRender={numbersCell}
                />
                <Paging pageSize={10} />
              </DataGrid>
            </>
          )}
          {hasAccessToProfessionalInfo && (
            <>
              <span
                style={{
                  marginTop: '15px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <p style={{ fontSize: '24px' }}>Access Per Period</p>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <SelectBox
                    stylingMode="outlined"
                    placeholder="Select period..."
                    dataSource={[
                      { id: 1, description: '30 days' },
                      { id: 2, description: '15 days' },
                      { id: 3, description: 'Last Edition' },
                      { id: 4, description: 'Current Edition' },
                    ]}
                    value={periodType}
                    onValueChanged={e => {
                      setPeriodType(e.value);
                      accessPerPeriodSource?.reload();
                    }}
                    valueExpr="id"
                    displayExpr="description"
                  />
                  <Button primary onClick={handleGenerateExcelByPeriod}>
                    Export to Excel
                  </Button>
                </div>
              </span>
              <DataGrid dataSource={accessPerPeriodSource}>
                <SearchPanel visible />
                <HeaderFilter visible allowSearch />
                <Column
                  dataField="name"
                  caption="Professional"
                  cellRender={professionalCell}
                />
                <Column
                  dataField="practices[0].name"
                  caption="Practice"
                  cellRender={practiceCell}
                />
                <Column
                  dataField="offices[0].name"
                  caption="Office"
                  cellRender={officePhotoCell}
                  alignment="center"
                  width={130}
                />
                <Column
                  dataField="accessCount"
                  caption="Access"
                  width={130}
                  alignment="center"
                  cellRender={numbersCell}
                />
                <Column
                  dataField="seenNr"
                  caption="Posts Seen"
                  width={130}
                  alignment="center"
                  cellRender={numbersCell}
                />
                <Paging pageSize={10} />
              </DataGrid>
            </>
          )}
        </BoardBody>
      </Container>
    </ApplicationLayers>
  );
};
