import React, { InputHTMLAttributes } from 'react';
import { Container, ContainerInput, ErrorMessage } from './styles';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  errorMessage?: string;
};

export const Input: React.FC<Props> = ({ errorMessage, ...rest }) => {
  return (
    <Container>
      <ContainerInput {...rest} />
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
