import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
} from 'react';

import { useHistory, useParams } from 'react-router-dom';

import { WhisperSpinner } from 'react-spinners-kit';
import { parseISO } from 'date-fns';

import { FiEye } from 'react-icons/fi';
import {
  Container,
  HeaderContainerBox,
  Content,
  TitleBox,
  MainEdition,
  MainTitle,
  BirthdayBox,
  BirthdayArea,
  BirthdayTop,
  ContentWrapper,
  ContainerLoading,
  LoadingPage,
  ScrollDown,
  NewsWrapper,
  SectionTitle,
  SectionMenu,
  SectionMenuButton,
  NavigationButton,
  NPButton,
  NavigationContainer,
} from './styles';
import { Post as PostArticle } from './Post';
import { SearchPage } from './DialogSearch';
import { SendContent } from './DialogSendContent';
import api, {
  ICategory,
  IEdition,
  IPost,
  IReaction,
  ITotalReaction,
} from '../../services/api';
import master, { IProfessionalProps } from '../../services/master';
import { FavoritesContent } from './DialogFavorites';
import { DialogEditions } from './DialogEditions';
import { useToast } from '../../hooks/toast';
import { useScreenMobile } from '../../hooks/mobileScreen';
import { useDocumentTitle } from '../../hooks/documentTitle';
import { ProfessionalBirthday } from '../../components/Birthday/professional';
import { HomePostGrid } from '../../components/HomePostGrid/HomePost';
import { MainHeader } from '../../components/MainHeader';
import { FeedbackDialog } from './DialogFeedback';

type CombinedProps = {
  category: ICategory;
  postsRead: number;
  totalPosts: number;
};

export const Home: React.FC = () => {
  const { id, postIdRouteParam } = useParams<{
    id: string;
    postIdRouteParam: string;
  }>();
  const { isMobile } = useScreenMobile();
  const { setTitle } = useDocumentTitle();
  const [posts, setPosts] = useState<IPost[]>([]);
  const [editionsDetails, setEditionsDetails] = useState<IEdition>();
  const [loading, setLoading] = useState(true);
  const [firstLoading, setFirstLoading] = useState(true);
  const [loadingReviews, setLoadingReviews] = useState(false);
  const [openPost, setOpenPost] = useState(false);
  const [openIdPost, setIdPost] = useState(0);
  const [openSearch, setOpenSearch] = useState(false);
  const [openEditions, setOpenEditions] = useState(false);
  const [openSendContent, setOpenSendContent] = useState(false);
  const [openFavorites, setOpenFavorites] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [openSectionDialog, setOpenSectionDialog] = useState(false);
  const [isPublished, setIsPublished] = useState(false);
  const [birthdays, setBirthdays] = useState<IProfessionalProps[]>([]);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const sectionMenu = useRef<HTMLDivElement | null>(null);

  const [overlayHeader, setOverlayHeader] = useState(false);
  const [reactions, setReactions] = useState<IReaction[]>([]);
  const [editionId, setEditionId] = useState(0);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [selectedCategory, setSelectedCategory] = useState(12);
  const [combinedSectionIndex, setCombinedSectionIndex] =
    useState<CombinedProps[]>();
  const executeScroll = () =>
    titleRef?.current?.scrollIntoView({ behavior: 'smooth' });
  const navigation = useHistory();

  const self = useRef<Array<HTMLHeadingElement>>([]).current;
  const saveRef = (i: number) => (ref: HTMLHeadingElement) => {
    self[i] = ref;
  };

  useEffect(() => {
    if (!loading) {
      const combined = categories.map(category => {
        const totalRead = posts.filter(
          post => post.idCategory === category.id && post.isRead,
        ).length;

        const totalPosts = posts.filter(
          x => x.idCategory === category.id,
        ).length;

        return {
          category,
          postsRead: totalRead,
          totalPosts,
        };
      });

      setCombinedSectionIndex(combined);
    }
  }, [loading, posts, categories]);

  // useEffect(() => {
  //   const checkIfClickedOutside = (e: MouseEvent) => {
  //     if (
  //       openSectionDialog &&
  //       sectionMenu.current &&
  //       !sectionMenu.current.contains(e.target as Node)
  //     ) {
  //       setOpenSectionDialog(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', checkIfClickedOutside);

  //   return () => {
  //     document.removeEventListener('mousedown', checkIfClickedOutside);
  //   };
  // }, [openSectionDialog]);

  const scrollToSection = (i: number) => {
    self[i].scrollIntoView({ behavior: 'smooth' });
  };

  const handleAdmin = useCallback(() => {
    navigation.push('/admin');
  }, [navigation]);

  const isSameDate = (date1: Date, date2: Date): boolean => {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  };

  const adjustTimezone = (date: Date) => {
    const timezoneOffset = date.getTimezoneOffset() * 60000; // Get the timezone offset in milliseconds
    const adjustedDate = new Date(date.getTime() + timezoneOffset);
    return adjustedDate;
  };

  const birthdayFromToday = useCallback(
    (professional: { Birthday: string }) => {
      if (professional.Birthday != null) {
        const currDate = adjustTimezone(new Date());
        currDate.setHours(0, 0, 0, 0);

        const currYear = currDate.getFullYear();

        const birthday = new Date(professional.Birthday);
        const localBirthday = adjustTimezone(birthday);

        const birthdayThisYear = new Date(localBirthday);
        birthdayThisYear.setFullYear(currYear);
        birthdayThisYear.setHours(0, 0, 0, 0);

        const birthdayNextYear = new Date(localBirthday);
        birthdayNextYear.setFullYear(currYear + 1);
        birthdayNextYear.setHours(0, 0, 0, 0);

        if (isSameDate(currDate, birthdayThisYear)) {
          return 0;
        }

        const diff = birthdayThisYear.getTime() - currDate.getTime();
        const diffNextYear = birthdayNextYear.getTime() - currDate.getTime();

        return diff < 0 ? diffNextYear : diff;
      }
      return NaN;
    },
    [],
  );

  const sortBirthday = useCallback(
    (a: { Birthday: string }, b: { Birthday: string }) => {
      return birthdayFromToday(a) - birthdayFromToday(b);
    },
    [birthdayFromToday],
  );

  const { addToast } = useToast();

  const handleOpenPost = useCallback(async postId => {
    setIdPost(postId);
    setOpenPost(true);
  }, []);

  const handleFavoriteChanged = useCallback(
    (postId: number, value: boolean) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined && currentPosts[index]) {
        currentPosts[index].isFavorited = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleReactionChanged = useCallback(
    (postId: number, value: ITotalReaction[]) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined && currentPosts[index]) {
        currentPosts[index].totalReactions = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleCommentChanged = useCallback(
    (postId: number, value: number) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined && currentPosts[index]) {
        currentPosts[index].totalComments = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleReadPost = useCallback(
    (postId: number, value: boolean) => {
      const currentPosts = [...posts];
      const index = currentPosts.findIndex(x => x.id === postId);
      if (index !== undefined && currentPosts[index]) {
        currentPosts[index].isRead = value;
        setPosts(currentPosts);
      }
    },
    [posts],
  );

  const handleSaveFavorites = useCallback(
    async postId => {
      try {
        handleFavoriteChanged(postId, true);
        await api.post(`/api/posts/${postId}/favorites`);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, handleFavoriteChanged],
  );

  const handleRemoveFavorites = useCallback(
    async postId => {
      try {
        handleFavoriteChanged(postId, false);
        await api.delete(`/api/posts/${postId}/favorites`);
      } catch {
        addToast({
          title: 'Something went wrong...',
          type: 'error',
        });
      }
    },
    [addToast, handleFavoriteChanged],
  );

  const loadEdition = useCallback(async () => {
    setLoading(true);

    const [
      postsResponse,
      editionResponse,
      birthdayResponse,
      reactionsResponse,
    ] = await Promise.all([
      api.get<IPost[]>(`api/editions/${id || 'current'}/posts`),
      api.get<IEdition>(`api/editions/${id || 'current'}/`),
      master.get<IProfessionalProps[]>(`master/professionals/`),
      api.get<IReaction[]>(`api/reactions`),
    ]);

    // setSelectedCategory(12);
    const uniqueSubCategories = postsResponse.data
      .filter(
        (v, i, a) => a.findIndex(v2 => v2.idCategory === v.idCategory) === i,
      )
      .map(x => x.category);
    // .sort((a, b) => a.sortOrder - b.sortOrder);
    setCategories(uniqueSubCategories);

    setReactions(reactionsResponse.data);
    const birthdaysFiltered = birthdayResponse.data
      .filter(
        professional =>
          birthdayFromToday(professional) >= 0 &&
          birthdayFromToday(professional) <= 18 * 24 * 60 * 60 * 1000,
      )
      .map(birthday => ({
        ...birthday,
        BirthdayConverted: adjustTimezone(parseISO(birthday.Birthday)),
      }))
      .slice(0)
      .sort(sortBirthday);

    setIsPublished(editionResponse.data.status?.name === 'Published');
    setBirthdays(birthdaysFiltered);
    setPosts(postsResponse.data);
    setLoading(false);
    setFirstLoading(false);
    setEditionsDetails(editionResponse.data);
    setEditionId(editionResponse.data.id);

    if (postIdRouteParam !== undefined) {
      setIdPost(parseInt(postIdRouteParam, 10));
      setOpenPost(true);
    }
  }, [birthdayFromToday, id, postIdRouteParam, sortBirthday]);

  const onScroll = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      const position = e.target.scrollTop;
      setOverlayHeader(position > 1);
      if (!isMobile) setOpenSectionDialog(position > 1);
    },
    [isMobile],
  );

  // useEffect(() => {
  //   setOpenSectionDialog(!isMobile && overlayHeader);
  // }, [overlayHeader, isMobile]);

  useEffect(() => {
    loadEdition();
  }, [loadEdition]);

  useLayoutEffect(() => {
    containerRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setPosts([]);
    setFirstLoading(true);
  }, [id]);

  const reloadPosts = useCallback(async () => {
    setLoadingReviews(true);
    const postsResponse = await api.get(
      `api/editions/${id || 'current'}/posts`,
    );
    setPosts(postsResponse.data);
    setLoadingReviews(false);
  }, [id]);

  useEffect(() => {
    if (editionsDetails && !openPost && !openSendContent && !openFavorites) {
      setTitle(`#${editionsDetails?.nrEdition} | ${editionsDetails?.slogan}`);
    } else if (!editionsDetails) {
      setTitle('Loading...');
    }
  }, [openPost, setTitle, editionsDetails, openSendContent, openFavorites]);

  return (
    <>
      {console.log('GitHub is working!')}
      <div ref={sectionMenu}>
        <MainHeader
          openAdmin={handleAdmin}
          openSearch={() => setOpenSearch(true)}
          openFavorites={() => setOpenFavorites(true)}
          openSendContent={() => setOpenSendContent(true)}
          openFeedback={() => setOpenFeedback(true)}
          openSectionDialog={() => setOpenSectionDialog(x => !x)}
          overlay={overlayHeader}
          openEditions={() => setOpenEditions(true)}
          selectedSection={openSectionDialog}
        />

        {!loading && openSectionDialog && (
          <SectionMenu overlay={overlayHeader}>
            <SectionMenuButton
              selected={selectedCategory === 0}
              onClick={() => {
                setSelectedCategory(0);
                setTimeout(() => {
                  executeScroll();
                }, 100);
              }}
            >
              All
              <div>
                <FiEye /> {posts.filter(x => x.isRead).length} / {posts.length}
              </div>
            </SectionMenuButton>
            {combinedSectionIndex?.map((x, index) => (
              <SectionMenuButton
                key={x.category.id}
                selected={selectedCategory === x.category.id}
                onClick={() => {
                  setSelectedCategory(x.category.id);
                  if (isMobile) {
                    setTimeout(() => {
                      scrollToSection(index);
                    }, 100);

                    setOpenSectionDialog(false);
                  } else {
                    setTimeout(() => {
                      executeScroll();
                    }, 100);
                  }
                }}
              >
                {x.category.name}
                <div>
                  <FiEye /> {x.postsRead} / {x.totalPosts}
                </div>
              </SectionMenuButton>
            ))}
          </SectionMenu>
        )}
      </div>

      <Container onScroll={onScroll}>
        {loading && firstLoading && (
          <ContainerLoading>
            <WhisperSpinner size={58} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}

        <HeaderContainerBox
          cover={editionsDetails?.imageCover?.uri}
          ref={containerRef}
        >
          <TitleBox>
            <MainEdition>#{editionsDetails?.nrEdition}</MainEdition>
            <MainTitle>{editionsDetails?.slogan}</MainTitle>
            <ScrollDown onClick={executeScroll}>
              <svg viewBox="0 0 40 24">
                <path
                  d="M36.5 3.5L20 20L3.50001 3.5"
                  stroke="white"
                  strokeWidth="7"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </ScrollDown>
          </TitleBox>
        </HeaderContainerBox>
        <Content background={editionsDetails?.imageBackground?.uri}>
          <ContentWrapper>
            <BirthdayArea ref={titleRef}>
              <BirthdayTop>Upcoming birthdays</BirthdayTop>
              <BirthdayBox
                style={{ justifyContent: birthdays.length < 5 ? 'center' : '' }}
              >
                {birthdays.map(professional => {
                  return (
                    <ProfessionalBirthday
                      key={professional.ID}
                      professional={professional}
                    />
                  );
                })}
              </BirthdayBox>
            </BirthdayArea>
            {!loading &&
              categories
                .filter(
                  x =>
                    isMobile ||
                    selectedCategory === 0 ||
                    x.id === selectedCategory,
                )
                .map((category, categoryIndex) => (
                  <>
                    <SectionTitle
                      key={category.id}
                      ref={saveRef(categoryIndex)}
                    >
                      {category?.name}
                    </SectionTitle>
                    <NewsWrapper>
                      {posts
                        .filter(post => post.idCategory === category.id)
                        .map(post => (
                          <HomePostGrid
                            editionsDetails={editionsDetails}
                            key={post.id}
                            post={post}
                            isPublished={isPublished}
                            isTherePostCategory
                            loadingReviews={loadingReviews}
                            onFavorite={handleSaveFavorites}
                            onUnfavorite={handleRemoveFavorites}
                            reactions={reactions}
                            onOpenPost={handleOpenPost}
                          />
                        ))}
                    </NewsWrapper>

                    {!isMobile && selectedCategory !== 0 && (
                      <NavigationContainer>
                        {categories[
                          categories.findIndex(x => x.id === selectedCategory) -
                            1
                        ] ? (
                          <NavigationButton
                            placement="previous"
                            onClick={() => {
                              executeScroll();
                              setSelectedCategory(
                                categories[
                                  categories.findIndex(
                                    x => x.id === selectedCategory,
                                  ) - 1
                                ].id,
                              );
                            }}
                            style={{ alignItems: 'flex-end' }}
                          >
                            <NPButton> ⟵ Previous Section</NPButton>
                            <div
                              className="categoryTitle"
                              style={{ textAlign: 'right' }}
                            >
                              {
                                categories[
                                  categories.findIndex(
                                    x => x.id === selectedCategory,
                                  ) - 1
                                ].name
                              }
                            </div>
                          </NavigationButton>
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}

                        {categories[
                          categories.findIndex(x => x.id === selectedCategory) +
                            1
                        ] ? (
                          <NavigationButton
                            onClick={() => {
                              executeScroll();
                              setSelectedCategory(
                                categories[
                                  categories.findIndex(
                                    x => x.id === selectedCategory,
                                  ) + 1
                                ].id,
                              );
                            }}
                            placement="next"
                            style={{ alignItems: 'flex-start' }}
                          >
                            <NPButton>Next Section ⟶</NPButton>
                            <div
                              className="categoryTitle"
                              style={{ textAlign: 'left' }}
                            >
                              {
                                categories[
                                  categories.findIndex(
                                    x => x.id === selectedCategory,
                                  ) + 1
                                ].name
                              }
                            </div>
                          </NavigationButton>
                        ) : (
                          <div style={{ flex: 1 }} />
                        )}
                      </NavigationContainer>
                    )}
                  </>
                ))}

            {loading && (
              <LoadingPage>
                <WhisperSpinner
                  size={58}
                  backColor="#8b0304"
                  frontColor="#fff"
                />
              </LoadingPage>
            )}
          </ContentWrapper>

          {openFeedback && (
            <FeedbackDialog
              open={openFeedback}
              handleClose={() => {
                setOpenFeedback(false);
              }}
            />
          )}

          {/* {openSectionDialog && !loading && (
            <DialogSection
              posts={posts}
              open={openSectionDialog}
              handleClose={() => {
                setOpenSectionDialog(false);
              }}
            />
          )} */}

          {openPost && (
            <PostArticle
              postId={openIdPost}
              open={openPost}
              handleClose={() => {
                reloadPosts();
                setIdPost(0);
                setOpenPost(false);
                if (postIdRouteParam) navigation.push(`/editions/${id}`);
              }}
              onRead={handleReadPost}
              preview={!isPublished}
              onFavoriteChanged={handleFavoriteChanged}
              onReactionChanged={handleReactionChanged}
              onCommentChanged={handleCommentChanged}
              onChangedCategoryId={categoryId => {
                setSelectedCategory(categoryId);
              }}
            />
          )}
          {openSearch && (
            <SearchPage
              open={openSearch}
              handleClose={() => {
                setOpenSearch(false);
              }}
            />
          )}
          {openSendContent && (
            <SendContent
              open={openSendContent}
              handleClose={() => {
                setOpenSendContent(false);
              }}
            />
          )}
          {openFavorites && (
            <FavoritesContent
              openFavorites={openFavorites}
              handleClose={() => {
                setOpenFavorites(false);
              }}
              onClickPost={handleOpenPost}
              onRemovePost={handleFavoriteChanged}
            />
          )}
          {openEditions && (
            <DialogEditions
              handleClose={() => {
                setOpenEditions(false);
              }}
              open={openEditions}
            />
          )}
        </Content>
      </Container>
    </>
  );
};
