import React, { useCallback, useEffect, useState } from 'react';
import { FiCheck, FiEdit, FiX } from 'react-icons/fi';
import { useParams } from 'react-router-dom';
import { WhisperSpinner } from 'react-spinners-kit';
import { IconButton, Tooltip } from '@mui/material';
import { DateBox, TextBox } from 'devextreme-react';
import DataSource from 'devextreme/data/data_source';
import CustomStore from 'devextreme/data/custom_store';
import DataGrid, {
  HeaderFilter,
  MasterDetail,
  Scrolling,
  Column,
} from 'devextreme-react/data-grid';
import { PrevButton } from '../../../components/PrevButton';
import { Upload } from '../../../components/Upload';
import { useToast } from '../../../hooks/toast';
import api, { ICategory, IStatus } from '../../../services/api';
import { Container, ContainerLoading, Section } from './styles';
import { DialogRemoveCoverImage } from './DialogRemoveCoverImage';
import { DialogRemoveEmailImage } from './DialogRemoveEmailImage';
import { DialogRemoveBackgroundImage } from './DialogRemoveBackgroundImage';
import { DialogEditStatus } from '../Edition/DialogEditStatus';
import { PostsGrid } from './PostsGrid';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

interface ICustomParams {
  id: string;
}

export const EditionView: React.FC = () => {
  const { id } = useParams<ICustomParams>();
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [openEditStatus, setOpenEditStatus] = useState(false);
  const [isEditingSlogan, setIsEditingSlogan] = useState(false);
  const [isEditingDatePublished, setIsEditingDatePublished] = useState(false);
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const [statusSource, setStatusSource] = useState<DataSource>();
  const [openConfirmRemoveCoverDialog, setOpenConfirmRemoveCoverDialog] =
    useState(false);
  const [openConfirmRemoveEmailDialog, setOpenConfirmRemoveEmailDialog] =
    useState(false);
  const [
    openConfirmRemoveBackgroundDialog,
    setOpenConfirmRemoveBackgroundDialog,
  ] = useState(false);

  const [editionInfo, setEditionInfo] = useState();
  const [name, setName] = useState('');
  const [nrEdition, setNrEdition] = useState(0);
  const [slogan, setSlogan] = useState('');
  const [originalSlogan, setOriginalSlogan] = useState('');
  const [dateCreated, setDateCreated] = useState(new Date());
  const [datePublished, setDatePublished] = useState(new Date());
  const [originalDatePublished, setOriginalDatePublished] = useState(
    new Date(),
  );
  const [coverPage, setCoverPage] = useState();
  const [status, setStatus] = useState<IStatus | undefined>({} as IStatus);
  const [coverImgName, setCoverImgName] = useState('');
  const [coverImgThumb, setCoverImgThumb] = useState('');
  const [emailImgName, setEmailImgName] = useState('');
  const [emailImgThumb, setEmailImgThumb] = useState('');
  const [backgroundImgName, setBackgroundImgName] = useState('');
  const [backgroundImgThumb, setBackgroundImgThumb] = useState('');
  const [categoryLoad, setCategoryLoad] = useState<ICategory[]>([]);

  const loadCategory = useCallback(async () => {
    const { data } = await api.get<ICategory[]>(`api/editions/${id}/posts`);

    const uniq = data.map(x => x.idCategory);
    const uniqFiltered = data.filter(
      ({ idCategory }, index) => !uniq.includes(idCategory, index + 1),
    );

    setCategoryLoad(uniqFiltered);
  }, [id]);

  const loadEdition = useCallback(async () => {
    setLoading(true);
    const { data } = await api.get(`/api/editions/${Number(id)}`);
    setEditionInfo(data);
    setName(data.name);
    setNrEdition(data.nrEdition);
    setSlogan(data.slogan);
    // "original" variables used for fixing bug when canceling edition. They are used to keep original variable values.
    setOriginalSlogan(data.slogan);
    setDateCreated(data.dateCreated);
    setDatePublished(data.datePublished);
    setOriginalDatePublished(data.datePublished);
    setCoverPage(data.coverPage);
    setStatus(data.status);
    if (data.imageCover) {
      setCoverImgName(data.imageCover.name);
      setCoverImgThumb(data.imageCover.uri);
    }
    if (data.imageEmail) {
      setEmailImgName(data.imageEmail.name);
      setEmailImgThumb(data.imageEmail.uri);
    }
    if (data.imageBackground) {
      setBackgroundImgName(data.imageBackground.name);
      setBackgroundImgThumb(data.imageBackground.uri);
    }

    const statusStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const response = await api.get('/api/status');
        return response.data;
      },
    });

    setStatusSource(
      new DataSource({
        store: statusStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setLoading(false);
  }, [id]);

  useEffect(() => {
    loadEdition();
    loadCategory();
  }, [loadEdition, loadCategory]);

  const submitFileCover = useCallback(
    async (files: File[]) => {
      setLoading(true);
      const data = new FormData();
      files.map(file => {
        data.append('file', file, file.name);
      });

      try {
        await api.put(`/api/editions/${id}/cover-image`, data);
        loadEdition();
        addToast({
          type: 'success',
          title: 'Cover image updated successfully!',
        });
        setLoading(false);
      } catch {
        addToast({
          type: 'error',
          title: 'Error while uploading cover image, try again.',
        });
        setLoading(false);
      }
    },
    [addToast, id, loadEdition],
  );

  const submitFileEmail = useCallback(
    async (files: File[]) => {
      setLoading(true);
      const data = new FormData();
      files.map(file => {
        data.append('file', file, file.name);
      });

      try {
        await api.put(`/api/editions/${id}/email-image`, data);
        loadEdition();
        addToast({
          type: 'success',
          title: 'Email image updated successfully!',
        });
        setLoading(false);
      } catch {
        addToast({
          type: 'error',
          title: 'Error while uploading email image, try again.',
        });
        setLoading(false);
      }
    },
    [addToast, id, loadEdition],
  );

  const submitFileBackground = useCallback(
    async (files: File[]) => {
      setLoading(true);
      const data = new FormData();
      files.map(file => {
        data.append('file', file, file.name);
      });

      try {
        await api.put(`/api/editions/${id}/background-image`, data);

        loadEdition();

        addToast({
          type: 'success',
          title: 'Background image updated successfully!',
        });
        setLoading(false);
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Error while uploading background image, try again.',
        });
        setLoading(false);
      }
    },
    [addToast, id, loadEdition],
  );

  const handleMetadataChange = useCallback(async () => {
    await api.put(`api/editions/${id}`, {
      slogan,
      datePublished,
    });

    addToast({
      type: 'success',
      title: 'Saved',
    });

    loadEdition();
  }, [addToast, datePublished, id, loadEdition, slogan]);

  const postsGridComponent = useCallback(
    categoryData => (
      <PostsGrid
        categoryId={categoryData.data.data.idCategory}
        editionId={Number(id)}
      />
    ),
    [id],
  );

  return (
    <ApplicationLayers>
      <Container>
        {loading && (
          <ContainerLoading>
            <WhisperSpinner size={50} backColor="#8b0304" frontColor="#fff" />
          </ContainerLoading>
        )}
        {!loading && (
          <>
            <PrevButton />
            <header className="infoHeader">
              <h1 style={{ margin: '5px 0' }}>{name}</h1>
              {!isEditingSlogan ? (
                <h2
                  onClick={() => {
                    setIsEditingSlogan(true);
                    setIsEditingDatePublished(false);
                    setIsEditingStatus(false);
                  }}
                  role="none"
                >
                  {slogan} <FiEdit color="#8b0304" />
                </h2>
              ) : (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <TextBox
                    defaultValue={slogan}
                    className="nameEditTextBox"
                    onValueChange={value => setSlogan(value)}
                  />
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => {
                        setIsEditingSlogan(false);
                      }}
                    >
                      <FiX color="#8b0304" size={26} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <IconButton
                      onClick={() => {
                        handleMetadataChange();
                        setIsEditingSlogan(false);
                      }}
                    >
                      <FiCheck color="#8b0304" size={26} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              {!isEditingDatePublished ? (
                <h2
                  onClick={() => {
                    setIsEditingDatePublished(true);
                    setIsEditingSlogan(false);
                    setIsEditingStatus(false);
                  }}
                  role="none"
                >
                  {`Publish at ${datePublished === undefined
                      ? 'N/A'
                      : new Date(datePublished).toDateString()
                    }`}{' '}
                  <FiEdit color="#8b0304" />
                </h2>
              ) : (
                <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                  <DateBox
                    defaultValue={datePublished}
                    openOnFieldClick
                    className="nameEditTextBox"
                    onValueChange={value => setDatePublished(value)}
                  />{' '}
                  <Tooltip title="Cancel">
                    <IconButton
                      onClick={() => {
                        setIsEditingDatePublished(false);
                      }}
                    >
                      <FiX color="#8b0304" size={26} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Submit">
                    <IconButton
                      onClick={() => {
                        handleMetadataChange();
                        setIsEditingDatePublished(false);
                      }}
                    >
                      <FiCheck color="#8b0304" size={26} />
                    </IconButton>
                  </Tooltip>
                </div>
              )}
              <h2 onClick={() => setOpenEditStatus(true)} role="none">
                {`Status: ${status?.name}`} <FiEdit color="#8b0304" />
              </h2>
            </header>
            <div
              style={{
                display: 'flex',
                gap: 20,
                justifyContent: 'space-between',
                margin: 20,
                marginTop: 0,
              }}
            >
              <Section>
                <h1>Cover Picture</h1>
                {coverImgThumb && coverImgThumb !== '' && (
                  <div
                    style={{ display: 'flex', gap: '10px', margin: '0 auto' }}
                  >
                    <div className="existingThumb">
                      <img src={`${coverImgThumb}`} alt="Cover" />
                      <p>{coverImgName}</p>
                    </div>
                    <Tooltip title="Click to remove image">
                      <IconButton
                        style={{
                          height: 'fit-content',
                          alignSelf: 'center',
                          marginBottom: '30px',
                        }}
                        onClick={() => setOpenConfirmRemoveCoverDialog(true)}
                      >
                        <FiX />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!coverImgThumb && (
                  <div className="uploadContainer">
                    <Upload onUpload={submitFileCover} />
                  </div>
                )}
              </Section>

              <Section>
                <h1>Email Picture</h1>
                {emailImgThumb && emailImgThumb !== '' && (
                  <div
                    style={{ display: 'flex', gap: '10px', margin: '0 auto' }}
                  >
                    <div className="existingThumb">
                      <img src={`${emailImgThumb}`} alt="Email" />
                      <p>{emailImgName}</p>
                    </div>
                    <Tooltip title="Click to remove image">
                      <IconButton
                        style={{
                          height: 'fit-content',
                          alignSelf: 'center',
                          marginBottom: '30px',
                        }}
                        onClick={() => setOpenConfirmRemoveEmailDialog(true)}
                      >
                        <FiX />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!emailImgThumb && (
                  <div className="uploadContainer">
                    <Upload onUpload={submitFileEmail} />
                  </div>
                )}
              </Section>

              <Section>
                <h1>Background Picture</h1>
                {backgroundImgThumb && backgroundImgThumb !== '' && (
                  <div
                    style={{ display: 'flex', gap: '10px', margin: '0 auto' }}
                  >
                    <div className="existingThumb">
                      <img src={`${backgroundImgThumb}`} alt="Background" />
                      <p>{backgroundImgName}</p>
                    </div>
                    <Tooltip title="Click to remove image">
                      <IconButton
                        style={{
                          height: 'fit-content',
                          alignSelf: 'center',
                          marginBottom: '30px',
                        }}
                        onClick={() =>
                          setOpenConfirmRemoveBackgroundDialog(true)
                        }
                      >
                        <FiX />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!backgroundImgThumb && (
                  <div className="uploadContainer">
                    <Upload onUpload={submitFileBackground} />
                  </div>
                )}
              </Section>
            </div>
          </>
        )}

        {openConfirmRemoveCoverDialog && (
          <DialogRemoveCoverImage
            open={openConfirmRemoveCoverDialog}
            handleClose={() => {
              setOpenConfirmRemoveCoverDialog(false);
            }}
            handleSubmit={() => {
              setCoverImgName('');
              setCoverImgThumb('');
              setOpenConfirmRemoveCoverDialog(false);
              addToast({
                title: 'Cover image removed successfully.',
                type: 'success',
                description: '',
              });
              loadEdition();
            }}
            id={parseInt(id, 10)}
          />
        )}

        {openConfirmRemoveEmailDialog && (
          <DialogRemoveEmailImage
            open={openConfirmRemoveEmailDialog}
            handleClose={() => {
              setOpenConfirmRemoveEmailDialog(false);
            }}
            handleSubmit={() => {
              setEmailImgName('');
              setEmailImgThumb('');
              setOpenConfirmRemoveEmailDialog(false);
              addToast({
                title: 'Email image removed successfully.',
                type: 'success',
                description: '',
              });
              loadEdition();
            }}
            id={parseInt(id, 10)}
          />
        )}

        {openConfirmRemoveBackgroundDialog && (
          <DialogRemoveBackgroundImage
            open={openConfirmRemoveBackgroundDialog}
            handleClose={() => {
              setOpenConfirmRemoveBackgroundDialog(false);
            }}
            handleSubmit={() => {
              setBackgroundImgName('');
              setBackgroundImgThumb('');
              setOpenConfirmRemoveBackgroundDialog(false);
              addToast({
                title: 'Background image removed successfully.',
                type: 'success',
                description: '',
              });
              loadEdition();
            }}
            id={parseInt(id, 10)}
          />
        )}

        <div>
          <DataGrid hoverStateEnabled={false} dataSource={categoryLoad}>
            <HeaderFilter visible />
            <Scrolling mode="infinite" />
            <Column dataField="category.name" />
            <Column dataField="dateCreated" dataType="datetime" />
            <MasterDetail enabled component={postsGridComponent} />
          </DataGrid>
        </div>

        {openEditStatus && (
          <DialogEditStatus
            open={openEditStatus}
            handleClose={() => setOpenEditStatus(false)}
            onChanged={newStatus => setStatus(newStatus)}
            idEdition={Number(id)}
          />
        )}
      </Container>
    </ApplicationLayers>
  );
};
