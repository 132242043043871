import styled, { css } from 'styled-components';

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

interface ItemProps {
  selected?: boolean;
}

export const Thumbnail = styled.img`
  margin: 0px 10px 0px 0px !important;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ThumbnailSvg = styled.div`
  height: 32px;
  width: 32px;
  background-color: #8b0304;
  border-radius: 3px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;

export const ContentItem = styled.div<ItemProps>`
  display: flex;
  flex-direction: row;
  width: 100%;
  transition: background-color 0.2s;
  border-radius: 3px;
  padding: 8px;
  align-items: center;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: #f5f5f5;

  &:hover {
    background-color: #eaeaea;
  }

  &:active {
    background-color: rgba(139, 3, 3, 0.1);
    border: 2px solid #8b0304;
  }

  ${props =>
    props.selected &&
    css`
      background-color: rgba(139, 3, 3, 0.1);
      border: 2px solid #8b0304;
    `}

  img {
    margin: 0px 4px 0px 0px !important;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    margin: 0px 4px 0px 0px !important;
    height: 32px;
    width: 32px;
    border-radius: 3px;
    display: flex;
    background-color: #8b0304;
    align-items: center;
    justify-content: center;

    & > svg {
      color: #fff;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    padding-left: 8px;
    flex: 1;

    span {
      font-size: 12px;
      color: #6b778c;
    }
  }

  .edit {
    align-items: center;
  }
`;

export const Title = styled.h2`
  font-size: 24px;
  color: #333;
`;

export const ThumbnailContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Tabs = styled.div`
  width: 100%;
`;

export const TabPanel = styled.div`
  width: 100%;
  position: relative;
`;
