import styled, { css } from 'styled-components';

export const BirthdayTooltipWrapper = styled.div`
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: center;

  @media (max-width: 600px) {
    flex: 0 0 16vw;
  }

  & > p {
    color: #ccc;
    font-size: 0.9em;
    letter-spacing: 0.05em;
  }
`;

export const BirthdayTooltip = styled.div`
  position: absolute;
  transform: translate(-50%, -115%);
  top: 50px;
  opacity: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 1em;
  border-radius: 5px;
  background-color: white;
  filter: drop-shadow(0 0 30px rgba(0 0 0 / 15%));
  transition: all 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  min-width: 150px;
  z-index: 10;

  &:after {
    content: '';
    position: absolute;

    bottom: -10px;
    border-radius: 5px;
    border-top: 15px solid white;
    border-right: 15px solid transparent;
    border-left: 15px solid transparent;
    border-bottom: none;
  }
`;

export const BirthdayDrawer = styled.div`
  position: fixed;
  transform: translateX(-50%);
  bottom: -100vh;
  opacity: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  padding: 2em 1em 1em 1em;
  border-radius: 20px 20px 0 0;
  background-color: white;
  box-shadow: -4px 0 100px rgba(0 0 0 / 50%);
  transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
  min-width: 150px;
  width: 100%;
  z-index: 99;
`;

export const Darken = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 98;
  background-color: rgba(0 0 0 / 50%);
  opacity: 0;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
`;

type BirthdateProps = {
  red?: any;
};

export const PhotoWrapper = styled.div<BirthdateProps>`
  cursor: pointer;
  position: relative;
  transition: all 250ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
  & svg {
    position: absolute;
    height: 25px;
    width: 25px;
    top: -15px;
    right: 3px;
    z-index: 1;
  }

  &:hover {
    transform: scale(1.1);
  }
`;

export const BirthdayPhoto = styled.img<BirthdateProps>`
  cursor: pointer;
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  object-fit: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  filter: brightness(0.9);
  transition: all 250ms cubic-bezier(0.55, 0.06, 0.68, 0.19);
  ${PhotoWrapper}:hover & {
    filter: brightness(0.95);
  }
`;

export const BirthdayName = styled.h3`
  margin: 0 auto;
  align-self: center;
  text-align: center;
  line-height: 1;
  font-size: 1.2em;
  letter-spacing: 0.1em;
  font-weight: 700;
  color: #8b0304;
  text-transform: uppercase;
`;

export const Birthdate = styled.p<BirthdateProps>`
  font-size: 1em;
  padding: 0.5em;
  align-self: center;
  text-align: center;
  font-weight: 700;
  bottom: 0;
  color: #000;
  text-transform: uppercase;
  ${props =>
    props.red &&
    css`
      color: #8b0304 !important;
    `}
`;

export const BirthdayLinksWrapper = styled.div`
  display: flex;
  gap: 1em;
  align-items: center;
`;

export const BirthdayEmail = styled.a`
  & svg {
    width: 20px;
    height: 20px;
    fill: #ccc;
    transition: 0.5s;
  }

  & svg:hover {
    fill: #999;
  }
`;

export const BirthdayTeams = styled.a`
  & svg {
    width: 25px;
    height: 25px;
    fill: #ccc;
    transition: 0.5s;
  }

  & svg:hover {
    fill: #999;
  }
`;
