import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 15px;

  & > svg {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderSearch = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
  margin-bottom: 14px;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .css-1a0q5i4-MuiInputBase-root-MuiOutlinedInput-root {
    border-radius: 5px;
    background: #dedede;
  }
  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const SearchField = styled.div`
  flex: 1;
`;

export const SearchAreaWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  justify-content: space-around;

  .css-ahj2mt-MuiTypography-root {
    font-family: 'Roboto Condensed', sans-serif;
  }
`;

export const DateArea = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
`;

export const DateWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 12px;
`;

export const TitleBox = styled.div``;

export const StyleComponent = styled.p`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
  letter-spacing: 0.05em;
`;

export const DateTitle = styled.h1`
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  font-family: 'Roboto Condensed', sans-serif;
`;

export const DateFromBox = styled.div`
  width: 45%;
`;

export const DateToBox = styled.div`
  width: 45%;
`;

export const SearchButtonBox = styled.div`
  align-self: center;
  margin-top: 18px;
`;
