import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;

  .newTagBtn {
    float: right;
    align-self: flex-end;
    margin-right: 10px;
    margin-bottom: 15px;
  }

  .dx-toolbar .dx-texteditor {
    width: 100% !important;
  }

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
    width: 100% !important;
  }

  .dx-datagrid-search-panel {
    margin-left: 0px;
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  background-color: #efefef;
  height: 100vh;
`;
