import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import config from 'devextreme/core/config';

import AppProvider from './hooks';

import Routes from './routes';

import 'devextreme/dist/css/dx.common.css';
import './assets/dx.material.integration.css';
import './assets/custom.css';

config({
  editorStylingMode: 'outlined',
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#8b0304',
    },
  },
});

const App: React.FC = () => (
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <Routes />
      </AppProvider>
    </ThemeProvider>
  </BrowserRouter>
);

export default App;
