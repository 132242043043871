import styled, { keyframes } from 'styled-components';

// const enlargeLeft = keyframes`
//   from {

//     transform: scaleX(0);
//   }
//   to {
//     transform: scaleX(100%);
//   }
// `;

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  border-radius: 20px 20px 0 0;

  @media (max-width: 1024px) {
    overflow-y: initial;
  }
`;

export const ContainerLoading = styled.div`
  z-index: 9999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
`;

export const HeaderCategory = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 40px;
  background-color: #333;
  color: #fff;
  overflow: hidden;

  & div {
    position: relative;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;

// export const HeaderTitleFixed = styled.div`
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   position: absolute;
//   left: 0;
//   right: 0;
//   top: 40px;
//   z-index: 99;
//   width: 100%;
//   /* height: 60px; */
//   background-color: #fff;
//   color: #8b0304;
//   box-shadow: rgb(139 3 4 / 25%) 0px 13px 27px -5px,
//     rgb(139 3 4 / 30%) 0px 8px 16px -8px;
//   animation: 0.5s ${fadeInAnimation};

//   .content {
//     width: 920px;
//     font-weight: 700;
//     font-size: 18px;
//     margin: 0 auto;
//     padding: 10px 25px;
//   }
// `;

export const Content = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 1024px) {
    flex-direction: column;
  }

  .ql-editor {
    width: 100% !important;
    height: auto;
    font-size: 1.125rem;
    overflow: visible;

    & p {
      line-height: 1.5;
    }
  }

  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
  }

  table td,
  table th {
    outline: 0;
    /* vertical-align: middle; */
  }
  td,
  th {
    /* border: 1px solid #e0e0e0; */
    padding: 2px 5px;
  }

  @media (max-width: 992px) {
    width: 100%;
  }

  strong {
    font-weight: 700;
  }

  em {
    font-style: italic;
  }

  pre {
    background-color: #7d7d7d !important;
    font-size: 14px;
  }

  h1 {
    font-size: 28px !important;
  }

  h2 {
    font-size: 24px !important;
  }

  h3 {
    font-size: 20px !important;
  }
`;

export const HeaderBox = styled.div`
  margin-bottom: 32px;
`;

export const HeaderSubtitle = styled.h1`
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  color: #333333;
`;

export const ButtonTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  justify-content: center;
`;

export const TagButton = styled.button`
  border: none;
  color: #333;
  background-color: #f5f5f5;
  border-radius: 40px;
  padding: 0.5em 1em;
  cursor: pointer;
  font-size: 0.8rem;
  transition: 0.2s;

  &:hover {
    background-color: #8b0304;
    color: #fff;
  }
`;

export const TagTitle = styled.h2`
  color: #333333;
  font-size: 14px;
  font-weight: 700;
`;

export const ArticleWrapper = styled.div`
  height: 100%;
`;

export const ArticleMainContent = styled.div`
  overflow-y: auto;
  height: 100%;
  scrollbar-width: thin;
  border-left: 1px #eee solid;
  border-right: 1px #eee solid;
  width: 70%;
  padding: 2em 10%;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 16px;
  background-color: white;

  * {
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 16px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 2em 1em;
    overflow-y: initial;
    height: fit-content;
    border: none;
  }

  &::-webkit-scrollbar {
    width: 3px;
  }
`;

export const ArticleUserInteraction = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    width: 100%;
    order: 3;
  }
`;

export const ArticleTitle = styled.h2`
  margin: auto;
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;
  color: #8b0304;
  margin-bottom: 2em;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

export const ArticleCategory = styled.h3`
  margin: auto;
  font-size: 1.25rem;
  font-weight: 700;
  color: #ccc;
  text-transform: uppercase;
  letter-spacing: 0.2em;

  @media (max-width: 1024px) {
    text-align: center;
  }
`;

export const ArticleBody = styled.div`
  .image-gallery {
    width: auto;
  }

  .image-gallery-slides {
    background-color: #e6e7e9;
  }

  .image-gallery-slide img {
    max-width: 100%;
    height: 600px !important;
    /* height: 600px; */
    /* object-fit: cover; */
    overflow: hidden;
    /* object-position: center center; */
  }

  a {
    color: #8b0304 !important;
  }

  .fullscreen .image-gallery-slide img {
    height: 100vh !important;
  }

  .image-gallery-image {
    border-radius: 0px;
    background: white;
  }
`;

export const CommentaryShowed = styled.div`
  width: 100%;
  overflow-y: scroll;
  padding: 2em;
  position: relative;
  flex-grow: 4;

  @media (max-width: 1024px) {
    overflow-y: initial;
    padding: 1em;
  }
`;

export const CommentaryWrapper = styled.div`
  margin-bottom: 1.5em;
  display: flex;
`;

export const CommentaryBox = styled.div`
  background-color: #f5f5f5;
  border-radius: 0 10px 10px 10px;
  width: 100%;
  margin-left: 0.5em;
  padding: 0.5em 1em 1em 1em;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: -0.5em;
    width: 0;
    height: 0;
    border-bottom: 1em solid transparent;
    border-right: 1em solid #f5f5f5;
  }
`;

export const RemoveCommentary = styled.button`
  background: none;
  position: absolute;
  top: 0.6em;
  right: 1em;
  cursor: pointer;
  color: #ccc;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    color: #8b0304;
  }
`;

export const CommentsIconButton = styled.div`
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 30px;
  width: 60px;
  height: 60px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  line-height: 0;
  position: relative;
  cursor: pointer;
  color: #ccc;

  &:hover {
    color: #333;
  }
`;

export const TotalsBox = styled.div`
  font-weight: 700;
  color: white;
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  line-height: 20;
  background-color: #ccc;
  border: #eee solid 1px;
  border-radius: 50%;
  right: -10px;
  bottom: -10px;
`;

export const CommentaryPhoto = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  filter: brightness(0.9);
`;

export const CommentaryAuthor = styled.div`
  font-size: 1rem;
  font-weight: 700;
  color: #333;
`;

export const CommentaryText = styled.p`
  overflow-wrap: anywhere;
  color: #333;
  margin-top: 1em;
`;

export const CommentaryDate = styled.small`
  color: #ccc;
  font-size: 0.8rem;
  margin-left: 0.5em;
`;

export const CommentaryEntry = styled.div`
  width: 100%;
  display: flex;
  padding: 2em calc(2em + 10px) 1px 2em;
  position: relative;
  z-index: 2;

  @media (max-width: 1024px) {
    padding: 1em;
  }

  @media (min-width: 1025px) {
    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 3em;
      bottom: -3em;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }

  .comment {
    padding: 1em;
    border-radius: 10px;
    width: 100%;
    background-color: #f5f5f5;
    font-size: 0.9rem;
    font-weight: 300;
    font-family: 'Roboto Condensed', sans-serif;
    resize: none;
    box-shadow: none;
    margin-left: 0.5em;
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

    & fieldset {
      border: none;
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    & textarea {
      transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
    }

    &:focus {
      box-shadow: 0 0 30px rgba(0 0 0 / 15%);
    }

    &::before {
      position: absolute;
      content: '';
      top: 0;
      left: -0.5em;
      width: 0;
      height: 0;
      border-bottom: 1em solid transparent;
      border-right: 1em solid #f5f5f5;
    }
  }
`;

export const CommentaryEntryBtn = styled.button`
  cursor: pointer;
  color: #ccc;
  background: none;
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    color: #8b0304;
  }
`;

export const HeaderIcon = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2em;
  position: relative;
  top: 2em;
  left: 30px;

  @media (max-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    order: 2;
    top: initial;
    left: initial;
    transform: scale(0.9);
    margin-bottom: 1em;
  }
`;

export const HeaderIconButton = styled.button`
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 30px;
  width: 60px;
  height: 60px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  line-height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #ccc;
  outline: #f5f5f5 solid 1px;

  &:hover {
    color: #333;
  }
  & svg {
    transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  }
`;

export const OpenPreviewButtonWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
`;

export const ReviewerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 450px;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 999;
  overflow: auto;
`;

export const NextContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 0;
  bottom: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    opacity: 1;
  }
`;

type NavigationProps = {
  placement: 'next' | 'previous';
};

export const NavigationContainer = styled.div`
  display: flex;
  gap: 2em;
  margin-bottom: 2em;
`;

export const NavigationButton = styled.button<NavigationProps>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  cursor: pointer;
  background: none;
  border: none;
  width: 50%;

  color: #ccc;
  font-weight: 700;
  transition: all 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  opacity: 0.75;

  .categoryTitle {
    color: #333;
  }

  .categoryName {
    font-size: clamp(0.7rem, 2.5vw, 1rem);
    text-transform: uppercase;
    letter-spacing: 0.02em;
  }

  transition: 0.2s all;

  &:hover {
    opacity: 1;
    /* transform: scale(1.04); */
  }
`;

export const NPButton = styled.div`
  cursor: pointer;
  background: none;

  transition: 0.2s all;

  ${NavigationButton}:hover & {
    color: #8b0304;
    /* transform: scale(1.04); */
  }
`;

export const MobileNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 22px 0;
`;

export const ReactionsContainer = styled.div`
  background-color: white;
  border-radius: 50%;
  box-shadow: rgb(0 0 0 / 15%) 0px 0px 30px;
  width: 60px;
  height: 60px;
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) 0s;
  line-height: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  outline: #f5f5f5 solid 1px;

  &:hover {
    color: #333;
  }
`;

export const ReactionDialog = styled.div`
  display: flex;
  height: 60px;
  background: white;
  box-shadow: 0 0 30px rgba(0 0 0 / 15%);
  padding: 0.5em 1em;
  gap: 3em;
  border-radius: 40px;
  position: absolute;
  z-index: 3;
  transform-origin: center left;
  transform: scaleX(100%);
  left: 70px;
  transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28) 0s;

  @media (max-width: 1024px) {
    top: -75px;
    left: 0px;
    transform-origin: bottom center;
  }
`;

export const ReactionButton = styled.div`
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;

  &:hover img {
    transform: scale(1.2) translateY(-3px);
  }

  & > img {
    width: 40px;
    height: 40px;
    transition: transform 0.2s;
  }
`;

export const ReactionIcon = styled.img`
  width: 35px;
  height: 35px;
  transform-origin: bottom;
  transition: all 125ms 0s cubic-bezier(0.91, 0.8, 0.54, 1.39);
`;

// export const ReactionButtonContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 5px;
//   cursor: pointer;

//   span {
//     font-size: 14px;
//   }
// `;

export const SectionBreak = styled.hr`
  border-top: #eee solid 1px;
  margin: 3em 0;
`;

export const PickerWrapper = styled.div`
  .emoji-mart-bar,
  .emoji-mart-search {
    display: none;
  }
  .emoji-mart-category .emoji-mart-emoji:hover:before {
    top: 2px;
    left: 4px;
  }
`;
