import { darken } from 'polished';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3em;
`;

export const DeleteConfirmation = styled.p`
  font-size: 1.5rem;
  color: #666;
  line-height: 1;
  font-weight: 700;
  font-family: 'Roboto Condensed';
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  justify-content: center;
  margin-top: 1em;

  .cancel-button {
    background-color: #bbb;

    &:hover {
      background-color: ${darken(0.05, '#888888')};
    }
  }
`;
