import React from 'react';

import { Container, Details, Info, Message, Photo } from './styles';

interface Props {
  professionalName: string;
  jobtitle: string;
  photo: string;
  message: string;
}

export const ProfessionalBanner: React.FC<Props> = ({
  jobtitle,
  message,
  photo,
  professionalName,
}) => {
  return (
    <Container>
      <Info>
        <Photo src={photo} alt={professionalName} />
        <Details>
          <h2>{professionalName}</h2>
          <h3>{jobtitle}</h3>
        </Details>
      </Info>
      <Message>{message}</Message>
    </Container>
  );
};
