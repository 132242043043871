import styled from 'styled-components';
import { FiX } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  .dx-htmleditor {
    border: none;
  }

  .dx-htmleditor.dx-htmleditor-outlined {
    box-shadow: none;
  }

  .dx-htmleditor.dx-htmleditor-outlined.dx-state-active,
  .dx-htmleditor.dx-htmleditor-outlined.dx-state-focused {
    box-shadow: none;
    border-color: none;
  }

  .dx-htmleditor-toolbar-wrapper {
    height: 60px !important;
    border: none !important;
    box-shadow: 0px 0px 30px rgba(0 0 0 / 7%);
    background-color: white;
    z-index: 10;
  }

  .dx-htmleditor-content {
    padding: 32px;
    margin: auto !important;
    max-width: 800px;
    padding: 2em 10%;
    background-color: white;

    @media (max-width: 992px) {
      width: 100%;
      padding: 15px;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    p {
      font-size: 1.125rem;
    }

    strong {
      font-weight: 700;
    }

    em {
      font-style: italic;
    }

    li {
      font-size: 1.125rem;
    }

    pre {
      background-color: #7d7d7d !important;
      font-size: 14px;
    }

    h1 {
      font-size: 28px !important;
    }

    h2 {
      font-size: 24px !important;
    }

    h3 {
      font-size: 20px !important;
    }
  }

  .ql-toolbar.ql-snow {
    height: 60px;
    align-items: center;
    display: flex;
    border: none;
    box-shadow: 0px 10px 10px rgba(0 0 0 / 3%);
    z-index: 99;
    background-color: white;
  }

  .dx-toolbar {
    height: 60px;
  }

  .ql-container.ql-snow {
    border: none;
  }

  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #8b0304;
  }
`;

export const EditorArea = styled.section`
  display: flex;
  width: calc(100% - 450px);
  height: 100%;
  position: absolute;
  left: 0;
  background-color: #f5f5f5;
`;

export const AuxArea = styled.section`
  display: flex;
  flex-direction: column;
  width: 450px;
  height: 100vh;
  position: absolute;
  right: 0;
  box-shadow: 0px 0px 30px rgba(0 0 0 / 7%);
  background-color: white;
`;

export const AuxHeader = styled.div`
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: white;
  box-shadow: 5px 10px 10px rgba(0 0 0 / 3%);
  z-index: 10;

  & > *:not(:last-child) {
    margin-right: 10px;
  }
`;

export const Saving = styled.section`
  padding: 10px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1;
  text-align: center;
  color: #333;
  display: flex;
  flex-direction: column;
`;

export const AuxBody = styled.div`
  width: 100%;
  /* box-shadow: -2px 0px 0px #ebecf0; */
  height: 100%;
  overflow: auto;
  background: #fff;
`;

// export const Footer = styled.div`
//   display: flex;
//   flex-direction: column;
//   box-shadow: 0px 0px 30px rgba(0 0 0 / 7%);
//   display: flex;
//   align-items: center;
//   /* padding: 8px 16px; */
//   background-color: #fff;
//   z-index: 10;

//   /* & > button {
//     margin-right: 5px;
//   } */
// `;

export const CloseButton = styled(FiX).attrs({
  size: 24,
})`
  cursor: pointer;
`;

export const TabPanel = styled.div`
  width: 100%;
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: #fff;
  flex-direction: column;
`;
