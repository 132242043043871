import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useMemo,
  useEffect,
} from 'react';

interface MobileScreenContextData {
  isMobile: boolean;
}

interface Props {
  children: React.ReactNode;
}

const MobileScreenContext = createContext<MobileScreenContextData>(
  {} as MobileScreenContextData,
);

export const MobileScreenProvider: React.FC<Props> = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const isMobile = useMemo(() => width <= 992, [width]);

  const providervalue = useMemo(() => ({ isMobile }), [isMobile]);

  return (
    <MobileScreenContext.Provider value={providervalue}>
      {children}
    </MobileScreenContext.Provider>
  );
};

export function useScreenMobile(): MobileScreenContextData {
  const context = useContext(MobileScreenContext);

  if (!context) {
    throw new Error('useScreenMobile must be used within an ScreenMobile');
  }

  return context;
}
