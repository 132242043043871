import React, { useState, useEffect, useCallback } from 'react';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import DataGrid, {
  Column,
  Scrolling,
  RowDragging,
  Selection,
} from 'devextreme-react/data-grid';

import api, { IPostContentFile, IWeeklyFile } from '../../../../services/api';
import { ThumbnailContainer, Thumbnail } from '../styles';
import { CONTENT_TYPES } from '..';

export type IWeeklyFileOrder = IWeeklyFile & {
  sortOrder: number;
};

type Props = {
  selectionType: 'single' | 'multiple';
  onSelectionChanged: (files: number[]) => void;
  onSortChanged: (files: IWeeklyFileOrder[]) => void;
  onReady: (files: IWeeklyFileOrder[]) => void;
  selectedFilesId: number[];
  postId: number;
  allowReordering: boolean;
  postContentFiles?: IPostContentFile[];
  typeId: number;
};

export const WeeklyFile: React.FC<Props> = ({
  onSelectionChanged,
  onSortChanged,
  onReady,
  selectionType,
  selectedFilesId,
  postId,
  allowReordering,
  postContentFiles,
  typeId,
}) => {
  const [files, setFiles] = useState<DataSource>();
  const [ids, setIds] = useState<number[]>([]);
  const [filesRaw, setFilesRaw] = useState<IWeeklyFileOrder[]>([]);

  const compare = useCallback((a: IWeeklyFileOrder, b: IWeeklyFileOrder) => {
    if (a.sortOrder < b.sortOrder) {
      return -1;
    }
    if (a.sortOrder > b.sortOrder) {
      return 1;
    }
    return 0;
  }, []);

  const handleDrag = useCallback(
    async e => {
      const visibleRows = e.component.getVisibleRows();
      const newFiles = [...filesRaw];
      const toIndex = newFiles.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = newFiles.indexOf(e.itemData);
      newFiles.splice(fromIndex, 1);
      newFiles.splice(toIndex, 0, e.itemData);

      setFilesRaw(newFiles);

      onSortChanged(newFiles);

      const store = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return newFiles;
        },
      });

      setFiles(
        new DataSource({
          store,
          paginate: true,
          reshapeOnPush: true,
        }),
      );
    },
    [filesRaw, onSortChanged],
  );

  useEffect(() => {
    setIds(selectedFilesId);
  }, [selectedFilesId]);

  const fetchData = useCallback(async () => {
    const filesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const filesFromPost = await api.get<IWeeklyFile[]>(
          `/api/weekly-files?idParent=${postId}&isPost=true`,
        );

        const filesWithOrder = filesFromPost.data
          .filter(x => !x.isDirectory)
          .filter(item => {
            if (
              typeId === CONTENT_TYPES.gallery ||
              typeId === CONTENT_TYPES.globalStorage ||
              typeId === CONTENT_TYPES.image ||
              typeId === CONTENT_TYPES.mosaic
            )
              return item.contentType.includes('image');
            if (typeId === CONTENT_TYPES.pdf)
              return item.contentType.includes('pdf');

            return true;
          })
          .map(item => ({
            ...item,
            sortOrder:
              postContentFiles?.find(x => x.idWeeklyFile === item.id)
                ?.sortOrder || 9999,
          }))
          .sort(compare);

        const filesWithOrderSecond = filesWithOrder.map((item, index) => ({
          ...item,
          sortOrder: index + 1,
        }));
        setFilesRaw(filesWithOrderSecond.sort(compare));
        onReady(filesWithOrderSecond.sort(compare));

        return filesWithOrderSecond.sort(compare);
      },
    });

    setFiles(
      new DataSource({
        store: filesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, [postId, compare, typeId, postContentFiles, onReady]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const thumbnailColumn = useCallback(
    e =>
      e.data?.uri && e.data?.contentType?.includes('image') ? (
        <ThumbnailContainer>
          <Thumbnail src={`${e.data?.uri}`} />
          <p>{e.data?.name}</p>
        </ThumbnailContainer>
      ) : (
        <span>{e.data?.name}</span>
      ),
    [],
  );

  return (
    <DataGrid
      hoverStateEnabled
      dataSource={files}
      onSelectedRowKeysChange={onSelectionChanged}
      selectedRowKeys={ids}
    >
      <RowDragging
        allowReordering={allowReordering}
        onReorder={handleDrag}
        showDragIcons
        dropFeedbackMode="push"
      />
      <Selection
        mode={selectionType}
        selectAllMode="allPages"
        showCheckBoxesMode="onClick"
      />
      <Scrolling mode="infinite" />
      <Column dataField="name" cellRender={thumbnailColumn} caption="File" />
    </DataGrid>
  );
};
