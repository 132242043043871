import React from 'react';

import styled from 'styled-components';
import {
  Chart,
  Series,
  CommonSeriesSettings,
  ArgumentAxis,
} from 'devextreme-react/chart';

interface Source {
  arg: string;
  postViews: number;
  avgInteraction: number;
  logins: number;
}

interface Props {
  title: string;
  id: string;
  source: Source[];
}

const Container = styled.div`
  width: 33%;
  padding: 15px 30px 30px 30px;
  background-color: white;
  border-radius: 20px;
  box-sizing: border-box;
  text-align: center;

  small {
    color: #ccc;
    font-weight: 300;
    font-size: 0.8rem;
  }

  #barOne,
  #barTwo,
  #barThree {
    height: 600px;
  }
`;

export const TheBar: React.FC<Props> = ({ title, id, source }) => {
  return (
    <Container>
      <Chart
        id={id}
        palette={['#CCC', '#8b0304', '#333', '#999', '#b30408', '#ff0106']}
        dataSource={source}
        barGroupWidth={20}
        rotated
        valueAxis={{
          grid: { visible: false },
          label: { visible: false },
          visible: false,
          tick: { visible: false },
        }}
        argumentAxis={{
          grid: { width: 1, color: '#EEE', visible: true },
          label: {
            overlappingBehavior: 'rotate',
            textOverflow: 'ellipsis',
            wordWrap: 'breakWord',
            rotationAngle: 180,
            font: { family: 'Roboto Condensed', weight: 700, color: '#AAA' },
          },
          visible: false,
          tick: { visible: false },
        }}
        title={{
          text: title.toUpperCase(),
          horizontalAlignment: 'center',
          font: { family: 'Roboto Condensed', weight: 700, color: '#333' },
        }}
        legend={{
          verticalAlignment: 'top',
          horizontalAlignment: 'center',
          itemTextPosition: 'right',
          font: { family: 'Roboto Condensed', weight: 700, color: '#AAA' },
          columnItemSpacing: 30,
        }}
        tooltip={{
          zIndex: 1000,
          font: { family: 'Roboto Condensed', color: '#333', size: '14px' },
          paddingLeftRight: 20,
          paddingTopBottom: 20,
          opacity: 1,
          arrowLength: 10,
          color: '#ffffff',
          shadow: { opacity: 0.2, blur: 10, color: '#000000' },
          enabled: true,
          interactive: true,
          customizeTooltip(pointInfo) {
            return {
              text: `${
                pointInfo.argumentText
              } : ${pointInfo.valueText.bold()}%`,
            };
          },
        }}
      >
        <CommonSeriesSettings
          barWidth={6}
          cornerRadius={2}
          label={{ visibility: true }}
          argumentField="arg"
          type="bar"
        />
        <Series valueField="logins" name="Logins*" />
        <Series valueField="postViews" name="Post Views" />
        <Series valueField="avgInteraction" name="Avg Interaction" />
      </Chart>
      <small>
        *Login information is only registered during the publication week.
      </small>
    </Container>
  );
};

export default TheBar;
