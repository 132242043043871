import React, { useState, useCallback, TextareaHTMLAttributes } from 'react';

import { Control, Controller } from 'react-hook-form';

import { Container } from './styles';

type Props = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  control: Control;
  handleChange: (
    newValue: string,
    onChange: (text: string) => void,
    oldValue?: string,
  ) => void;
};

export const TextAreaForm: React.FC<Props> = ({
  name,
  control,
  handleChange,
  ...rest
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ref, value } }) => (
        <Container
          ref={ref}
          onChange={e => handleChange(e.target.value, onChange, value)}
          value={value}
          {...rest}
        />
      )}
    />
  );
};
