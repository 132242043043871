import React, { useCallback, useEffect, useState } from 'react';
import {
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup as GroupForm,
} from '@mui/material';
import { SelectBox } from 'devextreme-react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { useForm, Controller } from 'react-hook-form';
import { FiX, FiSearch } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { FormGroup } from '../../../components/FormGroup';
import { Button } from '../../../components/Button';
import { Input } from '../../../components/Input';
import { useToast } from '../../../hooks/toast';

import {
  Container,
  Content,
  ButtonBox,
  HeaderSearch,
  SearchAreaWrapper,
  CheckBoxWrapper,
  SearchButtonBox,
  SearchField,
} from './styles';
import api, { ICategory, IEdition } from '../../../services/api';

interface DialogEditionProps {
  open: boolean;
  handleClose(): void;
}

type EditionProps = {
  idEdition: number;
};

export const DialogEditions: React.FC<DialogEditionProps> = ({
  open,
  handleClose,
}) => {
  const history = useHistory();
  const { addToast } = useToast();

  const [editions, setEditions] = useState<DataSource>();

  const { control, handleSubmit } = useForm();

  const fetchData = useCallback(async () => {
    const editionsStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<IEdition[]>('/api/editions');
        return data
          .filter(x => x.status.id === 1)
          .map(item => ({
            ...item,
            displayName: `${item.name} - ${item.slogan}`,
          }));
      },
    });

    setEditions(
      new DataSource({
        store: editionsStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = useCallback(
    async data => {
      if (!data.idEdition) {
        addToast({
          title: 'Select a edition',
          type: 'error',
        });

        return;
      }
      handleClose();
      history.push(`/editions/${data.idEdition}`);
    },
    [history, handleClose, addToast],
  );

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '20px',
        },
      }}
    >
      <DialogContent>
        <Container>
          <ButtonBox>
            <FiX onClick={handleClose} size={16} />
          </ButtonBox>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Content>
              <HeaderSearch>
                <SearchField>
                  <Controller
                    name="idEdition"
                    control={control}
                    render={({ field }) => (
                      <SelectBox
                        dataSource={editions}
                        valueExpr="id"
                        displayExpr="displayName"
                        onValueChanged={e => field.onChange(e.value)}
                        placeholder="Select a edition..."
                        searchEnabled
                        showClearButton
                        dropDownOptions={{ maxHeight: '21em' }}
                      />
                    )}
                  />
                </SearchField>

                <Button primary style={{ gap: 10 }} type="submit">
                  <FiSearch />
                  Go
                </Button>
              </HeaderSearch>
            </Content>
          </form>
        </Container>
      </DialogContent>
    </Dialog>
  );
};
