import React, { useEffect, useCallback, useState } from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { DataGrid } from 'devextreme-react';
import {
  Column,
  HeaderFilter,
  RowDragging,
  Scrolling,
  SearchPanel,
  MasterDetail,
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { FiEdit2, FiTrash2 } from 'react-icons/fi';

import { BoardBody } from '../../../components/BoardBody';
import { BoardHeader } from '../../../components/BoardHeader';
import { Button } from '../../../components/Button';
import api, { ICategory } from '../../../services/api';
import { DialogNewCategory } from './DialogNewCategory';
import { Container } from './styles';
import { DialogEditCategory } from './DialogEditCategory';
import { CategoryGrid } from './CategoryGrid';
import { DialogDeleteCategory } from './DialogDeleteCategory';
import { ApplicationLayers } from '../../../components/ApplicationLayers';

export const Category: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState<DataSource>();
  const [categoriesRaw, setCategoriesRaw] = useState<ICategory[]>([]);
  const [openEdit, setOpenEdit] = useState(false);
  const [openEditId, setOpenEditId] = useState(0);
  const [openDelete, setOpenDelete] = useState(false);
  const [openDeleteId, setOpenDeleteId] = useState(0);

  const loadCategories = useCallback(async () => {
    const store = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        const { data } = await api.get<ICategory[]>('/api/categories');

        const parentCategories = data.filter(x => !x.idParentCategory);

        setCategoriesRaw(parentCategories);
        return parentCategories;
      },
      update: async (id, data) => {
        await api.put(`/api/categories/${id}`, data);
      },
    });

    setCategories(
      new DataSource({
        store,
        paginate: true,
        reshapeOnPush: true,
      }),
    );
  }, []);

  const commandColumnRender = useCallback(e => {
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Tooltip title="Edit category" aria-label="open">
          <IconButton
            aria-label="Edit category"
            size="small"
            onClick={() => {
              setOpenEditId(e.key);
              setOpenEdit(true);
            }}
          >
            <FiEdit2 size={18} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete category" aria-label="open">
          <IconButton
            aria-label="Delete category"
            size="small"
            onClick={() => {
              setOpenDeleteId(e.key);
              setOpenDelete(true);
            }}
          >
            <FiTrash2 size={18} />
          </IconButton>
        </Tooltip>
      </div>
    );
  }, []);

  const handleDrag = useCallback(
    async e => {
      const visibleRows = e.component.getVisibleRows();
      const newcategories = [...categoriesRaw];
      const toIndex = newcategories.indexOf(visibleRows[e.toIndex].data);
      const fromIndex = newcategories.indexOf(e.itemData);
      newcategories.splice(fromIndex, 1);
      newcategories.splice(toIndex, 0, e.itemData);
      const newParentCategories = newcategories.filter(
        x => !x.idParentCategory,
      );

      setCategoriesRaw(newParentCategories);

      const store = new CustomStore({
        key: 'id',
        loadMode: 'raw',
        load: async () => {
          return newParentCategories;
        },
        update: async (id, data) => {
          await api.put(`/api/categories/${id}`, data);
        },
      });

      setCategories(
        new DataSource({
          store,
          paginate: true,
          reshapeOnPush: true,
        }),
      );

      const dataToUpdate = newcategories.map((item, index) => ({
        id: item.id,
        sortOrder: index + 1,
      }));
      await api.patch(`api/categories/order`, dataToUpdate);
    },
    [categoriesRaw],
  );

  const categoriesGridComponent = useCallback(
    categoryData => <CategoryGrid categoryId={categoryData.data.data.id} />,
    [],
  );

  useEffect(() => {
    loadCategories();
  }, [loadCategories]);

  return (
    <ApplicationLayers>
      <Container>
        <BoardHeader title="Categories" subtitle="Categories" />

        <BoardBody>
          <Button
            className="newCategoryBtn"
            primary
            onClick={() => setOpen(true)}
          >
            NEW CATEGORY
          </Button>
          <DataGrid
            hoverStateEnabled={false}
            dataSource={categories}
            onRowUpdating={options => {
              options.newData = { ...options.oldData, ...options.newData };
            }}
          >
            <RowDragging
              allowReordering
              onReorder={handleDrag}
              showDragIcons
              dropFeedbackMode="push"
            />
            <SearchPanel visible placeholder="Search here" />
            <HeaderFilter visible />
            <Scrolling mode="infinite" />
            <Column dataField="name" />
            <Column dataField="status.name" caption="Status" />
            <Column dataField="parentCategory.name" caption="Parent Category" />
            <Column dataField="dateCreated" dataType="datetime" />
            <Column
              type="buttons"
              cellRender={commandColumnRender}
              width={120}
            />
            <MasterDetail enabled component={categoriesGridComponent} />
          </DataGrid>
        </BoardBody>
        {open && (
          <DialogNewCategory
            open={open}
            handleClose={() => {
              setOpen(false);
              categories?.reload();
            }}
          />
        )}
        {openEdit && (
          <DialogEditCategory
            open={openEdit}
            handleClose={() => {
              setOpenEdit(false);
              categories?.reload();
            }}
            categoryId={openEditId}
          />
        )}
      </Container>
    </ApplicationLayers>
  );
};
