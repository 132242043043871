import React, { useCallback, useState, useEffect, useMemo } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

import MuiTabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import { DataGrid, SelectBox, TextBox } from 'devextreme-react';
import { Column, Scrolling } from 'devextreme-react/data-grid';
import { WhisperSpinner } from 'react-spinners-kit';

import api, {
  IPostContent,
  IPostContentFile,
  IWeeklyFile,
} from '../../../services/api';
import master from '../../../services/master';
import { Button } from '../../Button';
import {
  LoadingContainer,
  TabPanel,
  Tabs,
  Thumbnail,
  ThumbnailContainer,
} from './styles';
import { FormGroup } from '../../FormGroup';
import { ProfessionalLookup } from '../../ProfessionalLookup';
import { useToast } from '../../../hooks/toast';
import fileIcon from '../../../assets/icons/file.png';
import pdfIcon from '../../../assets/icons/pdf.png';

import { GlobalFile } from './GlobalFile';
import { IWeeklyFileOrder, WeeklyFile } from './WeeklyFile';

interface DialogCustomProps {
  open: boolean;
  handleClose(): void;
  onSaved?(): void;
  contentId: number;
  postId: number;
}

export const CONTENT_TYPES = {
  gallery: 1,
  mosaic: 2,
  image: 3,
  globalStorage: 4,
  pdf: 5,
  iframe: 6,
  templateProfessional: 7,
  templateSalesChart: 8,
  file: 9,
  importantMessage: 10,
};

interface CustomStoreProps {
  store: CustomStore;
  paginate: boolean;
}

export const ContentEdit: React.FC<DialogCustomProps> = ({
  handleClose,
  open,
  contentId,
  postId,
  onSaved,
}) => {
  const { addToast } = useToast();
  const [tab, setTab] = useState(0);

  const [loading, setLoading] = useState(false);
  const [typeId, setTypeId] = useState(0);
  const [textContent, setTextContent] = useState('');
  const [files, setFiles] = useState<IWeeklyFileOrder[]>([]);
  const [professionalStore, setProfessionalStore] = useState<CustomStoreProps>(
    {} as CustomStoreProps,
  );
  const [professional, setProfessional] = useState(0);
  const [alias, setAlias] = useState('');
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  const [postContentFiles, setPostContentFiles] = useState<
    IPostContentFile[] | undefined
  >([]);
  const [selectedFilesId, setSelectedFilesId] = useState<number[]>([]);
  const [salesItems, setSalesItems] = useState<DataSource>();

  const loadProfessionals = useCallback(() => {
    setProfessionalStore({
      store: new CustomStore({
        key: 'ID',
        loadMode: 'raw',
        load: async () => {
          const { data } = await master.get(`/master/professionals`, {
            params: {
              where: { IsAnyStatus: true },
            },
          });

          return data;
        },
      }),
      paginate: true,
    });
  }, []);

  const handleChangeTab = useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue);
    },
    [],
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const [content] = await Promise.all([
      api.get<IPostContent>(`api/posts/${postId}/contents/${contentId}`),
    ]);

    const salesStore = new CustomStore({
      key: 'id',
      loadMode: 'raw',
      load: async () => {
        return content.data.postContentSalesCharts || [];
      },
    });

    setSalesItems(
      new DataSource({
        store: salesStore,
        paginate: true,
        reshapeOnPush: true,
      }),
    );

    setTab(content.data.isGlobal ? 1 : 0);
    setSelectedFilesId(
      content.data.postContentFiles?.map(x => x.idWeeklyFile) || [],
    );
    setPostContentFiles(content.data.postContentFiles);
    setWidth(content.data.width);
    setHeight(content.data.height);
    setProfessional(content.data.idProfessional || 0);
    setAlias(content.data.alias || '');
    setTypeId(content.data.idType);
    setTextContent(content.data.textContent || '');
    setLoading(false);
  }, [postId, contentId]);

  useEffect(() => {
    fetchData();
    loadProfessionals();
  }, [fetchData, loadProfessionals]);

  const professionalComponent = useCallback(
    (name, login, jobtitle) => (
      <ProfessionalLookup name={name} login={login} jobtitle={jobtitle} />
    ),
    [],
  );

  const getDetailsInput = useMemo(() => {
    if (
      typeId === CONTENT_TYPES.gallery ||
      typeId === CONTENT_TYPES.mosaic ||
      typeId === CONTENT_TYPES.pdf ||
      typeId === CONTENT_TYPES.globalStorage ||
      typeId === CONTENT_TYPES.file ||
      typeId === CONTENT_TYPES.image
    ) {
      return (
        <>
          <FormGroup fieldSetLabel="Alias">
            <TextBox onValueChange={value => setAlias(value)} value={alias} />
          </FormGroup>
          {typeId === CONTENT_TYPES.image && (
            <>
              <FormGroup fieldSetLabel="Width">
                <TextBox
                  onValueChange={value => setWidth(value)}
                  value={`${width || ''}`}
                  placeholder="Keep empty to default size"
                />
              </FormGroup>
              <FormGroup fieldSetLabel="Height">
                <TextBox
                  onValueChange={value => setHeight(value)}
                  value={`${height || ''}`}
                  placeholder="Keep empty to default size"
                />
              </FormGroup>
            </>
          )}
          <Tabs>
            <MuiTabs value={tab} onChange={handleChangeTab}>
              <Tab label="Weekly" />
              <Tab label="Global" />
            </MuiTabs>

            {tab === 0 && (
              <TabPanel>
                <WeeklyFile
                  typeId={typeId}
                  allowReordering={
                    typeId === CONTENT_TYPES.gallery ||
                    typeId === CONTENT_TYPES.mosaic ||
                    typeId === CONTENT_TYPES.globalStorage
                  }
                  onReady={setFiles}
                  onSelectionChanged={setSelectedFilesId}
                  onSortChanged={setFiles}
                  postId={postId}
                  selectedFilesId={selectedFilesId}
                  postContentFiles={postContentFiles}
                  selectionType={
                    typeId === CONTENT_TYPES.gallery ||
                    typeId === CONTENT_TYPES.mosaic ||
                    typeId === CONTENT_TYPES.globalStorage
                      ? 'multiple'
                      : 'single'
                  }
                />
              </TabPanel>
            )}

            {tab === 1 && (
              <TabPanel>
                <GlobalFile
                  selectedFilesId={selectedFilesId}
                  onSelectionChanged={setSelectedFilesId}
                  selectionType={
                    typeId === CONTENT_TYPES.gallery ||
                    typeId === CONTENT_TYPES.mosaic ||
                    typeId === CONTENT_TYPES.globalStorage
                      ? 'multiple'
                      : 'single'
                  }
                />
              </TabPanel>
            )}
          </Tabs>
        </>
      );
    }
    if (typeId === CONTENT_TYPES.templateSalesChart) {
      return (
        <>
          <FormGroup fieldSetLabel="Alias">
            <TextBox onValueChange={value => setAlias(value)} value={alias} />
          </FormGroup>
          <DataGrid
            hoverStateEnabled
            dataSource={salesItems}
            onSelectionChanged={e => setSelectedFilesId(e.selectedRowKeys)}
          >
            <Scrolling mode="infinite" />
            <Column dataField="idClient" />
          </DataGrid>
        </>
      );
    }
    if (typeId === CONTENT_TYPES.templateProfessional) {
      return (
        <>
          <FormGroup fieldSetLabel="Alias">
            <TextBox onValueChange={value => setAlias(value)} value={alias} />
          </FormGroup>
          <FormGroup fieldSetLabel="Extra Info">
            <TextBox
              onValueChange={value => setTextContent(value)}
              value={textContent}
              placeholder="Welcome, good luck..."
            />
          </FormGroup>
          <FormGroup fieldSetLabel="Professional">
            <SelectBox
              dataSource={professionalStore}
              displayExpr="Name"
              valueExpr="ID"
              placeholder="Select professional"
              itemRender={data =>
                professionalComponent(
                  data.Name,
                  data.Login,
                  data.JobTitle?.Name,
                )
              }
              searchExpr={['Name', 'JobTitle.Name']}
              minSearchLength={2}
              showDataBeforeSearch
              searchEnabled
              value={professional}
              onValueChanged={e => setProfessional(e.value)}
            />
          </FormGroup>
        </>
      );
    }
    return (
      <>
        <FormGroup fieldSetLabel="Name">
          <TextBox onValueChange={value => setAlias(value)} value={alias} />
        </FormGroup>
        <FormGroup fieldSetLabel="Content">
          <TextBox
            onValueChanged={e => setTextContent(e.value)}
            value={textContent}
          />
        </FormGroup>
      </>
    );
  }, [
    typeId,
    alias,
    textContent,
    width,
    height,
    tab,
    handleChangeTab,
    postId,
    selectedFilesId,
    salesItems,
    professionalStore,
    professional,
    professionalComponent,
    postContentFiles,
  ]);

  const handleGlobalSave = useCallback(async () => {
    setLoading(true);

    try {
      await api.put(`api/posts/${postId}/contents/${contentId}`, {
        idProfessional: professional,
        textContent,
        alias,
        height,
        width,
        isGlobal: tab === 1,
      });

      await api.post(
        `api/posts/${postId}/contents/${contentId}/files`,
        selectedFilesId.map(x => ({
          idWeeklyFile: x,
          sortOrder: 0,
        })),
      );

      setLoading(false);

      addToast({
        type: 'success',
        title: 'Saved',
      });

      if (onSaved) onSaved();
      handleClose();
    } catch {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
      setLoading(false);
    }
  }, [
    tab,
    selectedFilesId,
    professional,
    addToast,
    onSaved,
    handleClose,
    contentId,
    postId,
    alias,
    textContent,
    height,
    width,
  ]);

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      await api.put(`api/posts/${postId}/contents/${contentId}`, {
        idProfessional: professional,
        textContent,
        alias,
        height,
        width,
        isGlobal: tab === 1,
      });

      if (
        typeId === CONTENT_TYPES.gallery ||
        typeId === CONTENT_TYPES.mosaic ||
        typeId === CONTENT_TYPES.pdf ||
        typeId === CONTENT_TYPES.globalStorage ||
        typeId === CONTENT_TYPES.file ||
        typeId === CONTENT_TYPES.image
      ) {
        const data = files
          .filter(x => selectedFilesId.includes(x.id))
          .map((item, index) => ({
            idWeeklyFile: item.id,
            sortOrder: index + 1,
          }));

        await api.post(`api/posts/${postId}/contents/${contentId}/files`, data);
      }

      setLoading(false);

      addToast({
        type: 'success',
        title: 'Saved',
      });

      if (onSaved) onSaved();
      handleClose();
    } catch {
      addToast({
        type: 'error',
        title: 'Something went wrong...',
      });
      setLoading(false);
    }
  }, [
    tab,
    addToast,
    onSaved,
    handleClose,
    postId,
    contentId,
    professional,
    textContent,
    alias,
    typeId,
    files,
    selectedFilesId,
    height,
    width,
  ]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle id="form-dialog-title">
        Select files to the content
      </DialogTitle>
      {loading ? (
        <LoadingContainer>
          <WhisperSpinner size={15} backColor="#8b0304" frontColor="#fff" />
        </LoadingContainer>
      ) : (
        <DialogContent>{getDetailsInput}</DialogContent>
      )}

      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
        <Button
          type="submit"
          primary
          loading={loading}
          onClick={() => (tab === 0 ? handleSave() : handleGlobalSave())}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
