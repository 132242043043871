import React from 'react';
import { AuthProvider } from './auth';
import { DocumentTitleProvider } from './documentTitle';
import { ToastProvider } from './toast';
import { MobileScreenProvider } from './mobileScreen';

interface AuxProps {
  children: React.ReactNode;
}

const AppProvider: React.FC<AuxProps> = ({ children }) => {
  return (
    <AuthProvider>
      <ToastProvider>
        <MobileScreenProvider>
          <DocumentTitleProvider>{children}</DocumentTitleProvider>
        </MobileScreenProvider>
      </ToastProvider>
    </AuthProvider>
  );
};

export default AppProvider;
