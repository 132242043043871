import { CircularProgress, Tooltip } from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  FiCheckCircle,
  FiAlertCircle,
  FiMinusCircle,
  FiStar,
  FiMessageSquare,
  FiThumbsUp,
  FiEye,
  FiEyeOff,
} from 'react-icons/fi';

import { IEdition, IPost, IReaction } from '../../services/api';

import {
  HeaderIcon,
  HeaderSubtitle,
  HeaderTitle,
  Post,
  PostCardTextContent,
  PostImageWrapper,
  PostWrapper,
  PostReviewTab,
  PostAbstract,
  ReactionIcon,
  IconButton,
  TotalsBox,
  PostHeader,
} from './styles';

type Props = {
  post: IPost;
  isPublished: boolean;
  loadingReviews?: boolean;
  onFavorite: (postId: number) => void;
  onUnfavorite: (postId: number) => void;
  onOpenPost: (postId: number) => void;
  reactions: IReaction[];
  editionsDetails?: IEdition;
  isTherePostCategory: boolean;
};

export const HomePostGrid: React.FC<Props> = ({
  post,
  isPublished,
  loadingReviews,
  onFavorite,
  onOpenPost,
  onUnfavorite,
  reactions,
  editionsDetails,
  isTherePostCategory,
}) => {
  const history = useHistory();
  const getReviewText = useCallback(status => {
    if (status === 1) return 'Approved';
    if (status === 2) return 'No Info';
    if (status === 3) return 'Pending';
    return 'No Info';
  }, []);

  // const handlePostTagSliced = useCallback(() => {
  //   return post.postTags.length > 6 ? post.postTags.slice(0, 5) : post.postTags;
  // }, [post.postTags]);

  const getReviewIcon = useCallback(status => {
    if (status === 1) return <FiCheckCircle size={25} color="#5ab65e" />;
    if (status === 3) return <FiAlertCircle size={25} color="#edce61" />;
    return <FiMinusCircle size={25} />;
  }, []);

  const totalReactionsSum = useMemo(
    () =>
      post.totalReactions.reduce((acc, obj) => {
        return acc + obj.total;
      }, 0),
    [post],
  );

  return (
    <Post
      key={post.id}
      onClick={() => {
        onOpenPost(post.id);
      }}
    >
      <PostWrapper>
        <PostHeader>
          <PostImageWrapper>
            <img
              src={post.mainImage?.uri || editionsDetails?.imageCover?.uri}
              alt=""
            />
          </PostImageWrapper>
          <HeaderIcon>
            <IconButton
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                return onOpenPost(post.id);
              }}
            >
              {post.totalReactions
                .filter(reaction => reaction.total > 0)
                .map(reaction => (
                  <ReactionIcon
                    key={reaction.idReaction}
                    src={
                      reactions.find(x => x.id === reaction.idReaction)
                        ?.imageURL
                    }
                    alt={
                      reactions.find(x => x.id === reaction.idReaction)
                        ?.description
                    }
                  />
                ))}

              {post.totalReactions.filter(reaction => reaction.total > 0)
                .length <= 0 && <FiThumbsUp size={20} />}

              <TotalsBox>{totalReactionsSum}</TotalsBox>
            </IconButton>
            <IconButton
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                return onOpenPost(post.id);
              }}
            >
              <FiMessageSquare size={20} />
              <TotalsBox>{post.totalComments}</TotalsBox>
            </IconButton>

            <IconButton
              style={{ cursor: 'pointer' }}
              onClick={event => {
                event.stopPropagation();
                return post.isFavorited
                  ? onUnfavorite(post.id)
                  : onFavorite(post.id);
              }}
            >
              <FiStar size={20} color={post.isFavorited ? '#8b0304' : ''} />
            </IconButton>
            <IconButton>
              {post.isRead ? (
                <FiEye size={20} color="#3d3b3b" />
              ) : (
                <FiEyeOff size={20} color="#ccc" />
              )}
            </IconButton>
          </HeaderIcon>
        </PostHeader>

        <PostCardTextContent>
          <p
            style={{
              color: 'rgb(204 204 204)',
              fontWeight: 'bold',
              fontSize: 12,
              marginBottom: 5,
            }}
          >
            {new Date(post?.dateCreated ?? new Date()).toLocaleDateString(
              'en-US',
              {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              },
            )}
          </p>
          {isTherePostCategory && (
            <HeaderSubtitle>{post.category?.name}</HeaderSubtitle>
          )}
          <HeaderTitle>{post.title}</HeaderTitle>
          <PostAbstract>{post.abstract || 'Read more'}</PostAbstract>
        </PostCardTextContent>
      </PostWrapper>
      {!isPublished && (
        <PostReviewTab>
          {!loadingReviews && (
            <>
              <Tooltip
                title={
                  post.isPartnerRevised
                    ? 'Partner - Approved'
                    : 'Partner - Pending'
                }
                placement="top"
              >
                <div>
                  Partner
                  {post.isPartnerRevised ? (
                    <FiCheckCircle size={25} color="#5ab65e" />
                  ) : (
                    <FiAlertCircle size={25} color="#edce61" />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                title={
                  post.isRevised ? 'Reviewer - Approved' : 'Reviewer - Pending'
                }
                placement="top"
              >
                <div>
                  Reviewer
                  {post.isRevised ? (
                    <FiCheckCircle size={25} color="#5ab65e" />
                  ) : (
                    <FiAlertCircle size={25} color="#edce61" />
                  )}
                </div>
              </Tooltip>
              <Tooltip
                title={`Photo - ${getReviewText(post.hasPhoto)}`}
                placement="top"
              >
                <div>Photos{getReviewIcon(post.hasPhoto)}</div>
              </Tooltip>
              <Tooltip
                title={`Video - ${getReviewText(post.hasVideo)}`}
                placement="top"
              >
                <div>Videos{getReviewIcon(post.hasVideo)}</div>
              </Tooltip>
              <Tooltip
                title={`Text - ${getReviewText(post.hasText)}`}
                placement="top"
              >
                <div>Text{getReviewIcon(post.hasText)}</div>
              </Tooltip>
              <Tooltip
                title={`Tag - ${getReviewText(post.isTag)}`}
                placement="top"
              >
                <div>Tags{getReviewIcon(post.isTag)}</div>
              </Tooltip>
            </>
          )}
          {loadingReviews && <CircularProgress size={25} color="primary" />}
        </PostReviewTab>
      )}
    </Post>
  );
};
