import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .css-ah6uqf-MuiButtonBase-root-MuiTab-root {
    padding: 10px;
    font-size: 14px !important;
    text-transform: none !important;
  }

  .css-heg063-MuiTabs-flexContainer {
    width: 100%;
    justify-content: center;
  }
`;

export const TabPanel = styled.div`
  width: 100%;
  position: relative;
`;
