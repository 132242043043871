import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #f0f0f0;
  font-weight: 600;
  font-size: 12px;

  .devextreme-selectbox {
    --background-color: #8b0305;
    --text-color: #fff;
    --border-radius: 5px;
    --font-weight: 600;
    --hover-background-color: #6a0203;

    background-color: var(--background-color);
    color: var(--text-color);
    padding: 10px 20px;
    border: none;
    border-radius: var(--border-radius);
    font-weight: var(--font-weight);
    width: 162.42px;
    max-width: 200px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.1s ease;

    &:hover {
      background-color: var(--hover-background-color);
    }

    @media (max-width: 767px) {
      width: 80%;
      margin-bottom: 20px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    font-size: 16px;
  }
`;

export const CardImage = styled.img`
  height: 70%;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 50%;
    height: 90%;
  }
`;

export const Button = styled.button`
  background-color: #8b0305;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  max-width: 200px;

  &:hover {
    background-color: #6a0203;
  }

  &:active {
    background-color: #520101;
    transform: scale(0.95);
  }
`;

export const Select = styled.select`
  background-color: #8b0305;
  font-family: 'Roboto Condensed', sans-serif;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 162.42px;
  max-width: 200px;
  font-weight: 600;
  text-align: center;

  &:hover {
    background-color: #6a0203;
  }

  @media (max-width: 767px) {
    width: 80%;
    margin-bottom: 20px;
  }

  option {
    background-color: white;
    color: #8b0305;
    font-weight: bold;
  }
`;
